@mixin make-footer($background: $colors-bbbb, $foreground: $colors-white) {
  footer {
    background-color: $background;
    color: $foreground;

    svg path {
      fill: $foreground;
    }

    a,
    p {
      color: inherit;
    }

    .disclaimers {
      @include links-colors($background, $foreground);
    }
  }
}

// mixin to push the footer to align with page content
@mixin footer-push($columns) {
  footer .row {
    @include container($width: 12, $push: $columns);
  }
}

@include make-footer($background: $colors-yellow, $foreground: $colors-black);
