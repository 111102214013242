// Loading animation for ajax zones
.initialize {
  // position: relative;
  // min-height: 40vh;

  & > * {
    display: none !important;
  }

  &::before {
    content: "";
    // top: 0;
    // left: 0;
    // position: absolute;
    width: 100%;
    display: block;
    // height: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    min-height: 100px;
    background-image: svg-url(
      '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 12" width="72px" height="12px"><style>@keyframes example{from{opacity: 0;}to{opacity: 1;}}.busy circle{fill:#000;animation: example 1s ease-in infinite alternate;}.busy circle:nth-child(2){animation-delay:0.2s;}.busy circle:nth-child(3){animation-delay: 0.4s;}</style><g class="busy"><circle stroke="none" cx="6" cy="6" r="5"></circle><circle stroke="none" cx="36" cy="6" r="5"></circle><circle stroke="none" cx="66" cy="6" r="5"></circle></g></svg>'
    );
  }
}

body.initialize,
main.initialize {
  // height: calc(100vh - 9rem);

  @each $breakpoint, $height in $header-heights {
    @include breakpoint($breakpoint) {
      height: calc(100vh - #{$height});
    }
  }

  position: relative;

  &::before {
    bottom: 0;
    position: absolute;
    height: 100%;
    padding-bottom: 0;
  }
}

.static-header main.initialize {
  @each $breakpoint, $height in $header-heights {
    @include breakpoint($breakpoint) {
      margin-top: $height;
    }
  }
}
