.tile-company {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.10);
  position: relative;
  color: $colors-grey-dark;
  transition: box-shadow $transition-speed-base ease;

  &:hover {
    box-shadow: 1px 4px 7px rgba(0, 0, 0, 0.15);
  }

  &__category {
    color: $colors-purple;
    border-top: 4px solid $colors-purple;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 1rem;
    padding: 4px 8px;
  }

  &__card {
    display: block;
    overflow: hidden;
    position: relative;
  }

  &__img {
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
    height: 120px;

    @include breakpoint(14) {
      height: 160px;
    }
    @include breakpoint(18) {
      height: 200px;
    }

    &:before {
      background: rgba(0,0,0,0.25);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &--default {
      background-image: linear-gradient(to bottom right, $colors-purple-dark, $colors-purple);
    }
  }

  &__factoid {
    background: $colors-purple-dark;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.25);
    color: #fff;
    font-weight: 600;
    font-size: 0.85rem;
    padding: 2px 8px;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  // $logo-width: 80px;
  // $logo-height: 76px;

  &__about {
    // margin-top: -0.5 * $logo-height;
    margin-top: $rhythm;
    text-align: left;
  }

  // &__logo {
  //   background-color: #fff;
  //   background-size: cover;
  //   background-position: center;
  //   border-radius: 0.5rem;
  //   box-shadow: 0 2px 5px rgba(0,0,0,0.12);
  //   display: inline-block;
  //   width: $logo-width;
  //   height: $logo-height;
  //   margin-left: $rhythm;
  //   position: relative;

  //   &--default {
  //     background-image: linear-gradient(to bottom right, $colors-grey, $colors-grey-light);
  //   }
  // }

  // &__logo-acronym {
  //   color: #fff;
  //   display: block;
  //   font-size: 1.75rem;
  //   font-weight: 400;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   text-align: center;
  // }

  &__name {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: $rhythm;
  }

  &__description {
    padding: ($rhythm * 0.5) $rhythm $rhythm;
    margin: 0;
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
}