.folio {
	dl,
	ol,
	ul {
		@include max-width($measure);
		margin-bottom: $rhythm;
		padding-left: 2rem;
	}

	ol {
		list-style-type: decimal;
	}

	$fancy-lists: 'ol.legal, ol.lettered, ol.numbered, ol.roman';

	#{$fancy-lists} {
		list-style-type: none;
		padding-left: 0;

		#{$fancy-lists} {
			padding-left: 1rem;
			margin-bottom: 1.5rem;
		}
	}

	ol.lower-alpha {
		list-style-type: lower-alpha;
	}

	ol.lower-roman {
		list-style-type: lower-roman;
	}

	ul {
		list-style-type: square;
	}

	p + dl,
	p + ol,
	p + ul {
		margin-top: -$rhythm * 0.5;
		margin-bottom: $rhythm * 0.5;
	}

	p.lede + dl,
	p.lede + ol,
	p.lede + ul {
		margin-top: $rhythm;
		margin-bottom: $rhythm;
	}

	p.major + dl,
	p.major + ol,
	p.major + ul {
		margin-top: $rhythm;
		margin-bottom: $rhythm;
	}

	li {
		margin-bottom: $rhythm * 0.5;

		dl,
		ol,
		ul {
			margin-top: $rhythm * 0.5;
			margin-bottom: $rhythm * 0.5;
		}

		p + dl,
		p + ol,
		p + ul {
			// @marvin: help—why do I need to do all this nesting?
			margin-top: -$rhythm * 0.5;
			margin-bottom: $rhythm;
		}
	}
}

