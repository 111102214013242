@import 'data-vals';

/***'nc-' means NetCapital, used to avoid any confusion with other libraries ***/ 

.grey-border-top {
	border-top: 1px solid #ccc;
}

.grey-border-bottom {
	border-bottom: 1px solid #ccc;
}
// @DEPRECATE
.text-align-center {
	text-align: center;
}

.nc-hidden {
	visibility: hidden;
}

.nc-text-align-center {
	text-align: center;
}

.no-bottom-border {
	border-bottom: none!important;
}

.nc-margin-top {
	margin-top: 2.5em;
}

.margin-bottom {
	margin-bottom: 2.5em;
}

.no-margin {
	margin: 0;
}

.fit-100 {
	width: 100%;
}

.white-background {
	background: white;
}

.color-red {
	color: red;
}

.color-black {
	color: black;
}

.nc-color-white {
	color: white;
}

.red-background {
	background: red;
}

.cursor-pointer {
	cursor: pointer;
}

.fit {
	width: 100%;
}

.nc-width-height-100 {
	width: 100%;
	height: 100%;
}

.z-index-1 {
	z-index: 1;
}

.width-100px {
	width: 100px!important;
}

.width-50px {
	width: 50px!important;
}

.nc-error {
	color: red;
}

.nc-success {
	color: green;
}

.overflow-hidden {
	overflow: hidden;
}

.nc-inline-flex {
	display: inline-flex;
}

.nc-no-padding-top {
	padding-top: 0!important;
}

.nc-margin-left-1em {
	margin-left: 1em;
}