figure {
	margin-bottom: $rhythm;
	text-align: center;

	&.col {
		&-1 {
			@include block(4);
		}
	}

	&.inline {
		max-width: 50%;

		&.left {
			float: left;
			margin-right: $rhythm;
		}

		&.right {
			float: right;
			margin-left: $rhythm;
		}

		img {
			margin-bottom: 0;
			padding-top: 6px;
		}
	}

	&.videos {
		margin: 0 auto 1.5rem;
		&.small {
			max-width: 600px;
		}
	}

	img {
		max-width: 100%;
		height: auto;
		margin-bottom: 0.5 * $rhythm;
	}

	&.two {
		@include simple-container;
		img {
			@include block($width: (0:8, 8:4, 12:6));
		}
	}

	figcaption {
		margin-top: -1rem;
	}
}

figcaption {
	font-size: 0.85rem;
	margin-bottom: $rhythm;
}