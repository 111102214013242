.has-tooltip {
	position: relative;
	display: inline-block;
	margin-left: 4px;
	margin-right: 4px;
	margin-bottom: $rhythm;
	padding-right: 14px;
	font-style: italic;

	@media screen and (min-width: 462px) {
		margin-left: 8px;
		margin-right: 8px;
	}

	&--table {
		margin: 0;
		font-style: normal;
	}

	&__img {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		width: 12px;
		height: 12px;
		cursor: pointer;

		.info {
			display: block;
			width: 100%;
			height: 100%;
		}

		&:hover {
			opacity: 0.75;
		}
	}
}
