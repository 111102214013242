@import "../functions/rem-conversion";
// Typography mixins for web, tablet and mobile view

$jostFont: "Jost", sans-serif;

// WEB
@mixin webTextVariant($variant) {
  @if $variant == web-heading1 {
    font-family: $jostFont;
    font-size: pxToRemVal(52px);
    line-height: pxToRemVal(60px);
    font-weight: 700;
    font-style: normal;
  } @else if $variant == web-heading2 {
    font-family: $jostFont;
    font-size: pxToRemVal(44px);
    line-height: pxToRemVal(56px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == web-heading3 {
    font-family: $jostFont;
    font-size: pxToRemVal(36px);
    line-height: pxToRemVal(44px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == web-heading4 {
    font-family: $jostFont;
    font-size: pxToRemVal(28px);
    line-height: pxToRemVal(36px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == web-heading5 {
    font-family: $jostFont;
    font-size: pxToRemVal(20px);
    line-height: pxToRemVal(28px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == web-heading6 {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == web-heading6-italic {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(24px);
    font-style: italic;
    font-weight: 400;
  } @else if $variant == web-button-large {
    font-family: $jostFont;
    font-size: pxToRemVal(18px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 500;
  } @else if $variant == web-button {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(20px);
    font-style: normal;
    font-weight: 500;
  } @else if $variant == web-button-small {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(16px);
    font-style: normal;
    font-weight: 500;
  } @else if $variant == web-body {
    font-family: $jostFont;
    font-size: pxToRemVal(18px);
    line-height: pxToRemVal(28px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == web-body-small {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == web-body-tiny {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(20px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == web-body-tiny-medium {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(20px);
    font-style: normal;
    font-weight: 500;
  } @else if $variant == web-small-semibold {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 600;
  } @else if $variant == web-caption {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(16px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == web-label {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(16px);
    font-style: normal;
    font-weight: 500;
  }
}

// TABLET

@mixin tabletTextVariant($variant) {
  @if $variant == tablet-heading1 {
    font-family: $jostFont;
    font-size: pxToRemVal(44px);
    line-height: pxToRemVal(56px);
    font-weight: 700;
    font-style: normal;
  } @else if $variant == tablet-heading2 {
    font-family: $jostFont;
    font-size: pxToRemVal(36px);
    line-height: pxToRemVal(44px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == tablet-heading3 {
    font-family: $jostFont;
    font-size: pxToRemVal(32px);
    line-height: pxToRemVal(40px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == tablet-heading4 {
    font-family: $jostFont;
    font-size: pxToRemVal(28px);
    line-height: pxToRemVal(36px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == tablet-heading5 {
    font-family: $jostFont;
    font-size: pxToRemVal(24px);
    line-height: pxToRemVal(32px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == tablet-heading6 {
    font-family: $jostFont;
    font-size: pxToRemVal(20px);
    line-height: pxToRemVal(28px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == tablet-button-large {
    font-family: $jostFont;
    font-size: pxToRemVal(18px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 500;
  } @else if $variant == tablet-button {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(20px);
    font-style: normal;
    font-weight: 500;
  } @else if $variant == tablet-button-small {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(16px);
    font-style: normal;
    font-weight: 500;
  } @else if $variant == tablet-body {
    font-family: $jostFont;
    font-size: pxToRemVal(18px);
    line-height: pxToRemVal(28px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == tablet-body-small {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == tablet-body-tiny {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(20px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == tablet-body-tiny-italic {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(20px);
    font-style: italic;
    font-weight: 400;
  } @else if $variant == tablet-small-semibold {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 600;
  } @else if $variant == tablet-caption {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(16px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == tablet-label {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(16px);
    font-style: normal;
    font-weight: 500;
  }
}

// MOBILE

@mixin mobileTextVariant($variant) {
  @if $variant == mobile-heading1 {
    font-family: $jostFont;
    font-size: pxToRemVal(32px);
    line-height: pxToRemVal(40px);
    font-weight: 700;
    font-style: normal;
  } @else if $variant == mobile-heading2 {
    font-family: $jostFont;
    font-size: pxToRemVal(26px);
    line-height: pxToRemVal(32px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == mobile-heading3 {
    font-family: $jostFont;
    font-size: pxToRemVal(22px);
    line-height: pxToRemVal(28px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == mobile-heading4 {
    font-family: $jostFont;
    font-size: pxToRemVal(18px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == mobile-heading5 {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == mobile-heading6 {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(20px);
    font-style: normal;
    font-weight: 700;
  } @else if $variant == mobile-button-large {
    font-family: $jostFont;
    font-size: pxToRemVal(18px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 500;
  } @else if $variant == mobile-button {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(20px);
    font-style: normal;
    font-weight: 500;
  } @else if $variant == mobile-button-small {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(16px);
    font-style: normal;
    font-weight: 500;
  } @else if $variant == mobile-body {
    font-family: $jostFont;
    font-size: pxToRemVal(16px);
    line-height: pxToRemVal(24px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == mobile-body-small {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(20px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == mobile-body-tiny {
    font-family: $jostFont;
    font-size: pxToRemVal(12px);
    line-height: pxToRemVal(20px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == mobile-small-semibold {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(20px);
    font-style: normal;
    font-weight: 600;
  } @else if $variant == mobile-caption {
    font-family: $jostFont;
    font-size: pxToRemVal(12px);
    line-height: pxToRemVal(16px);
    font-style: normal;
    font-weight: 400;
  } @else if $variant == mobile-label {
    font-family: $jostFont;
    font-size: pxToRemVal(14px);
    line-height: pxToRemVal(16px);
    font-style: normal;
    font-weight: 500;
  }
}
