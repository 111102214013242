.tooltip {
	position: relative;
	display: inline-block;
	// border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 300px;
	background-color: rgba(86, 86, 86, 0.8);
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 5px;
	position: absolute;
	z-index: 1;
	bottom: 0;
	margin-left: 110%;

	/* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
	opacity: 0;
	transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}