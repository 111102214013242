.notification {
	position: relative;
	background-color: $colors-yellow-light;
	border: 2px solid $colors-yellow;
	border-radius: 0.2rem;
	color: $colors-grey-dark;
	display: flex;
	margin: 8px 0;
	justify-content: space-between;
	width: 100%;
	padding: 1.1rem;

	&__text {
		display: flex;
		align-items: center;
		padding-right: 1.5rem;
		font-weight: 600;
		.icon {
			display: inline-block;
			margin-right: 8px;
			min-width: 20px; // so flexbox doesn't force it to be smaller
			width: 20px;
			height: auto;
			path {
				fill: $colors-yellow;
			}
		}
	}

	&__action {
		color: $colors-white;
		background-color: $colors-purple;
		border: 1px solid $colors-purple;
		border-radius: 0.2rem;
		font-weight: 600;
		align-self: center;
		padding: 0.35rem 0.35rem 3px;
		cursor: pointer;
	}

	&__action-dismiss {
		position: absolute;
		top: 4px;
		right: 4px;
		width: 10px;
		height: 10px;
		font-size: 0;
		background-image: url($CDN_IMAGES + "/images/_icons/close-dark.svg");
		border: none;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;
		outline: none;
	}

	&--tip {
		color: $colors-purple;
		border-color: $colors-purple;
		background-color: $colors-white;
	}
}
