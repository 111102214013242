// todo: cleanup
fieldset {
	.field.file + .field.file {
		margin-top: 1.25 * $rhythm;
	}
}

.file {
	.file-upload-area {
		// @include block;
		margin-bottom: $input-margin-bottom;
	}

	input[type="file"] {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	// when a file is dragged over the control
	&.hover {
		border: 3px solid green;
	}

	label {
		color: #999;
		background-color: #EEE;
		display: inline-block;
		cursor: pointer;
		padding-left: 63px;
		height: 44px;
		line-height: 44px;
		width: 100%;
		// font-family: $body-font;
		font-size: 0.9rem;
		font-weight: normal;
		position: relative;
		margin-bottom: 0;
		overflow: hidden;

		span {
			// keep above progress indicator
			position: relative;
			// z-index: 2;
		}

	 	svg {
			// keep above progress indicator
			width: 21px;
			height: 16px;
			position: absolute;
			// z-index: 2;
			top: 50%;
			margin-top: -8px;
			left: 21px;

			.arrow, .base {
				fill: #999999;
			}

			&.icon-cross {
				left: auto;
				right: 21px;
				cursor: pointer;
				stroke: $colors-black;
			}
		}

		&.grabbing {
			$border: 3px;
			cursor: -webkit-grabbing;
			cursor: -moz-grabbing;
			cursor: grabbing;
			border: $border dashed $colors-black;
			opacity: 0.4;
			line-height: 38px;

			svg, span {
				margin-left: -$border;
			}
		}
	}
	
	.success {
		svg .arrow, svg .base {
			fill: green;
		}

		label {
			background-color: #ddffdd;
			cursor: default;
		}
	}
	.failure {
		svg .arrow, svg .base {
			fill: red;
		}

		label {
			background-color: #ffdddd;
		}
	}

	.progress {
		position: absolute;
		width: 0%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: #ddffdd;
		cursor: default;
		transition: width 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}

}