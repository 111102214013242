.delta {
  border: 1px solid #fff;
  display: none;
  margin-right: 5px;
  position: relative;
  transform: translateY(-3px);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  &.is-up {
    border-bottom: 5px solid $colors-dddd;
    display: inline-block;
  }
  &.is-down {
    border-top: 5px solid $colors-red-dark;
    display: inline-block;
  }
}