// Form field:
// SSN/TIN with message
.form__fieldset--tin {

	.ssn-warning {
		display: flex;
		align-items: center;
		color: $colors-grey-med-dark;
		font-size: 0.85rem;
		line-height: 1.15rem;
		margin-bottom: $rhythm;
		.lock-svg {
			margin-right: 8px;
			svg {
				width: 40px;
				height: 40px;
			}
		}
		.text {
			max-width: 400px;
		}
	}
}