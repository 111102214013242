@import 'mixins/buttons';

.section-nav {

	@include container($push: 0, $width: 3, $min-visible: 12, $position: absolute);

	&.fixed {
		position: fixed;
		top: 0;
		padding-top: 48px; // todo: make rem units not break the js for positioning
		padding-bottom: 2 * $rhythm;

		&.bottom {
			position: absolute;
			bottom: 0;
			top: initial;
		}
	}

	p {
		margin-top: $rhythm;
	}

	.button {
		@include button($background: $colors-yellow, $foreground: $colors-black);
		margin-top: 0.5 * $rhythm;
		display: inline-block;
		font-size: 1rem;
		padding-left: 2.25rem;
		padding-right: 2.25rem;
	}

	li {
		line-height: 1.5 * $rhythm;
		.active > a {
			font-weight: 700;
			color: #000000;
		}
	}

	.invest-button {
		margin-left: -8px;
		display: inline-block;
	}

	#chevron-right {
		stroke: $colors-purple;
	}

}