.nc-button {

	@include button-base;
	@include breakpoint(5) {
		@include block-margins;
		float: left;
	}

	&.primary {
		@include button($background: $colors-aaaa, $foreground: $colors-white, $accent: $colors-mink);
	}

	&.danger {
		@include button($background: $colors-red-dark, $foreground: $colors-white, $accent: $colors-mink);
		&.invert {
			background-color: #fff;
			color: $colors-red-dark;
			&:hover {
				background-color: $colors-red-dark;
				color: #fff;
			}
		}
	}

	&.save {
		@include button($background: $colors-tttt, $foreground: $colors-white, $accent: $colors-mink);
		&.invert {
			background-color: #fff;
			color: $colors-tttt;
			&:hover {
				background-color: $colors-tttt;
				color: #fff;
			}
		}
	}

	&.secondary {
		@include button($background: $colors-ssss, $foreground: $colors-black, $accent: $colors-mink);
		margin-left: 0;
		margin-top: 12px;
		float: none;
		line-height: 2.5rem;
		height: 2.5rem;
	}

	&.busy {
		color: rgba(0,0,0,0) !important;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 12" width="72px" height="12px"><style>@keyframes example{from{opacity: 0;}to{opacity: 1;}}.busy circle{fill:#fff;animation: example 1s ease-in infinite alternate;}.busy circle:nth-child(2){animation-delay:0.2s;}.busy circle:nth-child(3){animation-delay: 0.4s;}</style><g class="busy"><circle stroke="none" cx="6" cy="6" r="4"></circle><circle stroke="none" cx="30" cy="6" r="4"></circle><circle stroke="none" cx="54" cy="6" r="4"></circle></g></svg>');
		background-size: initial;

		&:hover {
			cursor: wait;
		}
	}

	&.long-content {
		font-size: 0.9em;
	}
}

.nc-buttons {
	&.flex-spaces-between {
		display: flex;
		justify-content: space-between;
	}
}

.nc-success {
	&.absolute-right {
		font-size: 18px;
		font-style: italic;
		right: 5px;
		position: absolute;
	}
}