.state-success,
.state-errors,
.on-error,
.on-success {
	display: none;
}

.error-message,
.server-error {
	clear: both;
	color: $colors-error;
	white-space: normal;
}

.error-message,
.success-message {
	// overrides for <p> styles
	// todo: have `view.js` inject <div> elements and/or remove greedy <p> styles
	margin-left: 0;
	margin-right: 0;
	max-width: 100%;
}

.error-message {
	font-size: 0.85rem;
	line-height: 1rem;
	// padding: 0 $input-padding-right 0 $input-padding-left;
	padding: 8px 8px 0;
}

.success-message {
	color: $colors-success;
	clear: both;
}

.warning {
	color: $colors-warning;
	clear: both;
}

.form {
	&__field {
		&.error {
			.form__label {
				color: $colors-error;
			}
		}
	}

	&__label,
	&__input,
	&__select,
	&__textarea {
		&.error {
			border-color: $colors-error;
			color: $colors-error;
		}
	}

	&__input {
		&.error {
			background-repeat: no-repeat;
			background-position: right 8px center;
			background-image: svg-url('<svg width="19px" height="17px" viewBox="0 0 19 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="#{$colors-error}" d="M18.6643397,15.1814764 L10.5057929,0.933809984 C10.0548179,0.14801706 8.92738057,0.14801706 8.47640557,0.933809984 L0.317858756,15.1814764 C-0.133116195,15.9672693 0.420353063,16.9598499 1.32230296,16.9598499 L17.6393966,16.9598499 C18.5618454,16.9598499 19.1153146,15.9672693 18.6643397,15.1814764 L18.6643397,15.1814764 Z M9.23486346,5.29702859 C9.72683616,5.17295602 10.2188088,5.42110115 10.4442963,5.8760339 C10.5262917,6.04146399 10.5467906,6.22757284 10.5262917,6.39300293 C10.485294,6.90997195 10.4647952,7.42694096 10.4442963,7.96458875 C10.4032986,8.77106049 10.341802,9.59821093 10.3008042,10.4046826 C10.2803054,10.6528277 10.2803054,10.9009729 10.2598065,11.1697967 C10.2393077,11.6040508 9.91132589,11.9349109 9.48084977,11.9349109 C9.07087254,11.9349109 8.72239196,11.6040508 8.70189307,11.1904755 C8.64039649,9.92907105 8.55840103,8.66766666 8.49690446,7.4062622 C8.47640557,7.07540205 8.45590676,6.72386311 8.43540788,6.39300293 C8.43540788,5.89671266 8.76338965,5.42110115 9.23486346,5.29702859 L9.23486346,5.29702859 Z M9.50134866,14.7679012 C8.92738057,14.7679012 8.47640557,14.2922897 8.47640557,13.7132844 C8.47640557,13.1342791 8.94787938,12.6586676 9.52184754,12.6586676 C10.0958156,12.6586676 10.5467906,13.1342791 10.5467906,13.7339631 C10.5262917,14.2922897 10.0548179,14.7679012 9.50134866,14.7679012 L9.50134866,14.7679012 Z" id="Shape"></path></svg>');
			cursor: pointer;
		}
	}

	&__select {
		&.error {
			background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 7" width="24px" height="7px"><path d="M0 0 L14 0 L7 7" fill="#{$colors-error}"></path></svg>');
		}
	}

	// used in secure-record.js (SSN fields)
	.replace-input {
		.form__link {
			font-size: 0.85rem;
		}
	}
}