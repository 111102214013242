body.landing-page {

	main {

		@include centered-container;

		@include breakpoint(10) {
			text-align: center;
		}

		& > div, h1 {
			@include block($width: 12, $push:(0:0, 14:1, 16:2, 18:3, 20:4, 22:5, 24:6));
		}

		h2 {
			@include block($width: 12, $push:(0:0, 14:1, 16:2, 18:3, 20:4, 22:5, 24:6));
			text-align: left;
		}

		& > p {
			// @include block($width: 8, $push:(0:0, 10:1, 12: 2, 14:3, 16:4, 18:5, 20:6, 22:7, 24:8));
			@include block($width: 6, $push:(0:0, 10:2, 12:3, 14:4, 16:5, 18:6, 20:7, 22:8, 24:9));
		}
		.button {
			@include block($width: 6, $push:(0:0, 10:2, 12:3, 14:4, 16:5, 18:6, 20:7, 22:8, 24:9));
			@include button($background: $colors-yellow, $foreground: $colors-black);
			margin-bottom: 0.5 * $rhythm;
		}

		.companies {
			@include block($width: (0:6,8:8));
		}
		.left {
			text-align: left;
		}
	}


}

body.landing-page-left {

	main {

		@include container($push:(0:0, 18:3));

		h1, .co-brand, p, ul {
			clear: both;
		}

		.co-brand, h1 {
			@include block($width: 12);
		}
		.co-brand {
			margin-bottom: $rhythm;
			img {
				height: 3rem;
				@include breakpoint(12) {
					height: 4.5rem;
				}
			}
		}
		p {
			// @include block($width: 8);
		}
		.button {
			// @include block($width: 6, $push:(0:0, 10:2, 12:3, 14:4, 16:5, 18:6, 20:7, 22:8, 24:9));
			@include button($background: $colors-yellow, $foreground: $colors-black);
			margin-bottom: 0.5 * $rhythm;
		}
	}

}

body.landing-page, body.landing-page-left {

	main {
		.company, .promo {
			display: inline-block;
			margin-bottom: 1.5rem;

			& > div {
				display: inline-block;
				float: left;
			}

			.hero {
				@include block($width: (0:6, 14:7, 16:8, 18:9, 20:10, 22:11, 24:12));
			}
			.description {
				@include block($width: (0:6, 14:7, 16:8, 18:9, 20:10, 22:11, 24:12));
			}
			.button {
				margin: 1rem 0 0 0;
			}

			img {
				width: 100%;
			}
		}
	}
}