// TODO: rename, too general.
// This is the accordion/show-more module used on entrepreneurs/FAQ
.section {
  .section-title {
    position: relative;
    a {
      color: #3461a8;
      @include remove-underline;
    }
    &:before {
      content: svg-url(
        '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><path d="M3 1L7 5L3 9" vector-effect="non-scaling-stroke" fill="none" stroke="#777777" stroke-width="1"/></svg>'
      );
      left: -1em;
      top: 1px;
      position: absolute;
    }
  }

  .section-body {
    display: none;
  }

  &.show {
    .section-body {
      display: block;
    }

    .section-title {
      &:before {
        transition: all 0.2s ease-in-out;
        transform: rotate(90deg);
      }
    }
  }
}
