$background-color: $colors-white;
$background-color-marketing: #f7f5f1;
$background-color-entrepreneur: #003c8e;

$selection-color: rgba(94, 200, 239, 0.5);

$link-color: #ffc666;
// $link-color-visited: #6c4ea5;
// $link-color-visited-hover: #2a095a;
$link-color-hover: #ff4564;
// $link-color-active: #003c8e;

$measure: 8; // in columns

$link-underline-background-color: $background-color;
$link-underline-width: 1px;
$link-underline-offset: 1px;