.pagination-controls {
	display: flex;
	justify-content:  space-between;
	padding-top: 10px;
	width: 210px;

	button {
		padding: 5px 10px 5px 10px;
		border-radius: 10px;
		border-width: 2px;
		outline: none;
	}

	.page-number-wrapper {
		align-self:  center;
		text-align: center;
		width: 20px;
	}

	.page-number {
		font-weight:  bold;
		align-self:  center;
		width: 27px;
		text-align: center;
	}
}