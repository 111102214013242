// Sizes:   Large / Regular / Small
// Type: Primary / Secondary / Tertiary
// States: Disabled / Default / Focused / Hover / Active

@import "../functions/rem-conversion.scss";
@import "../mixins//index-mixins.scss";
@import "../tokens/colors.scss";

@mixin buttonComponent($size, $type) {
  @if $size == large {
    padding: pxToRemVal(16px) pxToRemVal(20px);
    @include webTextVariant("web-button-large");
    @include flex(
      $justifyContent: center,
      $alignItems: center,
      $gap: pxToRemVal(8px)
    );
    border-radius: 8px;
  } @else if $size == regular {
    padding: pxToRemVal(12px) pxToRemVal(16px);
    @include webTextVariant("web-button");
    @include flex(
      $justifyContent: center,
      $alignItems: center,
      $gap: pxToRemVal(8px)
    );
    border-radius: pxToRemVal(8px);
  } @else if $size == small {
    padding: pxToRemVal(8px) pxToRemVal(12px);
    @include webTextVariant(" web-button-small");
    @include flex(
      $justifyContent: center,
      $alignItems: center,
      $gap: pxToRemVal(8px)
    );
    border-radius: pxToRemVal(8px);
  }

  @if $type == primary {
    background-color: $primary-default-700;
    color: #ffffff;

    &:disabled {
      background-color: $primary-200;
    }

    &:focus {
      background-color: $primary-default-700;
      @include border($color: $neutral-100, $width: pxToRemVal(3px));
    }

    &:hover {
      background-color: $primary-800;
    }

    &:active {
      background-color: $primary-1000;
    }
  }

  @if $type == secondary {
    @include border($color: $primary-default-700);
    background-color: #ffffff;
    color: $primary-default-700;

    &:disabled {
      @include border($color: $primary-200);
      color: $primary-200;
    }

    &:focus {
      @include border($color: $neutral-300, $width: pxToRemVal(4px));
    }

    &:hover {
      @include border($color: $primary-800);
      background-color: $primary-200;
      color: $primary-800;
    }

    &:active {
      @include border($color: $primary-800);
      background-color: $primary-300;
      color: $primary-900;
    }
  }

  @if $type == tertiary {
    color: $primary-default-700;

    &:disabled {
      color: $primary-200;
    }

    &:focus {
      @include border($color: $neutral-300, $width: pxToRemVal(4px));
      color: $primary-default-700;
    }

    &:hover {
      background-color: $neutral-100;
      color: $primary-800;
    }

    &:active {
      color: $primary-900;
      background-color: $neutral-200;
    }
  }
}
