.dual-cta {
  @include clearfix;
  background: #FFC666;
  .btn {
		@include button-base;
		@include button($background: #6C4EA5, $foreground: $colors-white, $accent: $colors-mink);
		display: inline-block;
		margin-bottom: 0;
		font-weight: 400;
		width: auto;
	}
  p {
    max-width: 100%;
  }
  .left,
  .right {
    background: #FFD185;
    font-size: 1.125rem;
    font-weight: 400;
    padding: 1.5 * $rhythm;
    text-align: center;
  }
  .right {
    background: #FFC666;
  }
  @include breakpoint(8) {
    .left,
    .right {
      float: left;
      width: 50%;
    }
  }
}
