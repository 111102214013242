@import "lib/lity";
@import "lib/flickity";
@import "lib/OverlayScrollbars";
@import "../../mixins/index-mixins.scss";
@import "../../tokens/colors.scss";
@import "../../functions/rem-conversion.scss";
@import "../../common/button-component.scss";

.p-index {
  .section {
    // ------------------------ EXPLORE INVESTMENTS ---------------
    &--offerings {
      padding: pxToRemVal(112px) pxToRemVal(24px);
      background-color: #f9f9f9;
      overflow: hidden;

      .flickity-viewport {
        width: 100%;
        overflow: visible;
      }

      .section {
        &__content.row {
          max-width: pxToRemVal(1156px);
          width: 100%;
          margin: 0 auto;
          @include flex($direction: column, $alignItems: flex-start);

          .company-tiles {
            padding-top: pxToRemVal(20px);
            overflow: visible;
          }
        }

        &__header-content {
          width: 100%;
          @include flex($justifyContent: space-between);
          padding-bottom: pxToRemVal(28px);
        }

        &__title {
          color: $soft-black;
          text-align: left;
          margin-bottom: 12px;
          @include webTextVariant(web-heading2);
        }

        &__subtitle {
          color: $soft-black;
          text-align: center;
          @include webTextVariant(web-body);
          margin-bottom: 0;
        }

        &__button {
          @include flex($justifyContent: center);

          .link {
            text-align: center;
            @include buttonComponent("large", "primary");
            justify-content: center;
          }
        }

        &__footer {
          margin-top: auto;
        }
      }

      // MAX -Media for Tablet
      @media screen and (max-width: $tablet-max-width) {
        padding: pxToRemVal(56px) pxToRemVal(24px);

        .tiles__navigation {
          @include flex($justifyContent: flex-start);
        }

        .section {
          &__title {
            @include tabletTextVariant("tablet-heading2");
          }

          &__subtitle {
            @include tabletTextVariant("tablet-body-small");
          }
        }
      }

      // Max - Media for Mobile
      @media screen and (max-width: $mobile-max-width) {
        .section {
          &__title {
            @include mobileTextVariant("mobile-heading2");
          }

          &__subtitle {
            text-align: left;
            @include mobileTextVariant("tablet-body");
          }

          &__header-content {
            @include flex($direction: column, $gap: pxToRemVal(12px));
          }

          &__button {
            @include flex($justifyContent: flex-start);
          }
        }
      }
    }

    // --------------------- OUR SUCCESS STORIES -------------
    &--stories {
      background-color: #ffffff;
      padding: pxToRemVal(112px) pxToRemVal(24px);

      .section {
        &__content.row {
          max-width: pxToRemVal(1156px);
          width: 100%;
          margin: 0 auto;
          @include flex($direction: column, $alignItems: center);
        }

        &__title {
          color: $soft-black;
          text-align: center;
          margin-bottom: pxToRemVal(12px);
          @include webTextVariant(web-heading2);
        }

        &__subtitle {
          color: $soft-black;
          text-align: center;
          @include webTextVariant(web-body);
          margin-bottom: 0;
          width: 100%;
          max-width: 100%;
          padding-bottom: pxToRemVal(52px);
        }

        &__carousel {
          &__carousel {
            padding-top: pxToRemVal(40px);
          }
        }

        &__disclaimer {
          color: #a5a5b4;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          max-width: 684px;
          width: 100%;
          margin: 0 auto;
          padding-top: 48px;
        }
      }

      .carousel {
        &__nav {
          margin: 0 auto;
          margin-bottom: pxToRemVal(56px);
          max-width: pxToRemVal(467px);
          text-align: center;
          @include flex($alignItems: center, $justifyContent: center);

          .carousel__nav-item {
            cursor: pointer;
            padding: 0 pxToRemVal(20px);
            @include flex($justifyContent: center, $alignItems: center);
            height: pxToRemVal(40px);
            width: pxToRemVal(150px);
            @include border($color: transparent, $radius: pxToRemVal(56px));

            .state--active {
              display: none;
            }

            .state--default {
              height: 100%;
              @include flex($alignItems: center);
            }

            img {
              max-width: 100%;
            }

            &.active {
              @include border($color: $neutral-400, $radius: pxToRemVal(56px));
              background-color: #ffffff;

              .state--active {
                height: 100%;
                @include flex($alignItems: center);
              }

              .state--default {
                display: none;
              }
            }

            &:hover {
              .state--default {
                > svg g,
                > svg rect {
                  opacity: 0.7;
                }
              }
            }
          }
        }

        &__slides {
          position: relative;
        }

        &__slide {
          background-color: #f9f9f9;
          @include border($color: $neutral-100, $radius: pxToRemVal(24px));
          @include flex($alignItems: center, $gap: pxToRemVal(40px));
          padding: pxToRemVal(20px);
          width: 100%;
          opacity: 0;
          transition: opacity 0.5s ease;

          &.active {
            opacity: 1;
            position: relative;
          }
        }
      }

      .testimonial {
        text-align: center;
        width: 100%;

        .headshot {
          border-radius: pxToRemVal(16px);
          max-width: pxToRemVal(220px);
          width: 100%;
          min-height: pxToRemVal(220px);
          height: 100%;
          background-color: $neutral-100;

          &--small {
            display: none;
          }
        }

        &__container {
          @include flex($gap: pxToRemVal(52px));
        }

        .content {
          @include flex(
            $direction: column,
            $justifyContent: space-between,
            $alignItems: flex-start,
            $gap: pxToRemVal(40px)
          );
          flex: 1 0 0;

          .quote {
            color: $soft-black;
            @include webTextVariant(web-body);
            text-align: left;
            margin-bottom: 0;

            &--link {
              background-image: unset;
              text-decoration: underline;
              text-decoration-color: $primary-default-700;

              &:hover {
                color: $primary-default-700;
              }
            }

            @media screen and (max-width: $tablet-max-width) {
              @include tabletTextVariant("tablet-body");
            }

            @media screen and (max-width: $mobile-max-width) {
              @include mobileTextVariant("mobile-body");
            }
          }

          .name {
            color: $soft-black;
            @include webTextVariant(web-heading6);
            text-align: left;
            margin-bottom: 0;
          }

          .attribution {
            color: $neutral-500;
            @include webTextVariant(web-caption);
            text-align: left;
          }
        }

        .kpi {
          text-align: left;
          max-width: pxToRemVal(261px);
          padding-left: 20px;
          @include border($color: #f2f2f4, $side: left);

          &-description {
            color: $soft-black;
            @include webTextVariant(web-caption);
          }

          &-raise {
            color: $soft-black;
            @include webTextVariant(web-heading1);
            @include flex($alignItems: flex-start);

            > svg {
              margin-left: pxtoRemVal(5px) l;
            }
          }

          &-description {
            color: $soft-black;
            @include webTextVariant(web-body-small);
          }
        }
      }

      // MAX - Media for Tablet
      @media screen and (max-width: $tablet-max-width) {
        padding: pxToRemVal(56px) pxToRemVal(24px);

        .section {
          &__title {
            @include tabletTextVariant("tablet-heading2");
          }

          &__subtitle {
            @include tabletTextVariant("tablet-body-small");
          }
        }

        .testimonial {
          .kpi {
            &-raise {
              @include tabletTextVariant("tablet-heading1");
            }
          }
        }
      }

      // MAX - Media 900px
      @media screen and (max-width: pxToRemVal(900px)) {
        .testimonial {
          .headshot {
            display: none;

            &--small {
              display: block;
              width: pxToRemVal(52px);
              height: pxToRemVal(52px);
              border-radius: pxToRemVal(52px);
            }
          }

          .content {
            .function {
              @include flex();
            }
          }
        }
      }

      // MAX - Media 600px
      @media screen and (max-width: pxToRemVal(600px )) {
        .carousel {
          &__nav {
            .carousel__nav-item {
              padding: 0 pxToRemVal(10px);

              svg {
                width: 6.25rem;
              }
            }
          }

          &__slide {
            @include flex($direction: column-reverse);
          }
        }

        .testimonial {
          .kpi {
            max-width: 100%;
            @include border($color: #f2f2f4, $side: bottom);
            border-left: none;
          }
        }
      }

      // MAX - Media for Mobile
      @media screen and (max-width: $mobile-max-width) {
        .section {
          &__title {
            @include mobileTextVariant("mobile-heading2");
          }

          &__subtitle {
            @include mobileTextVariant("tablet-body");
          }
        }

        .testimonial {
          .kpi {
            &-raise {
              @include mobileTextVariant("mobile-heading1");
            }
          }
        }
      }
    }

    // --------- ABOUT ----------
    &--about {
      padding: pxToRemVal(112px) pxToRemVal(24px);
      background-color: #f9f9f9;

      .section {
        &__content {
          max-width: pxToRemVal(1156px);
          width: 100%;
          margin: 0 auto;
          @include flex($direction: column, $alignItems: center);
        }

        &__title {
          color: $soft-black;
          text-align: center;
          @include webTextVariant(web-heading2);
          max-width: 100%;
          width: 100%;
          margin-bottom: 12px;
        }

        &__subtitle {
          color: $soft-black;
          text-align: center;
          @include webTextVariant(web-body);
          margin-bottom: 0;
          padding-bottom: pxToRemVal(22px);
          max-width: 684px;
          width: 100%;
        }

        &__cards {
          padding-top: pxToRemVal(84px);
          @include flex($alignItems: flex-start, $gap: pxToRemVal(24px));
        }
      }

      .about-card {
        @include flex(
          $direction: column,
          $justifyContent: center,
          $alignItems: flex-start,
          $gap: pxToRemVal(8px)
        );
        max-width: pxToRemVal(369px);
        width: 100%;
        padding: pxToRemVal(50px) pxToRemVal(20px) pxToRemVal(24px)
          pxToRemVal(20px);
        border-radius: pxToRemVal(12px);
        background-color: $neutral-100;
        box-shadow: 0 pxToRemVal(6px) pxToRemVal(20px) 0
          rgba(108, 78, 165, 0.24);
        @include position();

        &__icon {
          padding: pxToRemVal(10px);
          @include flex($justifyContent: center, $alignItems: center);
          border-radius: pxToRemVal(12px);
          background-color: $primary-800;
          box-shadow: 0 pxToRemVal(6px) pxToRemVal(12px) 0 rgba(0, 0, 0, 0.12);
          @include position($position: absolute, $top: pxToRemVal(-26px));
          > svg path {
            fill: #ffffff;
          }
        }

        &__title {
          color: $soft-black;
          @include webTextVariant(web-small-semibold);
          margin: 0;
        }

        &__desscription {
          color: $neutral-600;
          @include webTextVariant(web-body-small);
          margin: 0;
        }
      }

      // MAX - Media for Tablet
      @media screen and (max-width: $tablet-max-width) {
        padding: pxToRemVal(56px) pxToRemVal(24px);

        .section {
          &__title {
            @include tabletTextVariant("tablet-heading2");
          }

          &__subtitle {
            @include tabletTextVariant("tablet-body-small");
          }
        }
      }

      //MAX - Media 1060px
      @media screen and (max-width: pxToRemVal(1060px)) {
        .section {
          &__cards {
            @include flex($direction: column, $gap: pxToRemVal(24px));
          }
        }

        .about-card {
          max-width: pxToRemVal(540px);
          margin-bottom: pxToRemVal(24px);
        }
      }

      //MAX - Media for Mobile
      @media screen and (max-width: $mobile-max-width) {
        .section {
          &__title {
            @include mobileTextVariant("mobile-heading2");
          }

          &__subtitle {
            @include mobileTextVariant("tablet-body");
          }
        }
      }
    }

    // ---------LEVERAGE POWER COMMUNITY ---------------

    &--entrepreneurs {
      background-color: $primary-1000;
      padding: pxToRemVal(72px) pxToRemVal(24px);

      .section {
        &__content {
          max-width: pxToRemVal(1156px);
          width: 100%;
          margin: 0 auto;
          @include flex(
            $direction: column,
            $alignItems: center,
            $gap: pxToRemVal(24px)
          );
        }

        &__title {
          color: #ffffff;
          text-align: center;
          margin-bottom: 24px;
          @include webTextVariant(web-heading4);
          max-width: 476px;
        }

        &__subtitle {
          color: #ffffff;
          text-align: center;
          @include webTextVariant(web-body);
          margin-bottom: 0;
          padding-bottom: pxToRemVal(32px);
          max-width: 682px;
          width: 100%;
        }

        &__button {
          @include buttonComponent("regular", "secondary");
        }
      }
    }

    // -------- FEATURES --------
    &--features {
      background-color: #f9f9f9;
      padding: pxToRemVal(112px) pxToRemVal(24px);

      .section {
        &__content {
          max-width: pxToRemVal(1156px);
          width: 100%;
          margin: 0 auto;
          @include flex(
            $direction: column,
            $alignItems: flex-start,
            $gap: pxToRemVal(24px)
          );
        }

        &__title {
          color: $soft-black;
          @include webTextVariant(web-heading2);
          text-align: left;
          align-self: stretch;
          max-width: 100%;
          margin-bottom: 12px;
        }

        &__subtitle {
          color: $soft-black;
          @include webTextVariant(web-body);
          margin-bottom: 16px;
          text-align: left;
          align-self: stretch;
        }

        &__accordion {
          @include flex($alignItems: center, $gap: pxToRemVal(142px));
          width: 100%;
        }
      }

      .accordion {
        &__tabs-menu {
          @include flex(
            $direction: column,
            $alignItems: flex-end,
            $gap: pxToRemVal(12px)
          );
          flex: 1 0 0;
          width: pxToRemVal(566px);
        }

        &__tab {
          width: 100%;
          @include flex(
            $direction: column,
            $alignItems: flex-start,
            $gap: pxToRemVal(12px),
            $alignSelf: stretch,
            $justifyContent: center
          );
          padding: pxToRemVal(12px);
          @include border($color: #dcdce1, $side: bottom);

          .accordion__header-icon {
            background-color: $primary-200;

            > svg path {
              fill: #47336d;
            }
          }
        }

        &__header {
          @include flex(
            $alignItems: center,
            $gap: pxToRemVal(12px),
            $alignSelf: stretch,
            $justifyContent: space-between
          );
          width: 100%;
        }

        &__header-title {
          @include flex($alignItems: center, $gap: pxToRemVal(12px));
        }

        &__header-icon {
          @include flex($alignItems: center);
          padding: pxToRemVal(12px);
          border-radius: pxToRemVal(48px);
          background-color: #ffffff;

          > svg path {
            fill: #707087;
          }
        }

        &__header-text {
          color: #2e2e40;
          flex: 1 0 0;
          @include webTextVariant(web-heading5);
          margin-bottom: 0;
        }

        &__description {
          color: #484865;
          @include webTextVariant(web-body-small);
        }

        &__content {
          width: 100%;
          padding-left: 57px;
        }

        &__image {
          svg {
            width: 100%;
          }
        }
      }

      // MAX - Media for tablet
      @media screen and (max-width: $tablet-max-width) {
        padding: pxToRemVal(56px) pxToRemVal(24px);

        .section {
          &__title {
            @include tabletTextVariant("tablet-heading2");
            text-align: center;
          }

          &__subtitle {
            @include tabletTextVariant("tablet-body-small");
            padding-bottom: pxToRemVal(30px);
          }
        }
      }

      // MAx - Media  1000px
      @media screen and (max-width: pxToRemVal(1000px)) {
        .section {
          &__accordion {
            @include flex($direction: column-reverse, $gap: 2rem);

            .accordion__image {
              width: 100%;
              max-width: 100%;
              border-radius: 20px;
              background: linear-gradient(
                107deg,
                #6c4ea5 -9.73%,
                #3c2e19 54.74%
              );
              box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);

              > svg path {
                fill: transparent;
              }
            }

            .accordion__tabs-menu {
              width: 100%;
            }
          }
        }
      }

      // MAx - Media 960px
      @media screen and (max-width: pxToRemVal(960px)) {
        .accordion {
          &__tabs-menu {
            width: 100%;
          }
        }
      }

      //MAX - Media for mobile
      @media screen and (max-width: $mobile-max-width) {
        .section {
          &__title {
            @include mobileTextVariant("mobile-heading2");
            text-align: center;
          }

          &__subtitle {
            @include mobileTextVariant("tablet-body");
            padding-bottom: 0;
          }
        }
      }
    }

    // --------- NEWS ---------------
    &--news {
      background-color: $neutral-100;
      padding: pxToRemVal(112px) pxToRemVal(24px);
      overflow: hidden;

      .flickity-viewport {
        width: 100%;
        overflow: visible;
      }

      .section {
        &__content {
          max-width: pxToRemVal(1156px);
          width: 100%;
          margin: 0 auto;
          @include flex(
            $direction: column,
            $alignItems: flex-start,
            $gap: pxToRemVal(24px)
          );
        }

        &__header {
          @include flex($justifyContent: space-between);
          width: 100%;
          padding-bottom: pxToRemVal(28px);
        }

        &__header-button {
          margin-top: auto;
          width: fit-content;
        }

        &__button {
          @include buttonComponent("regular", "secondary");
        }

        &__title {
          color: $soft-black;
          @include webTextVariant(web-heading2);
          text-align: left;
          align-self: stretch;
          max-width: 100%;
          margin-bottom: 12px;
        }

        &__subtitle {
          color: $soft-black;
          @include webTextVariant(web-body);
          margin-bottom: 0;
          max-width: pxToRemVal(768px);
        }

        &__footer--mobile {
          display: none;
        }
      }

      .press-items {
        @include flex(
          $direction: column,
          $alignItems: flex-start,
          $gap: pxToRemVal(24px)
        );
        width: 100%;
        padding-bottom: 0;
        overflow: visible;
      }

      .press-item {
        @include flex(
          $direction: column,
          $alignItems: center,
          $gap: pxToRemVal(20px)
        );
        flex-shrink: 0;
        max-width: pxToRemVal(370px);
        width: 100%;
        padding: pxToRemVal(16px);
        border-radius: pxToRemVal(20px);
        background-color: #ffffff;
        margin: 0 pxToRemVal(12px);
        box-shadow: 0 pxToRemVal(4px) pxToRemVal(10px) 0 rgba(0, 0, 0, 0.06);
        transition: transform 300ms ease-out;

        &__slides {
          @include flex($alignItems: flex-start, $gap: pxToRemVal(24px));
          width: 100%;
        }

        &__image {
          @include flex($alignItems: flex-start, $alignSelf: stretch);
          height: pxToRemVal(200px);
          padding: pxToRemVal(12px);
          border-radius: pxToRemVal(12px);
          background: lightgray 50% / cover no-repeat;
          width: 100%;

          img {
            width: 100%;
          }
        }

        &__content {
          padding-top: pxToRemVal(20px);
        }

        &__keyword {
          @include flex($alignItems: flex-start);
          padding: pxToRemVal(2px) pxToRemVal(12px);
          border-radius: pxToRemVal(8px);
          background-color: rgba(255, 255, 255, 0.6);
        }

        &__date {
          color: $neutral-500;
          @include webTextVariant(web-caption);
          padding-top: pxToRemVal(20px);
        }

        &__title {
          color: $soft-black;
          @include webTextVariant(web-heading5);
          margin-bottom: pxToRemVal(8px);
        }

        &__body {
          color: $soft-black;
          @include webTextVariant(web-body-small);
          margin-bottom: pxToRemVal(20px);
        }

        &__navigation {
          padding: 0;
        }

        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 pxToRemVal(4px) pxToRemVal(16px) 0 rgba(0, 0, 0, 0.1);
        }
      }

      // MAX - MEdia for tablet
      @media screen and (max-width: $tablet-max-width) {
        padding: pxToRemVal(56px) pxToRemVal(24px);

        .section {
          &__header {
            @include flex($justifyContent: center);
          }

          &__title {
            @include tabletTextVariant("tablet-heading2");
          }

          &__subtitle {
            @include tabletTextVariant("tablet-body-small");
          }

          &__footer--mobile {
            @include flex($justifyContent: center);
            width: 100%;
          }
        }

        .press-items {
          .press-item__navigation {
            @include flex($justifyContent: flex-end);
            width: 100%;
          }
        }
      }

      //MAX - Media for mobile
      @media screen and (max-width: $mobile-max-width) {
        .section {
          &__header {
            @include flex($direction: column, $gap: pxToRemVal(12px));
          }

          &__title {
            @include mobileTextVariant("mobile-heading2");
          }

          &__subtitle {
            @include mobileTextVariant("tablet-body");
          }
        }
      }
    }

    // --------- FAQ --------

    &--faq {
      background-color: #ffffff;
      padding: pxToRemVal(112px) pxToRemVal(24px);

      .section {
        &__content {
          max-width: pxToRemVal(1156px);
          width: 100%;
          margin: 0 auto;
          @include flex($alignItems: flex-start, $gap: pxToRemVal(24px));
        }

        &__title {
          color: $soft-black;
          @include webTextVariant(web-heading2);
          margin-bottom: 12px;
        }

        &__subtitle {
          color: $soft-black;
          @include webTextVariant(web-body);
          margin-bottom: 0;
          padding-bottom: pxToRemVal(24px);
        }

        &__button {
          .link {
            width: min-content;
            @include buttonComponent("regular", "secondary");
          }
        }

        &__accordion {
          max-width: pxToRemVal(684px);
          width: 100%;

          .state--active {
            display: none;
          }

          .accordion {
            &__item {
              @include border($color: $neutral-200, $radius: pxToRemVal(12px));
              margin-bottom: pxToRemVal(12px);
              list-style: none;
              cursor: pointer;

              &.active {
                .state--default {
                  display: none;
                }

                .state--active {
                  display: inline;
                }
              }
            }

            &__title {
              padding: pxToRemVal(16px) pxToRemVal(24px);
              color: #2e2e40;
              position: relative;
              @include webTextVariant(web-small-semibold);
              @include flex(
                $justifyContent: space-between,
                $alignItems: center,
                $alignContent: center
              );
              list-style: none;
              transition: color 0.3s;

              > svg {
                width: pxToRemVal(28px);
                height: pxToRemVal(28px);
                path {
                  fill: $soft-black;
                }
              }
            }

            &__content {
              padding: 0 pxToRemVal(24px);
              color: #2e2e40;
              @include webTextVariant(web-body-small);
              max-height: 0;
              opacity: 0;
              overflow: hidden;
              will-change: max-height;
              transition: all 0.5s ease-in-out;

              &.show {
                opacity: 1;
                margin-bottom: pxToRemVal(16px);
              }
            }
          }
        }
      }

      // MAX - Media for tablet
      @media screen and (max-width: $tablet-max-width) {
        padding: pxToRemVal(56px) pxToRemVal(24px);

        .section {
          &__content {
            @include flex($direction: column);
          }

          &__title {
            @include tabletTextVariant("tablet-heading2");
          }

          &__subtitle {
            @include tabletTextVariant("tablet-body-small");
          }
        }
      }

      //MAX - MEdia for mobile
      @media screen and (max-width: $mobile-max-width) {
        .section {
          &__title {
            @include mobileTextVariant("mobile-heading2");
          }

          &__subtitle {
            @include mobileTextVariant("tablet-body");
          }
        }
      }
    }

    // ------ TESTIMONIALS -----

    &--testimonials {
      padding: pxToRemVal(112px) pxToRemVal(24px);

      .flickity-viewport {
        width: 100%;
        overflow: visible;
      }

      .section {
        &__content {
          max-width: pxToRemVal(1156px);
          width: 100%;
          margin: 0 auto;
          @include flex(
            $direction: column,
            $alignItems: flex-start,
            $gap: pxToRemVal(48px)
          );
        }

        &__info {
          @include flex($direction: column, $alignItems: center);
          width: 100%;
        }

        &__title {
          color: #fff;
          @include webTextVariant(web-heading2);
          text-align: center;
          align-self: stretch;
          max-width: 100%;
          margin-bottom: 12px;
        }

        &__subtitle {
          color: #fff;
          @include webTextVariant(web-body);
          margin-bottom: 0;
          max-width: pxToRemVal(684px);
          width: 100%;
          text-align: center;
        }

        &__carousel {
          @include flex(
            $direction: column,
            $alignItems: flex-start,
            $gap: pxToRemVal(24px)
          );
          width: 100%;
        }

        &__disclaimer {
          max-width: pxToRemVal(700px);
          margin: 0 auto;
          .disclaimer__text {
            color: $neutral-400;
            @include webTextVariant(web-caption);
          }
        }
      }

      .testimonial {
        &__slides {
          @include flex(
            $alignItems: flex-start,
            $gap: pxToRemVal(24px),
            $alignSelf: stretch
          );
          width: 100%;
        }

        &__row {
          @include flex(
            $direction: column,
            $alignItems: flex-start,
            $gap: pxToRemVal(24px)
          );
          flex: 1 0 0;
        }

        &__slide {
          @include flex(
            $direction: column,
            $alignItems: flex-start,
            $alignSelf: stretch,
            $justifyContent: space-between,
            $gap: pxToRemVal(20px)
          );
          max-width: pxToRemVal(370px);
          width: 100%;
          margin: 0 auto;
          padding: pxToRemVal(20px);
          background-color: #ffffff;
          @include border($color: $neutral-100, $radius: pxToRemVal(16px));
          box-shadow: 0 pxToRemVal(8px) pxToRemVal(16px) 0 rgba(0, 0, 0, 0.08);
        }

        &__quote {
          color: $neutral-800;
          @include webTextVariant(web-body-tiny);
        }

        &__footer {
          @include flex(
            $alignItems: center,
            $alignSelf: stretch,
            $gap: pxToRemVal(12px)
          );
        }

        &__investor {
          @include flex($direction: column, $alignItems: flex-start);
          flex: 1 0 0;
        }

        &__investor-name {
          color: $neutral-1000;
          @include webTextVariant(web-body-tiny-medium);
          font-weight: 600;
          margin-bottom: 0;
        }

        &__navigation {
          padding-top: pxToRemVal(32px);
        }
      }

      // MAX - Media for tablet
      @media screen and (max-width: $tablet-max-width) {
        padding: pxToRemVal(56px) pxToRemVal(24px);

        .section {
          &__title {
            @include tabletTextVariant("tablet-heading2");
          }

          &__subtitle {
            @include tabletTextVariant("tablet-body-small");
            max-width: 100%;
          }
        }

        .testimonial {
          &__slides {
            @include flex($direction: column, $alignItems: center);
          }

          &__slide {
            max-width: pxToRemVal(534px);
          }
        }
      }

      //MAX - Media for mobile
      @media screen and (max-width: $mobile-max-width) {
        .section {
          &__title {
            @include mobileTextVariant("mobile-heading2");
          }

          &__subtitle {
            @include mobileTextVariant("tablet-body");
          }
        }

        .testimonial {
          &__slides {
            @include flex($direction: column);
          }

          &__slide {
            max-width: 100%;
          }
        }
      }
    }

    // --------- EMBARK JOURNEY -------

    &--journey {
      background-color: #ffffff;
      padding: pxToRemVal(112px) pxToRemVal(24px);

      .section {
        &__content {
          max-width: pxToRemVal(1156px);
          width: 100%;
          margin: 0 auto;
          padding: pxToRemVal(112px) pxToRemVal(16px);
          @include flex(
            $direction: column,
            $alignItems: center,
            $justifyContent: center
          );
          border-radius: pxToRemVal(24px);
          background-color: $primary-default-700;
        }

        &__title {
          color: #fff;
          @include webTextVariant(web-heading3);
          align-self: stretch;
          text-align: center;
          max-width: 100%;
          margin-bottom: 16px;
        }

        &__subtitle {
          color: #fff;
          @include webTextVariant(web-body);
          margin-bottom: 48px;
          max-width: pxToRemVal(684px);
          width: 100%;
          text-align: center;
        }

        &__button {
          @include buttonComponent("large", "secondary");
        }
      }

      //MAX - MEdia for tablet
      @media screen and (max-width: $tablet-max-width) {
        padding: pxToRemVal(56px) pxToRemVal(24px);

        .section {
          &__title {
            @include tabletTextVariant("tablet-heading2");
          }

          &__subtitle {
            @include tabletTextVariant("tablet-body-small");
          }
        }
      }

      //MAX - MEdia for mobile
      @media screen and (max-width: $mobile-max-width) {
        .section {
          &__title {
            @include mobileTextVariant("mobile-heading2");
          }

          &__subtitle {
            @include mobileTextVariant("tablet-body");
          }
        }
      }
    }
  }

}

main.folio,
main.hero-generic,
main.analytics-app,
main.invest-view,
main.stealth,
main.final-authorization-view,
main.announcements-view,
main.listing-view,
main.admin-view,
main.user-view,
main.announcement-page {
  padding-top: 8rem;
}
