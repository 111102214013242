.form {
	text-align: left;

	// Resets
	input,
	select,
	textarea {
		&:focus {
			outline: none;
		}
	}

	label {
		float: none !important;
	}

	&__header {
		margin-bottom: 1.5 * $rhythm;
	}

	&__title {
		margin: 0;
		margin-bottom: 0.25 * $rhythm;
		font-size: 1.5rem;
		line-height: 2.2rem;
		font-weight: 400;
		font-family: 'Source Serif Pro', serif;
		// tag-based overrides (h2)
		max-width: 100%;
	}

	&__subtitle {
		color: $colors-grey-med-dark;
		margin: 0;
		margin-bottom: 0.5 * $rhythm;
		font-size: 1.125rem;
		line-height: 1.5rem;
		font-weight: 300;
	}

	&__section-title {
		font-weight: 600;
		font-size: 1.125rem;
    line-height: 1.5rem;
	}

	&__note {
		color: $colors-grey-med-dark;
		margin: 0;
		margin-bottom: 0.5 * $rhythm;
		font-size: 1.125rem;
		line-height: 1.5rem;
		font-weight: 400;
	}

	&__textblock {
		margin-bottom: 0.5 * $rhythm;
	}

	&__link {
		text-decoration: underline;
		color: $colors-purple;
	}

	&__list {
		margin-left: 2rem;
		margin-bottom: $rhythm;
		list-style-image: svg-url('<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="13"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.0283 7.12854L6.62853 11.5283C6.2814 11.8754 5.71859 11.8754 5.37145 11.5283C5.02432 11.1812 5.02432 10.6184 5.37145 10.2712L9.14269 6.5L5.37145 2.72876C5.02432 2.38163 5.02432 1.81882 5.37145 1.47169C5.71859 1.12455 6.2814 1.12455 6.62853 1.47169L11.0283 5.87146C11.3754 6.21859 11.3754 6.78141 11.0283 7.12854Z" fill="white" /></mask><g mask="url(#mask0)"><rect y="15" width="16" height="16" transform="rotate(-90 0 15)" fill="#6C4EA5" /></g></svg>');
		&.small {
			font-size: 0.85rem;
			line-height: 1.25rem;
			margin-left: 1.5rem;
		}
	}

	&__content-block {
		border: 1px solid $colors-grey-light;
		padding: 12px;
		margin-bottom: $rhythm;
	}

	& .--addPerson{
		margin-left: 0px;
		margin-bottom: 10px;
	}

	& .--savePerson{
		margin-left: 0px;
		margin-bottom: 10px;
	}

	.file-upload {
		margin: 0;
		.file-upload-area {
			margin-bottom: 0;
		}
	}

	&__fieldset {
		// General fieldset types:
		&--name {
			.form__field-note {
				margin-top: -1 * $rhythm;
				margin-bottom: $rhythm;
			}
		}
		// group of checkboxes
		&--checkboxes {
			.form__field {
				margin-bottom: 0.5 * $rhythm;
			}
		}
		// group of radio inputs
		&--radios {
			.form__input {
				padding-bottom: 0; // inherit bottom marin from radios
			}
			&.error {
				.form__input {
					border-color: $colors-error;
				}
			}
		}

		&--dob {
			& .form__label {
				visibility: hidden;
			}
		}

		&--employed {
			.field .form__input {
				padding-bottom: 10px;
			}
		}

	}

	.form__legend {
		color: $colors-forms-labels;
		font-size: 0.85rem;
		line-height: 1rem;
		font-weight: 400;
	}

	&__field {
		font-weight: 400;
		padding-top: 7px;
		position: relative;
		margin-bottom: $rhythm;

		&:focus-within {
			.form__label {
				color: $colors-purple;
				transition: color 0.25s ease-out; // to match input border-color transition
			}
			.form__input,
			.form__select,
			.form__textarea {
				color: $colors-purple-dark;
				border-color: $colors-purple;
				transition: all 0.25s ease-out; // to match input border-color transition
				outline: 0;
			}
			.form__select {
				background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 7" width="24px" height="7px"><path d="M0 0 L14 0 L7 7" fill="#{$colors-purple}"></path></svg>');
			}
		}

		&-note {
			color: $colors-grey-med-dark;
			font-weight: 600;
			font-size: 0.8rem;
			font-style: italic;
			margin-left: $input-padding-left;
		}

		&--checkbox {
			.error-message {
				margin-left: 33px; // left-aligned w/ label
			}
			&.error {
				color: $colors-error;
			}
		}

		// show a preceeding "$"
		&--currency {
			.form__input {
				// color: $colors-currency;
				padding-left: 22px;
			}
			&:before {
				content: '$';
				// color: $colors-currency;
				display: block;
				font-size: 1.5rem;
				position: absolute;
				left: 6px;
				top: 17px;
			}
			&:focus-within .form__input {
				color: $colors-purple;
			}
			&.error {
				&:before {
					color: $colors-error;
				}
			}
		}

		// a pseudo-field with text in it
		&--informational {
			background-color: $colors-offwhite;
			border: 1px solid $colors-input-borders;
			border-radius: 2px;
			color: $colors-grey-med-dark;
			font-size: 0.85rem;
			line-height: 1.15rem;
			padding: 1.25rem 12px 9px;
			p {
				margin: 0 0 0.5rem;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

	}

	&__input,
	&__select,
	&__textarea {
		display: block;
		font-size: 1rem;
		// line-height: 1.2rem;
		font-family: 'Source Sans Pro', Arial, serif;
		border: 1px solid $colors-input-borders;
		border-radius: 2px;
		color: #000;
		padding: (0.75 * $input-padding-top) (0.75 * $input-padding-right) (0.75 * $input-padding-bottom) (0.75 * $input-padding-left);
		width: 100%;

		&:disabled,
		&.disabled {
			color: $colors-nnnn;
			background-color: $colors-offwhite-light;
			user-select: none;
		}
	}


	&__label {
		background-color: #fff;
		color: $colors-forms-labels;
		font-family: 'Source Sans Pro', Arial, serif;
		font-size: 0.85rem;
		font-weight: 400;
		line-height: 1;
		padding: 2px 6px;
    position: absolute;
    top: -2px;
		left: $input-padding-left - 4px;
		z-index: 1;
	}

	&__select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background-repeat: no-repeat;
		background-position: 100% 50%;
		background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 7" width="24px" height="7px"><path d="M0 0 L14 0 L7 7" fill="#999"></path></svg>');
		padding-right: 34px !important;
		// line-height: 3rem;

		&::-ms-expand {
			display: none;
		}

		&.placeholder {
			color: $colors-nnnn;
			font-weight: 400;
			font-family: 'Source Sans Pro', Arial, serif;

			&.error {
				color: $colors-error;
			}
		}
	}

	&__option {
		color: $colors-purple;
		font-weight: 600;
	}

	&__button {
		border-radius: 0.125rem;
		padding: 0.75rem 2.5rem;
		font-size: 1.25rem;
		font-family: 'Source Sans Pro', 'Arial', sans-serif;
		margin-left: 0 !important; // todo

		// TODO: remove input[type] selectors from style, below will never work because of this
		// &--secondary {
		// 	background-color: $colors-yellow;
		// 	border-color: $colors-yellow;
		// 	color: #000;
		// 	&:hover {
		// 		background-color: $colors-yellow-light;
		// 	}
		// }

		&--busy {
			background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 12" width="72px" height="12px"><style>@keyframes example{from{opacity: 0;}to{opacity: 1;}}.busy circle{fill:#fff;animation: example 1s ease-in infinite alternate;}.busy circle:nth-child(2){animation-delay:0.2s;}.busy circle:nth-child(3){animation-delay: 0.4s;}</style><g class="busy"><circle stroke="none" cx="6" cy="6" r="4"></circle><circle stroke="none" cx="30" cy="6" r="4"></circle><circle stroke="none" cx="54" cy="6" r="4"></circle></g></svg>');
			background-repeat: no-repeat;
			background-position: 50% 50%;

			&:disabled {
				color: $colors-transparent;
			}
		}
	}

	&__textarea {
		resize: none;
		display: block; // removes extra bottom margin in Google Chrome
		padding: $input-padding;
		height: 8 * $rhythm;
		width: 100%;
	}

	&__error-message {
		height: 3.5rem;
		padding: 1rem 0;
		font-size: 1rem;
		line-height: 1.5rem;
		font-weight: 600;
		color: $colors-red;
	}

	&__action {
		color: $colors-purple;
		cursor: pointer;
		font-size: 0.85rem;
		line-height: 1.2rem;

		&:hover {
			opacity: 0.8;
		}
	}

	&__help {
		@include remove-underline;
		border-bottom: 1px solid $colors-mink;
	}

	// todo
	.file-upload .success {
		a {
			@include remove-underline;
			color: $colors-black;
		}
	}

	&__column {
		$col: &;
		@media screen and (min-width: $bp-md) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;

			&--2 > * {
				width: 48%;
			}
			&--3 > * {
				width: 31%;
			}

			.valign-center {
				// todo: more scalable
				align-self: center;
			}
		}

		&--xl {
			@media screen and (min-width: $bp-md) {
				display: block;
				> * {
					width: 100%;
				}
			}
			@media screen and (min-width: $bp-xl) {
				display: flex;
				// todo: DRY
				&#{$col}--2 > * {
					width: 48%;
				}
				&#{$col}--3 > * {
					width: 31%;
				}
			}
		}
	}
}

@media screen and (min-width: $bp-md) {
	.form {
		&__title {
			font-size: 2rem;
		}

		&__input,
		&__textarea,
		&__select {
			font-size: 1.2rem;
			padding: $input-padding;
		}

		&__field {
			&--currency {
				&:before {
					left: 7px;
					top: 20px;
				}
			}
		}
	}
}

// jbox overrides
.jBox-Modal {
	.form {
		.decision {
			padding-top: $rhythm;
			.accept,
			input[type="submit"] {
				margin-right: $rhythm;
			}
			.cancel {
				line-height: 3rem;
			}
		}
	}
	@media screen and (min-width: $bp-md) {
		.form {
			&__title {
				font-size: 1.5rem;
			}
			&__subtitle {
				font-size: 1.125rem;
			}
			&__input,
			&__textarea,
			&__select {
				font-size: 1rem;
				padding: (0.75 * $input-padding-top) (0.75 * $input-padding-right) (0.75 * $input-padding-bottom) (0.75 * $input-padding-left);
			}

			&__fieldset {
				&--radios {
					.form__input {
						padding-top: 14px;
					}
				}
			}
		}
	}
}