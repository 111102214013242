$CDN_IMAGES: "https://cdn.staging.netcapital.com";
@import 'base/reset';

@import 'base/variables';
// libs
@import 'lib/jbox.all';
@import 'lib/grapes.min';

// libs custom
@import 'blocks/jbox-custom';
@import 'blocks/grapes-custom';

@import 'base/functions';
@import 'base/authentication-status';
@import 'base/mixins';

@import 'mixins/columns';

@import 'typography/index';
@import 'forms/index';

@import 'atoms/base';
@import 'molecules/base';

@import 'mixins/avatar';
@import 'mixins/buttons';
@import 'mixins/footer';

.modal-content .title {
	@extend h1;
}

@import 'layouts/index';

@import 'blocks/btn';
@import 'blocks/buttons'; // todo: deprecate
@import 'blocks/company-tile';
@import 'blocks/dropdown';
@import 'blocks/event';
@import 'blocks/file-download';
@import 'blocks/footer';
@import 'blocks/footer-v2';
@import 'blocks/header';
@import 'blocks/dual-cta';
@import 'blocks/images';
@import 'blocks/main-menu';
@import 'blocks/modals';
@import 'blocks/password-strength';
@import 'blocks/module';
@import 'blocks/recent-announcements';
@import 'blocks/recent-primary-activity';
@import 'blocks/recent-trades';
@import 'blocks/recent-activity';
@import 'blocks/section-nav';
@import 'blocks/section';
@import 'blocks/social-icons';
@import 'blocks/scroll-x';
@import 'blocks/status';
@import 'blocks/tables';
@import 'blocks/tabs';
@import 'blocks/very-bad';
@import 'blocks/recommended-offerings';
@import 'blocks/tile-company';
@import 'blocks/has-tooltip';

@import 'blocks/initialize';

@import "form-blocks/_address.scss";
@import "form-blocks/_auth.scss";
@import "form-blocks/_payment-method.scss";
@import "form-blocks/_toggleable-field.scss";

// Page themes that should override defaults
@import 'themes/clipped-sections'; // e.g. homepage/signup

@import "pages/_about.scss";
@import "pages/_blog.scss";
@import "pages/_companies.scss";
@import "pages/_entrepreneurs.scss";
@import "pages/_events.scss";
@import "pages/_index.scss";
@import "pages/_investors.scss";
@import "pages/_landing-page.scss";
@import "pages/_log-in.scss";
@import "pages/_partner-lp.scss";
@import "pages/_secondary.scss";
@import "pages/_sign-up.scss";
@import "pages/_stealth.scss";
@import "pages/_waitlist.scss";
@import "pages/_wires.scss";


// todo: should these blocks be moved up?
@import 'blocks/notifications';
@import 'blocks/notification';
@import 'blocks/alert-notifications';
@import 'blocks/instructions';

@import "../mixins/index-mixins.scss";
@import "../tokens/colors.scss";
@import "../functions/rem-conversion.scss";

// todo: what's this?
* {
	background: none;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	text-decoration: none;
}

main {
	padding-top: $rhythm;
	padding-bottom: 6 * $rhythm;
// 	overflow-x: hidden; // don’t break if words don’t fit on insanely narrow screens
}

body {
	position: relative;
}

body.fixed,
html.modal-open body {
	overflow: hidden !important; // required to disable scrolling underneath modals
}

.row {
	@include centered-container;
}

.clearfix {
	@include clearfix;
}

html, body {
	// force horizontal scrolling until our device supports our minimum column width
	min-width: breakpoint-width(nth($breakpoints, 1));
	height: 100%;
}

p, h1, h2, h3, h4, h5, h6, ul, ol {
	@include block;
}

[v-cloak] > * {
	display: none !important;
}

[v-cloak]::before {
	content: '';
	// top: 0;
	// left: 0;
	// position: absolute;
	width: 100%;
	display: block;
	// height: 100%;
	background-color: $colors-white;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	min-height: 100px;
	background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 12" width="72px" height="12px"><style>@keyframes example{from{opacity: 0;}to{opacity: 1;}}.busy circle{fill:#000;animation: example 1s ease-in infinite alternate;}.busy circle:nth-child(2){animation-delay:0.2s;}.busy circle:nth-child(3){animation-delay: 0.4s;}</style><g class="busy"><circle stroke="none" cx="6" cy="6" r="5"></circle><circle stroke="none" cx="36" cy="6" r="5"></circle><circle stroke="none" cx="66" cy="6" r="5"></circle></g></svg>');
}

.aspect-ratio {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56%;

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
}

.rotate {
	&--45 {
		transform: rotate(45deg);
	}
	&--90 {
		transform: rotate(90deg);
	}
	&--180 {
		transform: rotate(180deg);
	}
	&--270 {
		transform: rotate(270deg);
	}
}

// todo: used for tables. remove completely
.wrapper {
	@include block;
}

#disclaimers-container {
  padding-top: pxToRemVal(100px);
  background: $hero-section-gradient;
}

.top-disclaimers {
	background: $primary-200;
	padding-top: $rhythm;

	p {
		max-width: 100%;
		@include webTextVariant("web-body-small");
		color: $soft-black;
		a {
			@include links-colors-inner($background-color: $primary-200, $link-color-hover: $link-color);
		}
	}
}

.closed-notice {
	background: #24094b;
	padding-top: $rhythm;
	padding-bottom: 0.25rem;
	opacity: 100%;

	p {
		max-width: 100%;
		@include centered-container;
		color: white;
		font-weight:400;
		font-size: 16px;
		a {
			@include links-colors-inner($background-color: #24094b, $link-color-hover: $link-color);

		}
	}
}

.bust-offering-cache {
	background: #FF4564;
	padding-top: $rhythm;
	padding-bottom: 0.25rem;

	p {
		max-width: 100%;
		@include centered-container;
		font-size: 0.85rem;
		a {
			@include links-colors-inner($background-color: #FF4564, $link-color-hover: $link-color);
			background-image: none;
			border-bottom: 1px solid #ffffff;
		}
	}
}

.backer-notification {
	background: #24094b;
	padding-top: $rhythm;
	border-bottom: 5px solid #ffffff;
	opacity: 100%;

	p {
		max-width: 100%;
		@include centered-container;
		color: white;
		font-weight:400;
		font-size: 20px;
		a.button {
			background: #ffc666;
			color: #000000;
			@include links-colors-inner($background-color: #ffc666, $link-color-hover: $link-color);
		}
	}
}

nav li a {
	@include remove-underline;
}

.hide {
	display: none;
}

small {
	font-size: smaller;
}

[data-nc-uneditable] {
	// overlay for uneditable page content
	cursor: not-allowed;
	position: relative;

	&:after {
		background: $disabled-gradient;
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}
