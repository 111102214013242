@import "lib/lity";
@import "lib/flickity";
@import "lib/OverlayScrollbars";
@import "../../mixins/index-mixins.scss";
@import "../../tokens/colors.scss";
@import "../../functions/rem-conversion.scss";
@import "../../common/button-component.scss";
@import "../../common//input-component.scss";

.sign-up-page {
	.hero-section__title,
	form,
	.sign-up-confirm {
		max-width: pxToRemVal(672px);
		margin: $rhythm auto $rhythm*2;
		float: none;
		.form__input {
			@include inputComponent();
		}
	}
	.log-in-cta {
		margin: $rhythm auto;
		text-align: center;
		a {
			font-weight: 400;
		}
	}
}

.hero-homepage {
	.modal-mask {
		overflow: hidden;
		border-radius: pxToRemVal(40px);
		width: 95%;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
	} 

	.open .modal-mask {
		transform: translateY(4.5%);
		height: 92%;
		min-height: 92%;
	}

	.modal-wrap.open {
		.close { 
			margin-left: 90px;	
			top: 5rem;
		}

		&::before {
			content: "";
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1000;
			background-color: rgba(0,0,0,0.5);
			display: flex;
		}
	}
}

.login-view {
	display: flex !important;
	@include flex($justifyContent: space-between);
	width: 100%;
	padding: pxToRemVal(20px);

  
	.sign-up {
	  width: 100%;
	  flex: 1 0 50%;
	  @include flex(
		$direction: column,
		$justifyContent: center,
		$alignItems: center,
		$gap: pxToRemVal(23px)
	  );
  
	  &__header {
		width: 100%;
		@include flex($direction: column, $justifyContent: center, $alignItems: center);
	  }
  
	  &__logo {
		width: pxToRemVal(112px );
		height: pxToRemVal(23px);
		margin: 0 auto;
	  }
  
	  &__subtitle {
		text-align: center;
		color: $neutral-500;
		@include webTextVariant(web-body-tiny);
		margin-bottom: 0;
	  }
		
		.confirmation-code-form .form {
			width: 100%;
		}
  
	  &-form,
		&-confirm {
		max-width: pxToRemVal(288px);

		.fieldform__field {
		  padding-bottom: pxToRemVal(28px);
		}
  
		.form__input {
		  @include inputComponent();
		}

		.form__field {
			margin-bottom: pxToRemVal(32px);
		}
  
		.form__button {
		  @include buttonComponent("regular", "primary");
		}
  
		.decision {
		  @include flex($direction: column);
		}
	  }
  
	  &__form-label {
		padding-bottom: pxToRemVal(4px);
		@include labelText();
	  }
  
	  &__footer-text {
		color: #000;
		@include webTextVariant(web-body-tiny-medium);
  
		.supplement {
		  color: $primary-default-700;
		  text-decoration-color: $primary-default-700;

		  a {
			background-image: unset;
			text-decoration: underline !important;
		  }
		}
	  }
  
	  &__details {
		flex: 1 0 50%;
		width: 100%;
		@include flex(
		  $direction: column,
		  $justifyContent: center,
		  $alignItems: center,
		  $gap: pxToRemVal(32px)
		);
		flex-shrink: 0;
		padding-bottom: pxToRemVal(40px);
		border-radius: pxToRemVal(24px);
		background: $hero-section-gradient;
		overflow: hidden;
  
		.title {
		  @include webTextVariant(web-heading5);
		  color: $neutral-800;
		  margin-bottom: 0;
		}
  
		.list {
		  list-style: disc;
		}
  
		.list-item {
		  @include webTextVariant(web-body-small);
		  color: $neutral-800;
		  padding-bottom: pxToRemVal(12px);
		}
  
		@media screen and (max-width: pxToRemVal(1060px)) {
		  display: none;
		}
	  }
  
	  &__logos-wrapper {
		overflow: hidden;
		padding-bottom: 0;
		padding-top: 0;
		margin-bottom: 2rem;
		width: 100%;
	  }
  
	  &__logo-slider {
		@include flex(
			$justifyContent: flex-start,
			$alignItems: center,
			$gap: pxToRemVal(24px)
		  );
		  overflow: visible;
		  position: relative;
		  animation: slide 40s linear infinite;
		  white-space: nowrap;
		  width: calc(200% + pxToRemVal(48px)); 
  
		&:before,
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			z-index: 1;
		} 
	  }
  
	  &__logo-slide {
		column-gap: pxToRemVal(60px);
		row-gap: pxToRemVal(60px);
		flex: none;
		justify-content: center;
		align-items: center;
		margin-top: auto;
		margin-bottom: auto;
		display: flex;
		min-width: pxToRemVal(1650px); 
	  }
	}
  }
  
  .sign-up__form-label {
	padding-bottom: pxToRemVal(4px);
	@include labelText();
  }
  
  @keyframes slide {
	0% {
	  transform: translateX(0);
	}
  
	100% {
	  transform: translateX(-100%);
	}
  }


  @media screen and
  (prefers-reduced-motion: reduce), 
  (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important; /* Hat tip Nick/cssremedy (https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/#comment-1700170) */
    transition-duration: 0.001ms !important;
  }
}