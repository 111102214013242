@import 'fonts';
@import 'variables';
@import 'mixins';

@import 'code';
@import 'headings';
@import 'links';
@import 'lists';

@import 'paragraphs';
@import 'selections';
@import 'special-styles';

body {
	color: $colors-iiii;
	font-family: 'Source Sans Pro', 'Arial', sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-webkit-text-stroke: 0.4px;
	// font-feature-settings: 'dlig' 0, 'clig' 0, 'liga' 0, 'lnum', 'kern';

	/* don't display digraphs in languages that don't support it */
	font-language-override: normal;

	/* use font-defined kerning info */
	font-kerning: auto;

	/* opentype options: kerning, ligatures, horiz ligatures,
	* discretionary ligatures, contextual swash */
	// don’t enable this globally since we don’t want ligatures in input fields.
	// carefully apply ligatures to headings and paragraphs
	font-feature-settings: 'kern', 'liga' 0, 'dlig' 0, 'hlig' 0, 'cswh' 0;

	/* allow browser to auto-infer missing glyphs */
	font-synthesis: weight style;
}

.is-mac body {
	color: $colors-black;
	font-weight: 300;
	-webkit-text-stroke: initial;
}

::selection {
	background: $selection-color;
	text-shadow: none;
}

::-moz-selection {
	background: $selection-color;
	text-shadow: none;
}

a {
	text-decoration: none;

	&:focus {
		outline: none;
	}
}

em,
i {
	font-style: italic;
}

u {
  text-decoration: underline;
}

strong,
b {
	font-weight: 600;
}

.semibold {
	font-weight: 400;
}

sup, sub {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	top: -0.33em;
}

sub {
	top: 0.33em;
}
