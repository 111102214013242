@import "../../mixins/index-mixins.scss";
@import "../../tokens/colors.scss";
@import "../../functions/rem-conversion.scss";
@import "../../common/button-component.scss";

// Homepage tiles

.company-tile {
  display: block;
  margin-right: pxToRemVal(24px);
  position: relative;
  width: pxToRemVal(370px);
  transition: transform 300ms ease-out;

  .wrap {
    height: pxToRemVal(496px);
  }

  &__disclaimer {
    @include webTextVariant(web-body-tiny);
    font-size: pxToRemVal(12px);
    color: $neutral-300;
    text-align: center;
    margin-top: auto;
  }

  &__content {
    background-color: #ffffff;
    border-radius: pxToRemVal(16px);
    padding: pxToRemVal(16px);
    @include flex($direction: column, $justifyContent: space-between);
    flex: 1 0 0;
    height: inherit;
    box-shadow: 0 pxToRemVal(4px) pxToRemVal(10px) 0 rgba(0, 0, 0, 0.06);
  }

  &__header {
    @include flex($direction: column);
    flex: 1 0 0;
    line-height: 2 * $rhythm;
    padding-bottom: pxToRemVal(16px);

    .company-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $soft-black;
      @include webTextVariant(web-heading5);
      padding-bottom: pxToRemVal(8px);
      padding-top: pxToRemVal(20px);
    }
  }

  &__image {
    @include aspect-ratio(16, 9);
    background-position: center;
    min-height: pxToRemVal(200px);
    border-radius: pxToRemVal(12px);
    width: 100%;
    padding: pxToRemVal(12px);
    @include flex(
      $direction: column,
      $justifyContent: space-between,
      $alignItems: flex-start,
      $alignSelf: stretch
    );

    &:before {
      padding-top: 0;
      content: unset;
    }
  }

  .keyword {
    @include flex($alignItems: flex-start);
    border-radius: pxToRemVal(8px);
    background-color: rgba(255, 255, 255, 0.6);
    color: $soft-black;
    @include webTextVariant(web-body-tiny);
    overflow: hidden;
    white-space: nowrap;
    padding: pxToRemVal(2px) pxToRemVal(12px);
  }

  &__image-contained {
    @include aspect-ratio(16, 9);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__description {
    color: $soft-black;
    @include webTextVariant(web-body-tiny);
    .fact {
      color: $soft-black;
      @include webTextVariant(web-body-tiny);
      margin-bottom: 1rem;
    }
  }

  &__price {
    @include flex(
      $alignItems: flex-start,
      $alignSelf: stretch,
      $justifyContent: space-between,
      $gap: pxToRemVal(12px)
    );
    padding-bottom: pxToRemVal(16px);

    .company__label,
    .company__raise {
      border-radius: pxToRemVal(12px);
      background: #f7f7f9;
      @include flex(
        $direction: column,
        $justifyContent: center,
        $alignItems: center,
        $alignSelf: stretch,
        $gap: pxToRemVal(4px)
      );
      padding: pxToRemVal(8px) 0;
      color: #9c9c9c;
      @include webTextVariant(web-body-small);
      flex: 1 0 0;
    }

    .currency {
      color: $soft-black;
      @include webTextVariant(web-small-semibold);
    }
  }

  &:hover {
    transform: translateY(-10px);

    .company-tile__content {
      box-shadow: 0 pxToRemVal(4px) pxToRemVal(16px) 0 rgba(0, 0, 0, 0.1);
    }
  }
}
