// address styling for new form style
// todo: remove .form dependency
.form .country-address-fields {
	@media screen and (min-width: $bp-md) {
		.form__field {
			&.city,
			&.state,
			&.zipCode {
				display: inline-block;
			}
			&.city,
			&.state {
				margin-right: $rhythm;
			}
			&.city {
				width: 33%;
			}
			&.zipCode {
				width: 25%;
			}
			&.state {
				width: calc(42% - (#{2 * $rhythm}));
			}
		}
	}
}