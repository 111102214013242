@import "../base/variables";

.instructions {
	border: 1px solid $colors-yellow-dark;
	font-size: 0.85rem;
	font-weight: 400;
	line-height: 1.15rem;
	padding: 1.5rem 1rem;

	.title {
		.icon {
			display: inline-block;
			margin-right: 8px;
			width: 20px;
			path {
				fill: $colors-yellow-dark;
			}
		}
	}

	.subtitle {
		margin-bottom: 0.25rem;
		text-decoration: underline;
	}

	p {
		margin-left: 0;
		margin-bottom: 1rem;
		max-width: 100%;
	}
}