.modal-trigger {
	cursor: pointer;
}

.modal-wrap {
	// don‘t use display none since we can‘t transition then
	visibility: hidden;

	&.open {
		visibility: visible;
		// display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100000;
	}
}

.modal-mask {
	// reset the color to black since some pages set the body’s base color to white

	color: $colors-black;
	top: 0;
	transform: translateY(100%);

	overflow-y: scroll;
	height: 100%;
	position: fixed;
	left: 0;
	width: 100%;
	z-index: 1000;
	background: $colors-white;
}

.open .modal-mask {
	position: fixed;
	transform: translateY(0%);
	width: 100%;
	min-height: 100%;
	transition: transform 250ms cubic-bezier(0, 0, 0, 1); //, opacity 0.25s cubic-bezier(0, 0, 0, 1);
}

.modal-content {
	@include centered-container;
	// hide modals rendered directly on the page
	display: none;

	position: relative;
	padding-top: 6rem;
	background: $colors-white;
	padding-bottom: 4 * $rhythm;

	&.visible {
		// todo: .visible is excessive
		display: block;
	}
}

// todo: scope
.open .close {
	transform: translateY(0);
	transition: transform 250ms cubic-bezier(0, 0, 0, 1);
}

.close {
	transform: translateY(100vh);
	position: fixed;
	left: 0%;
	cursor: pointer;
	z-index: 1001;
	width: 2rem;
	height: 2rem;
	line-height: 1;
	margin-left: 40px;

	
	// todo: this position causes issues with unintentional double clicks on sign up
	// moving it down causes issues with long headings in normal modals
	@each $breakpoint, $height in $header-heights {
		@include breakpoint($breakpoint) {
			top: ($height - 2rem) * 0.5;
		}
	}

	svg {
		width: 2rem;
		height: 2rem;
		stroke: $colors-black;
	}

	&:hover svg {
		stroke: $colors-mink;
	}
}


.can-flexbox { // .modal-cover

	.modal-content {
		flex: 1;
	}

	.modal-mask {
		display: flex;
		flex-direction: column;
	}
}

.modal-open {
	body {
		overflow: hidden !important; // required to disable scrolling underneath modals
	}

}
