@import "../functions/rem-conversion";
//  GRIDS
// Web
$web-width: pxToRemVal(1440px);
$web-columns: 12;
$web-column-width: auto;
$web-gutter: pxToRemVal(24px);
$web-margins: pxToRemVal(24px);

// Tablet
$tablet-max-width: pxToRemVal(768px);
$tablet-columns: 8;
$tablet-column-width: auto;
$tablet-gutter: pxToRemVal(24px);
$tablet-margins: pxToRemVal(24px);

// Mobile
$mobile-max-width: pxToRemVal(430px);
$mobile-columns: 4;
$mobile-column-width: auto;
$mobile-gutter: pxToRemVal(16px);
$mobile-margins: pxToRemVal(16px);


@mixin flex(
  $direction: row,
  $alignItems: null,
  $alignSelf: null,
  $alignContent: null,
  $justifyContent: null,
  $flexWrap: null,
  $gap: null,
  $flexFlow: null,
  $flex: null
) {
  display: flex;
  flex-direction: $direction;

  @if $alignItems != null {
    align-items: $alignItems;
  }

  @if $alignSelf != null {
    align-self: $alignSelf;
  }

  @if $alignContent != null {
    align-content: $alignContent;
  }

  @if $justifyContent != null {
    justify-content: $justifyContent;
  }

  @if $flexWrap != null {
    flex-wrap: $flexWrap;
  }

  @if $gap != null {
    gap: $gap;
  }

  @if $flexFlow != null {
    flex-flow: $flexFlow;
  }

  @if $flex != null {
    flex: $flex;
  }
}
