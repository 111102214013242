.image-upload {
	overflow: visible !important; // todo: refactor application's base form styles
	input[type="file"] {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
	// todo: globalize
	&[data-aspect-ratio="1:1"] .image-upload__preview {
		@include aspect-ratio(1, 1);
	}
	&[data-aspect-ratio="16:9"] .image-upload__preview {
		@include aspect-ratio(16, 9);
	}
	&[data-aspect-ratio] .image-upload__preview {
		> * {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__select {
		cursor: pointer;
		display: block;
		.form__button {
			display: inline-block;
			margin-bottom: 6px;
			width: 190px;
		}
		// file drag 'n drop state
		&.grabbing {
			background-color: $colors-yellow;
			.image-upload__preview {
				background-image: none;
				.image-preview .empty {
					color: #fff;
					svg path {
						fill: #fff;
					}
				}
			}
		}
	}

	&__preview {
		// border: 2px dashed $colors-grey-light;
		padding: 4px;
		// checkerboard bg
		background-image: linear-gradient(45deg, $colors-grey-lighter 25%, rgba(255,255,255,0) 25%),
		linear-gradient(135deg, $colors-grey-lighter 25%, rgba(255,255,255,0) 25%),
		linear-gradient(45deg, rgba(255,255,255,0) 75%, $colors-grey-lighter 75%),
		linear-gradient(135deg, rgba(255,255,255,0) 75%, $colors-grey-lighter 75%);
		background-size: 20px 20px;
		background-position: 0 0, 10px 0, 10px -10px, 0px 10px;

		.image-preview {
			display: flex;
			min-height: 80px;
			position: relative;
			width: 100%;
			height: 100%;
			.remove {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				svg {
					display: none;
					width: 50px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					path {
						fill: #fff;
					}
				}
				&:hover {
					background-color: rgba($colors-error, 0.85);
					svg {
						display: block;
					}
				}
			}
			.empty {
				color: $colors-grey-med-dark;
				font-size: 0.85rem;
				line-height: 1.15rem;
				margin: auto;
				padding: $rhythm;
				text-align: center;
				.icon {
					margin-bottom: 4px;
				}
				svg {
					height: 80px;
					path {
						fill: $colors-grey;
					}
				}
				a {
					text-shadow: none;
				}
			}
			&:hover {
				.empty {
					color: $colors-purple;
					svg path {
						fill: $colors-purple;
					}
				}
			}
		}
		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			margin: auto;
		}
	}

	.actions {
		line-height: 0;
		margin-top: 1rem;
		.icon {
			cursor: pointer;
			display: inline-block;
			width: 30px;
			svg path {
				fill: $colors-grey;
			}
		}
		.remove {
			float: right;
			&:hover {
				svg path {
					fill: $colors-error;
				}
			}
		}
		.edit:hover {
			svg path {
				fill: $colors-purple;
			}
		}
	}

}

// Form with show/hide Save button
.image-upload-form {
	margin-bottom: $rhythm;
	.image-upload {
		margin-bottom: 8px;
	}
}
