.payment-view {

	@include block-wrap($width: 8);

	.field.bank-name {
		@include block($width: (0: 8));//, $push: (0: 0, 10: 1));
	}

	.field.bank-routing,
	.field.bank-account {
		@include block($width: (0: 8, 8: 4));//, $push: (0: 0, 10: 1));
	}

}