.image-grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .item {
    display: block;
    padding: ($rhythm * 0.5);
  }

  img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &.right {
    flex-direction: row-reverse;
  }
  &.two {
    .item {
      flex-basis: 50%;
    }
  }
  &.three {
    .item {
      flex-basis: 33.3333%;
    }
  }
  &.four {
    .item {
      flex-basis: 25%;
    }
  }
  &.align-top {
    align-items: flex-start;
  }

  @include breakpoint(8, $max: true) {
    display: block;
  }
}