@import '../base/variables';
@import '../mixins/columns';

// todo: move out of this file
.api-docs {
	p {
		@include block(12);
		max-width: 100%;
	}
}

code {
	font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
}

p code {
	padding: 0.2em 0.4em;
	background-color: rgba(27,31,35,0.05);
	font-size: 85%;
}

pre {
	@include block;
	clear: both;
	display: block;
	white-space: pre-wrap;
	overflow-x: auto;
	tab-width: 4;
	background-color: #f6f8fa;
	padding: 16px;
	line-height: 1.45;
	margin-bottom: $rhythm;

	a {
		color: #3461A8;
	}
}
