body.is-anonymous {
	.is-recognized,
	.is-not-anonymous,
	.is-authenticated {
		display: none;
	}
}

body.is-recognized {
	.is-anonymous,
	.is-authenticated {
		display: none;
	}
}

body.is-authenticated {
	.is-anonymous,
	.is-recognized {
		display: none;
	}
}
