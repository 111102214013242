@import '../typography/mixins';

// Notice
.jBox-Notice.jBox-Notice-red .jBox-container {
	background: $colors-red;
}

.jBox-Notice.jBox-Notice-green .jBox-container {
	background: $colors-dddd;
}

// --------------------------------------------
// Tooltip
// --------------------------------------------
.jBox-Tooltip {
	.error-message,
	.success-message {
		// overrides for <p> styles
		// todo: have `view.js` inject <div> elements and/or remove greedy <p> styles
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 0;
		max-width: 100%;
		padding: 0;
		font-weight: 400;
		a {
			@include remove-underline;
			text-decoration: underline;
			font-weight: 600;
		}
	}

	&:hover {
		// hack to allow staying open on hover
		opacity: 1 !important;
		display: block !important;
	}
}
.jBox-TooltipBorder {

	.jBox-container,
	.jBox-pointer:after {
		background: $colors-yellow-light;
		border: 0;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
		font-size: 0.85rem;
		line-height: 1.15rem;
	}

	.jBox-container .jBox-content {
		padding: 8px 12px 6px;
	}

	.jBox-content {
		p {
			margin: 0 0 0.5rem;
			&:last-of-type {
				margin-bottom: 0;
			}
			a {
				@include remove-underline;
				text-decoration: underline;
				color: $colors-purple;
				font-weight: 400;
			}
		}
		ul {
			list-style: disc;
			margin: 0 0 1rem 1rem;
			li {
				margin-bottom: 0.5rem;
			}
			ul {
				list-style: circle;
				margin-bottom: 0;
			}
		}
	}
}

.details {
	font-weight: 500;
}

.tooltip,
.tooltip-jbox {
	cursor: pointer;
}

.tooltip-content {
	display: none;
}

// --------------------------------------------
// Modal
// --------------------------------------------
.jBox-Modal {
	.jBox-content {
		max-height: 92vh;
	}
}

// custom close button svg added in source/js/lib/jbox.all.js
.jBox-overlay .jBox-closeButton {
	width: 60px;
	height: 60px;
}

.jBox-overlay .jBox-closeButton svg {
	width: 30px;
	height: 30px;
	margin-top: -15px;
	margin-right: -15px;
}

// --------------------------------------------
// Confirm
// --------------------------------------------
.jBox-Confirm {
	.jBox-content {
		padding: 1rem 1.5rem;

		&.markup {
			text-align: left;
		}

		.title {
			font-weight: 400;
			margin-right: $rhythm;
			margin-bottom: $rhythm;
			font-size: 1.15rem;
			@media (min-width: $bp-md) {
				font-size: 1.35rem;
			}
		}

		.note {
			font-size: 0.85rem;
			line-height: 1.275rem;
		}
	}

	.jBox-Confirm-footer {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		height: auto;
		padding: 0 1.5rem 1rem;

		.jBox-Confirm-button {
			border-radius: 0.125rem;
			font-size: 1.25rem;
			font-weight: 400;
			height: 2 * $rhythm;
			line-height: 2 * $rhythm;
			margin-right: 8px;
			min-width: 90px;
			padding-left: 1.25em;
			padding-right: 1.25em;
			width: auto;
		}
		.jBox-Confirm-button-submit {
			background: $colors-purple-dark;
			&:hover {
				background: darken($colors-purple-dark, 3%);
			}
		}
	}
}

.jBox-Confirm.btns-centered .jBox-container .jBox-Confirm-footer {
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	height: auto;
	padding: 0 1.5rem 1rem;
}

// fix overflow in jBox modal for fieldset and address fields
// jBox modal maxWidth: 904px
// .jBox-content fieldset {
// 	width: calc(100vw - 50px);

// 	@media (min-width: 904px) {
// 		width: calc(904px - 50px);
// 	}
// }
// .jBox-content .address .field {
// 	width: calc(100vw - 50px) !important;
// 	margin-left: 0 !important;
// 	margin-right: 0 !important;

// 	@media (min-width: 904px) {
// 		width: calc(904px - 50px) !important;
// 	}
// }

// --------------------------------------------
// Confirm - cancel wallet payment
// --------------------------------------------
.pmnt-cancellation.jBox-Confirm {
	&::before {
		display: none;
	}
	.jBox-container {
		padding-bottom: 20px;
	}
	.jBox-content {
		padding: 46px 35px;
		text-align: left;
	}
	@media (max-width: 500px) {
		.jBox-content {
			padding: 46px 20px;
		}
	}
	.jBox-Confirm-footer {
		padding: 0 35px;
	}
	@media (max-width: 500px) {
		.jBox-Confirm-footer {
			padding: 0 20px;
		}
	}
	.jBox-closeButton {
		top: 0;
		right: 0;

		path {
			stroke: $colors-grey-dark;
		}
	}
	.jBox-Confirm-button-cancel {
		display: none;
	}
	.amount {
		font-weight: 600;
		color: $colors-purple;
	}
}