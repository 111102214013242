.toggle-switch {
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;

    & + label {
      background: $colors-rrrr;
      border-radius: 50px;
      cursor: pointer;
      display: block;
      margin: 0 auto;
      text-indent: -9999px;
      position: relative;
      width: 50px;
      height: 26px;
      &:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 50%;
        transition: 0.3s;
      }
      &:active:after {
        width: 20px;
      }
    }

    &:checked + label {
      background: $colors-tttt;
      &:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
      }
    }

    &:disabled {
      & + label {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }
}