.p-events {
	.section__content--large {
		margin-bottom: 2 * $rhythm;
		text-align: center;
	}
	.events-container {
		margin-bottom: 2 * $rhythm;
	}

	.upcoming-events {
		background-color: $colors-offwhite;
		&:before,
		&:after {
			border-bottom-color: $colors-offwhite;
		}
		.section__content--large {
			margin: 0 auto (3 * $rhythm);
			max-width: 700px;
		}

		@media screen and (min-width: $bp-lg) {
			.events-container .data {
				display: table !important;
				border-collapse: separate;
				border-spacing: 0 $rhythm;
				width: 100%;
				.event--upcoming {
					display: table-row;
					.event__header,
					.event__content,
					.event__images {
						display: table-cell;
						border: 1px solid $colors-purple;
						border-left-width: 0;
						border-right-width: 0;
					}
					.event__header {
						border-left-width: 1px;
						max-width: 300px;
					}
					.event__images {
						vertical-align: middle;
						border-right-width: 1px;
						max-width: 300px;
					}
				}
			}
		}
	}
	.past-events {
		background-color: #fff;
		&:before,
		&:after {
			border-bottom-color: #fff;
		}
		.event {
			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
