$about-text-max-width: 800px;

.about-page {

	.responsive-row {
		max-width: 1584px;
		margin: 0 auto;
		padding: 0 1.25rem;
	}

	.about-main {
		padding: 0;
	}

	.about-hero {
		background: #fec665 url('#{$CDN_IMAGES}/images/about/about-hero-bg.jpg') no-repeat center bottom;
		background-size: cover;
		padding: 100px 0;
		h1, p {
			max-width: $about-text-max-width;
			margin-left: auto;
			margin-right: auto;
		}
		h1 {
			font-size: 72px;
			line-height: 1.04;
		}
		p {
			font-family: 'Source Serif Pro';
			font-size: 26px;
			line-height: 1.54;
		}
	}

	.about-principles,
	.about-team,
	.about-advisory-board {
		@include clearfix;
		padding: 100px 0;
		text-align: center;
		h2 {
			font-size: 54px;
			line-height: 1.07;
			margin: 0 auto 100px;
			max-width: $about-text-max-width;
			font-weight: 600;
		}
	}

	.about-principles {
		background: #f9f8f4;
		padding-bottom: 0;
		.principle {
			float: left;
			width: 44%;
			margin: 0 3% 100px;
			&:nth-child(2n+1) {
				clear: left;
			}
			&:nth-child(5){
				display: flex;
				width: 94%;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
			h3, p {
				max-width: 450px;
			}
			h3 {
				font-size: 36px;
				font-weight: 600;
				line-height: 1.28;
				margin: 0 auto 10px;
				&:before {
					content: "";
					display: block;
					width: 16px;
					height: 2px;
					background: #d66fb2;
					margin: 0 auto 24px;
				}
			}
			p {
				font-size: 18px;
				line-height: 1.56;
				margin: 0 auto;
			}
		}
	}

	.about-team {
		background: #f9f8f4;
		padding-top: 0;
		.about-team-member {
			float: left;
			margin-left: 1.884%;
			margin-bottom: 60px;
			padding: 20px;
			position: relative;
			text-align: left;
			transition: all .3s ease-in-out;
			width: 23.587%;
			&:nth-child(4n+1) {
				clear: left;
				margin-left: 0;
			}
			.about-team-member-img {
				margin-bottom: 24px;
				position: relative;
				img {
					border: 1px solid #ccc;
					border-radius: 3px;
					display: block;
					height: auto;
					width: 100%;
				}
			}
			.name {
				font-size: 18px;
				font-weight: bold;
				margin: 0 0 2px;
			}
			.title {
				font-size: 18px;
				margin: 0;
				@include breakpoint(8, $max: true) {
					font-size: 0.9em;
				}
			}
			.hover {
				border-radius: 5px;
				background: #272727;
				box-shadow: 0px 5px 13px rgba(0,0,0,0.25);
				opacity: 0;
				padding: 20px;
				position: absolute;
				top: 0;
				left: 0;
				transition: all .3s ease-in-out;
				width: 100%;
				height: 100%;
				.title,
				.name,
				.quote {
					color: #fff;
				}
				.title,
				.quote {
					font-weight: normal;
				}
				.title {
					margin-bottom: 20px;
				}
				.quote {
					font-size: 16px;
					line-height: 1.63;
					margin: 0;
				}
			}
			&:hover {
				.hover {
					opacity: 1;
				}
			}
		}
	}

	.about-advisory-board {
		background: $colors-light-blue;
		.responsive-row {
			position: relative;
			.illustration,
			.illustration-2 {
				content: '';
				display: block;
				position: absolute;
				height: auto;
			}
			.illustration {
				top: -125px;
				left: 0;
				width: 261px;
				height: auto;
			}
			.illustration-2 {
				bottom: -100px;
				right: 0;
				height: auto;
				width: 375px;
			}
			.clb {
				clear: both;
			}
		}
		.about-advisory-board-members {
			margin-bottom: 150px;
		}
		.about-advisory-board-member {
			float: left;
			width: 46%;
			margin: 0 2% 24px;
			&:nth-child(2n+1) {
				clear: left;
			}
			p {
				max-width: 450px;
				margin: 0 auto;
			}
			.name {
				font-family: 'Source Serif Pro';
				font-weight: 600;
				font-size: 24px;
				line-height: 1.56;
			}
			.desc {
				font-size: 18px;
				line-height: 1.56;
			}
		}
	}

	@include make-footer($background: #272727, $foreground: $colors-white);

}

@media #{$media-tablet} {
	.about-page {
		.about-hero {
			h1 {
				font-size: 60px;
			}
			p {
				font-size: 22px;
			}
		}
		.about-principles h2,
		.about-team h2,
		.about-advisory-board h2 {
			font-size: 48px;
			margin-bottom: 80px;
		}
		.about-principles {
			.principle h3 {
				font-size: 24px;
			}
		}
		.about-team {
			.about-team-member {
				margin-bottom: 40px;
				padding: 10px;
				.hover {
					padding: 15px;
					.name {
						font-size: 16px;
					}
					.name {
						margin: 0;
					}
					.title {
						font-size: 13px;
						margin-bottom: 15px;
					}
					.quote {
						font-size: 13px;
					}
				}
			}
		}
		.about-advisory-board {
			.responsive-row {
				.illustration {
					top: -118px;
					width: 190px;
				}
				.illustration-2 {
					width: 273px;
				}
			}
		}
	}
}
@media #{$media-mobile} {
	.about-page {
		.about-hero {
			padding-top: 60px;
			padding-bottom: 60px;
			h1 {
				font-size: 30px;
			}
			p {
				font-size: 16px;
			}
			.signature {
				width: 230px;
			}
		}
		.about-principles h2,
		.about-team h2,
		.about-advisory-board h2 {
			font-size: 24px;
			margin-bottom: 60px;
		}
		.about-principles {
			padding-top: 60px;
			padding-bottom: 0;
			.principle {
				margin: 0 0 60px;
				width: 100%;
			}
			.principle h3 {
				font-size: 19px;
			}
		}
		.about-team {
			padding-bottom: 36px;
			.responsive-row {
				padding: 0 10px;
			}
			.about-team-member {
				margin-left: 0;
				margin-bottom: 14px;
				padding: 10px;
				width: 50%;
			}
		}
		.about-advisory-board {
			padding: 60px 0;
			.responsive-row {
				.illustration,
				.illustration-2 {
					display: none;
				}
			}
			.about-advisory-board-members {
				margin-bottom: 0;
			}
			.about-advisory-board-member {
				width: 100%;
				margin: 0 0 24px;
				.name {
					font-size: 19px;
				}
				.desc {
					font-size: 16px;
				}
			}
		}
	}
}
