footer {

	padding-top: 2 * $rhythm;
	padding-bottom: $rhythm;

	position: relative;
	z-index: 3; // above <main>

	nav,
	.contact {
		@include block(4);
	}

	nav,
	.contact,
	.share {
		margin-bottom: $rhythm;
	}

	.disclaimers {
		@include block-wrap(8);
	}

	nav {
		clear: both;

		a {
			display: block;
			margin-bottom: 0.5 * $rhythm;
		}
	}

	.phone {
		display: block
	}

	.share {
		@include block(4, $display: flex);
		@include clearfix;
		align-items: center;

		a {
			display: block;
			margin-right: 1rem;
			height: 1.75rem;

			svg {
				height: 1.75rem;
			}

			&.instagram {
				height: 1.25rem;
				margin-right: 1.5rem;
				svg {
					height: 1.25rem;
				}
			}
		}
	}

}