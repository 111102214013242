@mixin remove-underline {
	background-size: 0;
	text-shadow: none;
}

@mixin links($background: $background-color, $link-color: $link-color, $link-color-hover: $link-color-hover) {
	@include links-colors-inner($background-color: $background-color, $link-color: $link-color, $link-color-hover: $link-color-hover);

	// background-image: linear-gradient($link-color, $link-color);
	// todo: ems
	background-position: 0 1.1em;
	background-repeat: repeat-x;
	background-size: 1px 1px;
	cursor: pointer;
	// text-shadow: -2px 0 0 $background, -1px 0 0 $background, 1px 0 0 $background, 2px 0 0 $background;
	// &:active,
	// &:focus,
	// &:hover {
	// 	background-image: linear-gradient($link-color-hover, $link-color-hover);
	// }
	// background-image: linear-gradient($link-color, $link-color);
	// todo: ems
	// text-shadow: -2px 0 0 $background-color, -1px 0 0 $background-color, 1px 0 0 $background-color, 2px 0 0 $background-color;

	// *,
	// *::after,
	&::after,
	&::before {
		text-shadow: none;
	}

}

@mixin links-colors-inner($background-color: $background-color, $link-color: $link-color, $link-color-hover: $link-color-hover) {
	background-image: linear-gradient($link-color, $link-color);
	// todo: ems
	text-shadow: -2px 0 0 $background-color, -1px 0 0 $background-color, 1px 0 0 $background-color, 2px 0 0 $background-color;
	&:active,
	&:focus,
	&:hover {
		background-image: linear-gradient($link-color-hover, $link-color-hover);
	}
}

@mixin links-colors($background-color: $background-color, $link-color: $link-color, $link-color-hover: $link-color-hover) {
	p, h1, h2, h3, h4, h5, h6, li, legend, .legend, label, .decision {
		a {
			@include links-colors-inner($background-color: $background-color, $link-color: $link-color, $link-color-hover: $link-color-hover);
		}
	}
}

// todo: new styles
@mixin dotted-underline($color: #ff0000, $bgcolor: $colors-white) {
	background-repeat: repeat-x;
	background-size: 5px 3px;
	background-image: radial-gradient(circle, $color 1px, transparent 1.5px);
	background-position: 0 1.125rem;
	text-shadow: -2px 0 0 $bgcolor, -1px 0 0 $bgcolor, 1px 0 0 $bgcolor, 2px 0 0 $bgcolor;
}

@mixin retina($ratio: 1.5) {
	$dpi: $ratio * 96;
	$opera-ratio: $ratio * 100;

	@media only screen and (-webkit-min-device-pixel-ratio: #{$ratio}),
		only screen and ( -o-min-device-pixel-ratio: '#{$opera-ratio}/100'),
		only screen and ( min-resolution: #{$dpi}dpi),
		only screen and ( min-resolution: #{$ratio}dppx) {
			@content;
	}
}

// "(+) New" stylized elements
@mixin links-create-new($thisColor: $colors-purple, $hoverColor: #fff) {
	color: $thisColor;
	font-weight: 400;
	position: relative;
	padding-left: 26px;
	&:before {
		content: '';
		display: block;
		border-radius: 50%;
    border: 1px solid;
		width: 18px;
    height: 18px;
		transform: translateY(-50%);
    position: absolute;
    left: 0;
    top: 50%;
	}
	&:after {
		content: '+';
		display: block;
		font-size: 1.25em;
		font-weight: 600;
		line-height: 0;
		position: absolute;
		top: 50%;
    left: 5px;
    width: 20px;
    height: 20px;
	}
	&:hover {
		&:before {
			background-color: $thisColor;
			border-color: $thisColor;
		}
		&:after {
			color: $hoverColor;
		}
	}
}

// @mixin font-sizes($sizes) {
// 	@if not map-has-key($sizes, 0) {
// 		@error 'You must provide a base font size.';
// 	}
// 	font-size: map-get($sizes, 0);
// 	@each $breakpoint, $size in map-remove($sizes, 0) {
// 		@include breakpoint($breakpoint) {
// 			font-size: $size;
// 		}
// 	}
// }