// Selectors
// explicitly list these. using :not() has inheritance issues since pseudo-classes have the highest priority
$selectors-text-inputs: ('input[type="text"]','input[type="email"]','input[type="password"]','input[type="tel"]','input[type="number"]');
$selectors-single-line: append($selectors-text-inputs, 'select');
$selectors-input-fields: append($selectors-single-line, 'textarea');
$selectors-block-elements: 'address, blockquote, caption, div, dl, figcaption, figure, form, img, label, legend, p, ol, table, ul, .field, fieldset';

// approved colors
$colors-black: #000000;
$colors-mink: #ff4564;
$colors-purple: #6C4EA5;
$colors-purple-dark: #2a095a;
$colors-purple-light: #9e88c7;
$colors-purple-lightest: #ede9f5;
$colors-orange: #ff8457;
$colors-orange-dark: #f14000;
$colors-red: #f74453;
$colors-red-dark: #f80029;
$colors-sky-blue: #5ec8ef;
$colors-light-blue: #c3efff;
$colors-white: #ffffff;
$colors-offwhite: #f9f8f4;
$colors-offwhite-light: #fdfdfd;
$colors-yellow: #ffc666;
$colors-yellow-light: lighten($colors-yellow, 23%);
$colors-yellow-dark: darken($colors-yellow, 20%);
// todo: better naming convention
$colors-grey-light: #d4d4d4;
$colors-grey-lighter: #ededed;
$colors-grey: #9d9d9d;
$colors-grey-1: #979caf;
$colors-grey-dark: #272727;
$colors-grey-med-dark: #6e6e6e;
$colors-grey-med-darker: #3a3a3a;

$colors-white-transparent: rgba(255, 255, 255, 0.75);
$colors-transparent: rgba(0,0,0,0);

$colors-currency: #008080;

// brands:
$colors-facebook: #3b5998;
$colors-linkedin: #0077b5;
$colors-twitter: #049ff6;
$colors-instagram: #cc2366;
$colors-youtube: #ff0000;

// todo:
// @joseph: do a workspace search for these colors to see where they're used:
// fix colors, rename

$primary-color: #5486ff; // todo: copied from old styles
$colors-aaaa: #24094b;
$colors-bbbb: #2a0a58;
$colors-dddd: #00aa00;
$colors-eeee: #ddffdd;
$colors-gggg: #54e897;
$colors-hhhh: #003c8e;
$colors-iiii: #333333;
$colors-jjjj: #420f8c;
$colors-kkkk: #eb5757;
$colors-mmmm: #fbf8ff;
$colors-nnnn: #aaaaaa;
$colors-oooo: #0065ee;
$colors-pppp: #b7bacd;
$colors-qqqq: #84899d;
$colors-rrrr: #666666;
$colors-ssss: #c9c1cc;
$colors-tttt: #1acc00;

$colors-forms-labels: darken($colors-qqqq, 15%);

$colors-success: $colors-dddd;
$colors-warning: $colors-yellow-dark;
$colors-error: $colors-red-dark;

$rhythm: 1.5rem;
$sidenav-width: $rhythm * 4;

$header-heights: (
	0: 3 * $rhythm,
	8: 4 * $rhythm,
	12: 5 * $rhythm
);

$media-mobile: "only screen and (max-width: 767px)";
$media-tablet: "only screen and (max-width: 1023px)";

$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1428px;

$transition-speed-base: 0.2s;
$transition-speed-fast: 0.07s;
$transition-speed-slow: 0.5s;
$transition-speed-slower: 1s;

$font-serif-default: 'Source Serif Pro', 'Times NewRomen', serif;
$font-sans-default: 'Source Sans Pro', 'Arial', sans-serif;

// used on the new homepage/signup
$font-serif: 'Pridi', serif;
$font-sans: 'Jost', sans-serif;

$disabled-gradient: repeating-linear-gradient(45deg,
	rgba($colors-grey-light, 0.45),
	rgba($colors-grey-light, 0.45) 10px,
	rgba($colors-grey-light, 0.25) 10px,
	rgba($colors-grey-light, 0.25) 20px);

// offering page cms
$purple: #674FA0;
$grey-light: #C4C4C4;
$grey-dark: #4D4D4D;
$grey-dark-2: #333333;
$green: #5A823A;
$blue-sky: #18A0FB;
$offwhite: #FAF8F4;
