// todo: remove <footer> tag reliance
@import "../../mixins/index-mixins.scss";
@import "../../tokens/colors.scss";
@import "../../functions/rem-conversion.scss";
@import "../../common/button-component.scss";
@import "../../common/input-component.scss";

// ----------- FOOTER -------------

.section {
  &--footer {
    background: $neutral-1000;
    padding: pxToRemVal(100px) pxToRemVal(24px);
    border-radius: pxToRemVal(32px) pxToRemVal(32px) 0 0;
    color: #fff;

    .footer {
      &__content {
        max-width: pxToRemVal(920px);
        width: 100%;
        margin: 0 auto;
        @include flex(
          $direction: column,
          $justifyContent: center,
          $alignItems: center,
          $gap: pxToRemVal(40px)
        );
      }

      &__newsletter {
        @include flex(
          $justifyContent: space-between,
          $alignContent: center,
          $gap: pxToRemVal(120px)
        );
        padding-bottom: pxToRemVal(52px);
        @include border($color: $neutral-800, $side: bottom);
        width: 100%;

        .newsletter {
          &__content {
            flex: 1 0 0;
          }

          &__title {
            color: #fff;
            @include webTextVariant(web-heading5);
            margin-bottom: 0;
          }

          &__subtitle {
            color: #fff;
            @include webTextVariant(web-body-small);
            margin-bottom: 0;
          }

          &__form {
            flex: 1 0 0;
            align-content: flex-end;

            .mail-chimp-newsletter-form {
              max-width: pxToRemVal(520px);
              width: 100%;

              .form__input {
                border-color: $colors-purple-dark;
              }

              .form__field {
                margin-bottom: 0;
              }
            }

            #mc-embedded-subscribe-form {
              .mc-field-group {
                gap: pxToRemVal(12px);
              }

              #mce-EMAIL {
                @include inputComponent();
              }

              #mc-embedded-subscribe {
                @include buttonComponent("regular", "primary");
              }
            }

            // mailchimp validation styling override
            #mc_embed_signup div.mce_inline_error[for] {
              position: absolute;
              bottom: -3rem;
              background: none;
              color: $colors-error;
            }
          }
        }
      }

      &__navigation {
        @include flex($alignItems: flex-start, $justifyContent: space-between);
        padding-bottom: pxToRemVal(56px);
        @include border($color: $neutral-800, $side: bottom);
        width: 100%;

        .wordmark__img {
          width: pxToRemVal(92px);
          height: pxToRemVal(20px);
        }
      }

      &__nav {
        @include flex(
          $gap: pxToRemVal(8px),
          $alignItems: flex-start,
          $direction: column
        );
        flex-wrap: wrap;
        flex-shrink: 0;
        column-gap: pxToRemVal(12px);
        padding-bottom: 1rem;
        margin: 0;
        margin-left: pxToRemVal(4px);

        .nav__col {
          border-radius: pxToRemVal(8px);
          @include flex($justifyContent: center, $alignItems: center);
          padding: pxToRemVal(4px) pxToRemVal(12px);

          &:hover {
            background-color: $neutral-900;

            .nav__item,
            a {
              color: #fff;
            }
          }
        }

        .nav__item,
        a {
          color: $neutral-300;
          @include webTextVariant(web-small-semibold);
          font-weight: 400;
          text-shadow: unset;
          text-decoration: unset;
          background-image: unset;
        }
      }

      &__nav-title {
        color: #fff;
        @include webTextVariant(web-small-semibold);
        margin-bottom: pxToRemVal(8px);
        padding-left: pxToRemVal(8px);
      }
    }

    .social-links {
      position: relative;
      text-align: center;

      .footer__nav-title {
        text-align: left;
        padding-left: 0;
      }

      .wrap {
        @include flex(
          $justifyContent: flex-start,
          $alignItems: center,
          $gap: pxToRemVal(12px)
        );
        padding: 0 pxToRemVal(8px) pxToRemVal(8px);
      }
      &__item {
        display: inline-block;
        width: pxToRemVal(24px);
        height: pxToRemVal(24px);
        svg path {
          fill: #fff;
        }

        &:hover {
          svg path {
            fill: $primary-200;
          }
        }
      }
    }

    .disclaimers {
      &__section {
        width: 100%;
      }

      &__text {
        color: $neutral-400;
        @include webTextVariant(web-caption);
        width: 100%;
        max-width: 100%;
      }

      &__link {
        color: #fff;
        text-align: center;
        text-shadow: unset;
        text-decoration: unset;
        background-image: unset;
      }
    }

    .policy-links {
      @include flex($gap: pxToRemVal(12px));
      .policy-link {
        color: #fff;
        @include webTextVariant(web-caption);
        text-align: center;
        text-shadow: unset;
        text-decoration: unset;
        background-image: unset;

        &:hover {
          text-decoration: underline;
          text-decoration-color: $primary-200;
          color: $primary-200;
        }
      }
    }
  }
}

// MAX - Media for tablet view
@media screen and (max-width: $tablet-max-width) {
  .section--footer {
    padding: pxToRemVal(48px) pxToRemVal(24px);

    .footer {
      &__newsletter {
        @include flex($direction: column, $gap: pxToRemVal(20px));
        padding-bottom: pxToRemVal(16px);
      }

      &__navigation {
        @include flex($justifyContent: center, $gap: pxToRemVal(40px));
        flex-wrap: wrap;
        padding-bottom: pxToRemVal(16px);
      }
    }
  }
}

// MAX - Media for mobile view
@media screen and (max-width: $mobile-max-width) {
  .section--footer {
    .footer {
      &__navigation {
        @include flex($gap: pxToRemVal(20px), $justifyContent: flex-start);
        .wordmark__img {
          margin-right: auto;
          width: 100%;
        }

        .footer {
          &__nav--container {
            flex: 1 0 100%;
            @include flex($alignItems: flex-start, $direction: column);
          }

          &__nav {
            text-align: left;
          }
        }

        .social-links {
          .wrap {
            @include flex($justifyContent: flex-start);
          }
        }
      }
    }
  }
}
