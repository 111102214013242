// see Companies/Invest
.dropdown {
  background: #fff;
  cursor: pointer;
  position: relative;
  .text {
    display: block;
    line-height: 3rem;
    padding: 0 1rem;
    img {
      display: inline-block;
      margin-left: 1rem;
      height: auto;
      position: absolute;
      top: 24px;
      right: 10px;
      width: 10px;
    }
    .default {
      color: $colors-nnnn;
    }
  }
  .options {
    background: #fff;
    position: absolute;
    display: none;
    top: 3rem;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 3px 5px rgba(0,0,0,0.15);
    z-index: 2;

    &.visible {
      display: block;
    }
    .option {
      list-style: none;
      margin: 0;
    }
    a {
      background: none;
      border-left: 4px solid transparent;
      cursor: pointer;
      text-shadow: none;
      display: block;
      line-height: 45px;
      padding: 0 1rem;
      &:hover {
        background: #ECECEC;
        border-color: $link-color;
      }
    }
    a.selected {
      border-color: $colors-purple;
      color: $colors-purple;
      font-weight: 600;
    }
  }
}