.form__radio,
.radio {
	// todo: v3 styles

	input[type='radio'] {
		@include remove();

		& + label {
			margin-right: 1rem;
			margin-bottom: $input-margin-bottom;

			display: block;
			float: left;
			padding-left: 25px;
			clear: none;
			color: $colors-grey-med-dark;
			font-weight: 400;

			background-repeat: no-repeat;
			background-position: 0 bottom;

			vertical-align: middle;
			cursor: pointer;

			background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="24px" height="21px"><circle fill="none" stroke="#{$colors-grey}" stroke-width="1" cx="8" cy="9.5" r="9"></circle></svg>');

			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			&:hover {
				background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="24px" height="21px"><circle fill="none" stroke="#{$colors-grey}" cx="8" cy="9.5" r="9"></circle><circle fill="#{$colors-grey-light}" stroke="none" cx="8" cy="9.5" r="4.5"></circle></svg>');
				color: $colors-grey-dark;
			}
		}

		&:checked + label {
			background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="24px" height="21px"><circle fill="none" stroke="#{$colors-grey}" cx="8" cy="9.5" r="9"></circle><circle fill="#5486ff" stroke="none" cx="8" cy="9.5" r="4.5"></circle></svg>');
			color: #000;
		}

		&:disabled + label {
			background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="24px" height="21px"><circle fill="none" stroke="#{$colors-grey-light}" cx="8" cy="9.5" r="9"></circle></svg>');
			color: $colors-grey;
			pointer-events: none;
		}
	}

	// &.field .error-message {
	// 	margin-top: -0.25 * $rhythm;
	// }

}

.form__radio-group {
	@include clearfix;
	// todo: better workaraound for radio buttons as a bordered "field"
	&.form__input {
		padding-bottom: 0;
	}
}