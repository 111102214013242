.recent-trades-view {

  .trade {
    &:nth-of-type(4) {
      border-bottom: 0;
    }
    &:nth-of-type(n+5) {
      display: none;
    }
  }

  .delta {
    transform: translateY(-1px);
  }
}