// single field with submit button to the right
.form {
	$self: &;

	&--single-field {
		#{$self}__field {
			padding-top: 0;
			display: flex;
		}
		#{$self}__input {
			border-radius: 2px 0 0 2px;
			border-right: 0;
		}
		#{$self}__button {
			border-radius: 0 2px 2px 0;
			height: auto;
			margin: 0;
		}
	}
}
