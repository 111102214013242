// simple "underlined" field styles
.form {
	$self: &;

	&--minimal {
		#{$self}__field {
			padding-top: 0;
		}
		#{$self}__input {
			border-top: 0;
			border-right: 0;
			border-left: 0;

			&.error {
				background-image: none;
			}
		}
	}
}
