.recent-primary-activity-view {

  .activity-row {
    &:nth-of-type(4) {
      border-bottom: 0;
    }
    &:nth-of-type(n+5) {
      display: none;
    }
  }
}