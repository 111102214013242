.partner-landing-page {
	.intro {
		@include container;
		@include clearfix;
		h1, h3 {
			margin-bottom: 3 * $rhythm;
		}
		h3 {
			@include block(7);
			padding-right: 20px;
		}
	}
	.logos {
		@include block(6);
		margin-bottom: 3 * $rhythm;
		text-align: center;
		img,
		.img {
			max-width: 100%;
		}
		.netcapital-logo {
			// font-family: 'Netcapital';
			font-size: 62px;
			margin: 14px 10px 40px;
		}
	}
	.partner-form-view {
		@include block(6);
		clear: both;
	}
}