@import "header-mixins";
@import "../../mixins/index-mixins.scss";
@import "../../tokens/colors.scss";
@import "../../functions/rem-conversion.scss";
@import "../../common//button-component.scss";

//  Main menu for AUTHENTICATED users
.avatar-menu {
  display: block;

  .avatar-icon {
    cursor: pointer;
    .avatar {
      @include flex($justifyContent: center, $alignItems: center);
      @include webTextVariant("web-body-small");
      border-radius: pxToRemVal(48px);
      width: pxToRemVal(44px);
      height: pxToRemVal(44px);
      background-color: $neutral-100;
      transition: all 0.2s ease-in;

      &:hover {
        background-color: $neutral-200;
      }
    }
  }
}

.avatar-menu__dropdown {
  position: absolute;
  top: pxToRemVal(70px);
  background: #ffffff;
  box-shadow: 0 pxToRemVal(12px) pxToRemVal(32px) 0 rgba(145, 123, 188, 0.26);
  right: 0;
  border-radius: pxToRemVal(20px);
  box-shadow: 0 pxToRemVal(6px) pxToRemVal(12px) 0 rgba(28, 28, 30, 0.12);
  max-width: pxToRemVal(192px);
  width: 100%;
  @include flex($direction: column, $alignItems: flex-start);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  .avatar-menu__link {
    width: 100%;
    padding: pxToRemVal(12px) pxToRemVal(20px);
    color: $soft-black;
    @include webTextVariant("web-body-small");
    @include flex($justifyContent: flex-start);

    &--hidden {
      @media screen and (min-width: $tablet-max-width) {
        display: none;
      }
    }
  }

  > li {
    width: 100%;
    cursor: pointer;
    @include flex(
      $alignItems: flex-start,
      $gap: pxToRemVal(8px),
      $alignSelf: stretch,
      $justifyContent: flex-start
    );

    &:first-of-type {
      border-radius: pxToRemVal(20px) pxToRemVal(20px) 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 pxToRemVal(20px) pxToRemVal(20px);
    }

    &:hover {
      background-color: $neutral-100;
    }
  }

  .border-bottom {
    @include border($color: $neutral-100, $side: bottom);
  }

  .border-top {
    @include border($color: $neutral-100, $side: top);
  }
}
