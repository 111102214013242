.password-strength-view {
	clear: both;
	padding-top: 0.25 * $rhythm;
	padding-bottom: 0.25 * $rhythm;
	.strength-label {
		float: left;
		padding-right: 1em;
	}
	.strength-max {
		float: left;
		height: 0.5 * $rhythm;
		margin-top: 0.5rem;
		width: 100px;
		background-color: #cccccc;
	}
	.strength-bar {
		height: 100%;
		background-color: #FF4564;
		&.medium {
			background-color: #FFC666;
		}
		&.strong {
			background-color: #1ACC00;
		}
	}
	.password-enhancements {
		margin-bottom: $rhythm;
	}
	.enhancements {
		clear: both;
		padding-top: 0.25 * $rhythm;
	}
	.enhancements-item {
		// line-height: 0.75 * $rhythm;
		color: #666666;
		&:before {
			content: '●';
			padding-right: 1em;
		}
		&.active {
			color: #1ACC00;
		}
	}
	#confirm-password {
		// margin-top: 1em;
	}
}
