/* Reset some default styling */
.gjs-cv-canvas {
	top: 0;
	width: 100%;
	height: 100%;
}

.gjs-block {
	width: auto;
	height: auto;
	min-height: auto;
}
