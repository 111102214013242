body.investors-page {

	// todo: abstract this, since it's just about adjusting a few colors based on the background.
	@include header-invert-colors($colors-purple-dark);

	background-color: $background-color-marketing;

	main {
		// todo: remove
		@include links-colors($background-color-marketing);
		// margin-top: 0;
		padding-top: 0;
		// required for section-nav with 'fixed bottom' styles
		position: relative;
	}

	.intro {
		background-color: $colors-purple-dark;
		color: $colors-white;
		font-weight: 400;
		padding-top: 4 * $rhythm;
		padding-bottom: 3 * $rhythm;

		background-image: url('#{$CDN_IMAGES}/images/investors/chart.svg');
		background-position: 50% 100%;
		background-repeat: no-repeat;

		@include breakpoint(10) {
			text-align: center;
		}

		@include breakpoint(13) {
			background-size: contain;
		}

		h1 {
			@include positioned-block($width: 14, $push: (0: 0, 14: 1, 16: 2, 18: 3, 20: 4, 22: 5, 24: 6));
		}

		p {
			@include positioned-block($width: 8, $push: (0: 0, 10: 1, 12: 2, 14: 3, 16: 4, 18: 5, 20: 6, 22: 7, 24: 8));
			@extend %lede;
		}

		@include links-colors($colors-purple-dark, $colors-mink);

	}

	.section-nav {
		padding-top: 3 * $rhythm;
	}

	.body {
		@include container(8, $push:(0:0,10:1,12:4,14:4,16:4,18:5,20:6,22:7,24:8));
		padding-top: 3 * $rhythm;

		p {
			@extend %marquee;
		}
	}

	@include footer-push($columns: (0: 0, 18: 1, 20: 2, 22: 3, 24: 4));
}
