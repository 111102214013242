.jBox-wrapper {
  text-align: left;
  box-sizing: border-box;
}

.jBox-title,
.jBox-content,
.jBox-container {
  position: relative;
  word-break: break-word;
  box-sizing: border-box;
}

.jBox-container {
  background: #fff;
}

.jBox-content {
  padding: 8px 12px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity .2s;
}

.jBox-footer {
  box-sizing: border-box;
}

.jBox-Tooltip .jBox-container,
.jBox-Mouse .jBox-container {
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}

.jBox-Tooltip .jBox-title,
.jBox-Mouse .jBox-title {
  padding: 8px 10px 0;
  font-weight: bold;
}

.jBox-Tooltip.jBox-hasTitle .jBox-content,
.jBox-Mouse.jBox-hasTitle .jBox-content {
  padding-top: 5px;
}

.jBox-Mouse {
  pointer-events: none;
}

.jBox-pointer {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
}

.jBox-pointer:after {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  background: #fff;
  transform: rotate(45deg);
  box-sizing: border-box;
}

.jBox-pointer-top {
  top: 0;
}

.jBox-pointer-top:after {
  left: 5px;
  top: 6px;
  box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.15);
}

.jBox-pointer-right {
  right: 0;
}

.jBox-pointer-right:after {
  top: 5px;
  right: 6px;
  box-shadow: 1px -1px 2px rgba(0, 0, 0, 0.15);
}

.jBox-pointer-left {
  left: 0;
}

.jBox-pointer-left:after {
  top: 5px;
  left: 6px;
  box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.15);
}

.jBox-pointer-bottom {
  bottom: 0;
}

.jBox-pointer-bottom:after {
  left: 5px;
  bottom: 6px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}

.jBox-pointer-top, .jBox-pointer-bottom {
  width: 30px;
  height: 12px;
}

.jBox-pointer-left, .jBox-pointer-right {
  width: 12px;
  height: 30px;
}

.jBox-Modal .jBox-container {
  border-radius: 4px;
}

.jBox-Modal .jBox-container, .jBox-Modal.jBox-closeButton-box:before {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.4), 0 0 5px rgba(0, 0, 0, 0.4);
}

.jBox-Modal .jBox-content {
  padding: 15px 20px;
}

.jBox-Modal .jBox-title {
  border-radius: 4px 4px 0 0;
  padding: 15px 20px;
  background: #fafafa;
  border-bottom: 1px solid #eee;
}

.jBox-Modal.jBox-closeButton-title .jBox-title {
  padding-right: 65px;
}

.jBox-Modal .jBox-footer {
  border-radius: 0 0 4px 4px;
}

.jBox-closeButton {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  box-sizing: border-box;
}

.jBox-closeButton svg {
  position: absolute;
  top: 50%;
  right: 50%;
}

.jBox-closeButton path {
  fill: #aaa;
  transition: fill .2s;
}

.jBox-closeButton:hover path {
  fill: #888;
}

.jBox-overlay .jBox-closeButton {
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
}

.jBox-overlay .jBox-closeButton svg {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-right: -10px;
}

.jBox-overlay .jBox-closeButton path {
  fill: #ddd;
}

.jBox-overlay .jBox-closeButton:hover path {
  fill: #fff;
}

.jBox-closeButton-title .jBox-closeButton {
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
}

.jBox-closeButton-title svg {
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-right: -6px;
}

.jBox-closeButton-box {
  box-sizing: border-box;
}

.jBox-closeButton-box .jBox-closeButton {
  top: -8px;
  right: -10px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50%;
}

.jBox-closeButton-box .jBox-closeButton svg {
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-right: -5px;
}

.jBox-closeButton-box:before {
  content: '';
  position: absolute;
  top: -8px;
  right: -10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.jBox-closeButton-box.jBox-pointerPosition-top:before {
  top: 5px;
}

.jBox-closeButton-box.jBox-pointerPosition-right:before {
  right: 2px;
}

.jBox-Modal.jBox-hasTitle.jBox-closeButton-box .jBox-closeButton {
  background: #fafafa;
}

.jBox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.82);
}

.jBox-footer {
  background: #fafafa;
  border-top: 1px solid #eee;
  padding: 8px 10px;
  border-radius: 0 0 3px 3px;
}

body[class^="jBox-blockScroll-"],
body[class*=" jBox-blockScroll-"] {
  overflow: hidden;
}

.jBox-draggable {
  cursor: move;
}

@keyframes jBoxLoading {
  to {
    transform: rotate(360deg);
  }
}

.jBox-loading .jBox-content {
  opacity: .2;
}

.jBox-loading-spinner .jBox-content {
  min-height: 38px !important;
  min-width: 38px !important;
  opacity: 0;
}

.jBox-spinner {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
}

.jBox-spinner:before {
  display: block;
  box-sizing: border-box;
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-top-color: rgba(0, 0, 0, 0.8);
  animation: jBoxLoading .6s linear infinite;
}

.jBox-countdown {
  border-radius: 4px 4px 0 0;
  z-index: 0;
  background: #000;
  opacity: .2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  overflow: hidden;
}

.jBox-countdown-inner {
  top: 0;
  right: 0;
  width: 100%;
  height: 3px;
  position: absolute;
  background: #fff;
}

[class^="jBox-animated-"],
[class*=" jBox-animated-"] {
  animation-fill-mode: both;
}

@keyframes jBox-tada {
  0% {
    transform: scale(1);
  }
  10%,
  20% {
    transform: scale(0.8) rotate(-4deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.2) rotate(4deg);
  }
  40%,
  60%,
  80% {
    transform: scale(1.2) rotate(-4deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

.jBox-animated-tada {
  animation: jBox-tada 1s;
}

@keyframes jBox-tadaSmall {
  0% {
    transform: scale(1);
  }
  10%,
  20% {
    transform: scale(0.9) rotate(-2deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(2deg);
  }
  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-2deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

.jBox-animated-tadaSmall {
  animation: jBox-tadaSmall 1s;
}

@keyframes jBox-flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.jBox-animated-flash {
  animation: jBox-flash .5s;
}

@keyframes jBox-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  20%,
  60% {
    transform: translateX(-6px);
  }
  40%,
  80% {
    transform: translateX(6px);
  }
}

.jBox-animated-shake {
  animation: jBox-shake .4s;
}

@keyframes jBox-pulseUp {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.jBox-animated-pulseUp {
  animation: jBox-pulseUp .25s;
}

@keyframes jBox-pulseDown {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

.jBox-animated-pulseDown {
  animation: jBox-pulseDown .25s;
}

@keyframes jBox-popIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.jBox-animated-popIn {
  animation: jBox-popIn .25s;
}

@keyframes jBox-popOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}

.jBox-animated-popOut {
  animation: jBox-popOut .25s;
}

@keyframes jBox-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.jBox-animated-fadeIn {
  animation: jBox-fadeIn .2s;
}

@keyframes jBox-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.jBox-animated-fadeOut {
  animation: jBox-fadeOut .2s;
}

@keyframes jBox-slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-300px);
    opacity: 0;
  }
}

.jBox-animated-slideUp {
  animation: jBox-slideUp .4s;
}

@keyframes jBox-slideRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300px);
    opacity: 0;
  }
}

.jBox-animated-slideRight {
  animation: jBox-slideRight .4s;
}

@keyframes jBox-slideDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(300px);
    opacity: 0;
  }
}

.jBox-animated-slideDown {
  animation: jBox-slideDown .4s;
}

@keyframes jBox-slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-300px);
    opacity: 0;
  }
}

.jBox-animated-slideLeft {
  animation: jBox-slideLeft .4s;
}

.jBox-Confirm .jBox-content {
  text-align: center;
  padding: 46px 35px;
}

@media (max-width: 500px) {
  .jBox-Confirm .jBox-content {
    padding: 32px 20px;
  }
}

.jBox-Confirm-footer {
  height: 46px;
}

.jBox-Confirm-button {
  display: block;
  float: left;
  cursor: pointer;
  text-align: center;
  width: 50%;
  line-height: 46px;
  height: 46px;
  overflow: hidden;
  padding: 0 10px;
  transition: color .2s, background-color .2s;
  box-sizing: border-box;
}

.jBox-Confirm-button-cancel {
  border-bottom-left-radius: 4px;
  background: #ddd;
  color: #666;
}

.jBox-Confirm-button-cancel:hover, .jBox-Confirm-button-cancel:active {
  background: #ccc;
}

.jBox-Confirm-button-cancel:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.jBox-Confirm-button-submit {
  border-bottom-right-radius: 4px;
  background: #7d0;
  color: #fff;
}

.jBox-Confirm-button-submit:hover, .jBox-Confirm-button-submit:active {
  background: #6c0;
}

.jBox-Confirm-button-submit:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.jBox-Image .jBox-container {
  background-color: transparent;
}

.jBox-Image .jBox-content {
  padding: 0;
  width: 100%;
  height: 100%;
}

.jBox-image-container {
  background: center center no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.jBox-image-label-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 40px;
  z-index: 100;
  display: flex;
}

.jBox-image-label-container {
  position: relative;
  flex: 1;
}

.jBox-image-label {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 8px 12px;
  font-size: 15px;
  line-height: 24px;
  transition: opacity .36s;
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}

.jBox-image-label.expanded {
  background: #000;
}

.jBox-image-label:not(.expanded) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.jBox-image-label.active {
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 600px) {
  .jBox-image-label {
    font-size: 13px;
  }
}

.jBox-image-pointer-next,
.jBox-image-pointer-prev {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: .8;
  transition: opacity .2s;
  background: no-repeat center center url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ijc0LjcgMjI0IDE4LjcgMzIiPg0KPHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTkzLDIyNy40TDgwLjQsMjQwTDkzLDI1Mi42YzAuNCwwLjQsMC40LDEuMSwwLDEuNWwtMS42LDEuNmMtMC40LDAuNC0xLDAuNS0xLjUsMEw3NSwyNDAuN2MtMC40LTAuNC0wLjUtMSwwLTEuNWwxNC45LTE0LjljMC40LTAuNCwxLTAuNCwxLjUsMGwxLjYsMS42QzkzLjUsMjI2LjQsOTMuNCwyMjcsOTMsMjI3LjR6Ii8+DQo8L3N2Zz4=);
  background-size: 11px auto;
  user-select: none;
  z-index: 1;
}

.jBox-image-pointer-next:hover,
.jBox-image-pointer-prev:hover {
  opacity: 1;
}

.jBox-image-pointer-next {
  transform: scaleX(-1);
}

.jBox-image-counter-container {
  flex-shrink: 0;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: #fff;
  text-align: right;
  display: none;
}

.jBox-image-has-counter .jBox-image-counter-container {
  display: block;
}

.jBox-overlay.jBox-overlay-Image {
  background: #000;
}

.jBox-image-not-found {
  background: #000;
}

.jBox-image-not-found:before {
  content: '';
  box-sizing: border-box;
  display: block;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  position: absolute;
  top: 50%;
  left: 50%;
  border: 5px solid #222;
  border-radius: 50%;
}

.jBox-image-not-found:after {
  content: '';
  display: block;
  box-sizing: content-box;
  z-index: auto;
  width: 6px;
  height: 74px;
  margin-top: -37px;
  margin-left: -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #222;
  transform: rotateZ(45deg);
  transform-origin: 50% 50% 0;
}

.jBox-image-download-button-wrapper {
  position: absolute;
  top: -40px;
  right: 35px;
  height: 40px;
  display: flex;
  cursor: pointer;
  opacity: .8;
  transition: opacity .2s;
}

.jBox-image-download-button-wrapper:hover {
  opacity: 1;
}

.jBox-image-download-button-icon {
  width: 40px;
  height: 40px;
  background: center center no-repeat url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNjQwIj48cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNNDE2IDI1NnYtMTkyaC0xOTJ2MTkyaC0xNjBsMjU2IDI1NiAyNTYtMjU2aC0xNjB6TTAgNTc2aDY0MHY2NGgtNjQwdi02NHoiPjwvcGF0aD48L3N2Zz4=);
  background-size: 60%;
}

.jBox-image-download-button-text {
  white-space: nowrap;
  line-height: 40px;
  padding: 0 10px 0 0;
  color: #fff;
  font-size: 14px;
}

@keyframes jBoxImageLoading {
  to {
    transform: rotate(360deg);
  }
}

.jBox-image-loading:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;
  border: 4px solid #333;
  border-bottom-color: #666;
  animation: jBoxImageLoading 1.2s linear infinite;
  border-radius: 50%;
}

.jBox-Notice {
  transition: margin .2s;
}

.jBox-Notice .jBox-container {
  border-radius: 4px;
  box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.25), inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
}

.jBox-Notice .jBox-content {
  border-radius: 4px;
  padding: 12px 20px;
}

@media (max-width: 768px) {
  .jBox-Notice .jBox-content {
    padding: 10px 15px;
  }
}

@media (max-width: 500px) {
  .jBox-Notice .jBox-content {
    padding: 8px 10px;
  }
}

.jBox-Notice.jBox-hasTitle .jBox-content {
  padding-top: 5px;
}

@media (max-width: 500px) {
  .jBox-Notice.jBox-hasTitle .jBox-content {
    padding-top: 0;
  }
}

.jBox-Notice.jBox-hasTitle .jBox-title {
  padding: 12px 20px 0;
  font-weight: bold;
}

@media (max-width: 768px) {
  .jBox-Notice.jBox-hasTitle .jBox-title {
    padding: 10px 15px 0;
  }
}

@media (max-width: 500px) {
  .jBox-Notice.jBox-hasTitle .jBox-title {
    padding: 8px 10px 0;
  }
}

.jBox-Notice.jBox-closeButton-title .jBox-title {
  padding-right: 55px;
}

.jBox-Notice.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton {
  width: 40px;
}

.jBox-Notice.jBox-Notice-black .jBox-container {
  color: #fff;
  background: #000;
}

.jBox-Notice.jBox-Notice-black.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton path,
.jBox-Notice.jBox-Notice-black.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton:hover path {
  fill: #fff;
}

.jBox-Notice.jBox-Notice-gray .jBox-container {
  color: #222;
  background: #f6f6f6;
}

.jBox-Notice.jBox-Notice-gray.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton path,
.jBox-Notice.jBox-Notice-gray.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton:hover path {
  fill: #222;
}

.jBox-Notice.jBox-Notice-red .jBox-container {
  color: #fff;
  background: #d00;
}

.jBox-Notice.jBox-Notice-red.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton path,
.jBox-Notice.jBox-Notice-red.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton:hover path {
  fill: #fff;
}

.jBox-Notice.jBox-Notice-green .jBox-container {
  color: #fff;
  background: #5d0;
}

.jBox-Notice.jBox-Notice-green.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton path,
.jBox-Notice.jBox-Notice-green.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton:hover path {
  fill: #fff;
}

.jBox-Notice.jBox-Notice-blue .jBox-container {
  color: #fff;
  background: #49d;
}

.jBox-Notice.jBox-Notice-blue.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton path,
.jBox-Notice.jBox-Notice-blue.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton:hover path {
  fill: #fff;
}

.jBox-Notice.jBox-Notice-yellow .jBox-container {
  color: #000;
  background: #fd0;
}

.jBox-Notice.jBox-Notice-yellow.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton path,
.jBox-Notice.jBox-Notice-yellow.jBox-closeButton-title.jBox-hasTitle .jBox-closeButton:hover path {
  fill: #fff;
}

.jBox-NoticeFancy .jBox-content,
.jBox-NoticeFancy .jBox-title {
  padding-left: 25px;
}

.jBox-NoticeFancy.jBox-Notice-color .jBox-container {
  color: #fff;
  background: #000;
}

.jBox-NoticeFancy.jBox-Notice-color .jBox-container:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 8px;
  border-radius: 4px 0 0 4px;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-size: 14px 14px;
}

.jBox-NoticeFancy.jBox-Notice-black .jBox-container:after,
.jBox-NoticeFancy.jBox-Notice-gray .jBox-container:after {
  background-color: #888;
}

.jBox-NoticeFancy.jBox-Notice-red .jBox-container:after {
  background-color: #e00;
}

.jBox-NoticeFancy.jBox-Notice-green .jBox-container:after {
  background-color: #6c0;
}

.jBox-NoticeFancy.jBox-Notice-blue .jBox-container:after {
  background-color: #49d;
}

.jBox-NoticeFancy.jBox-Notice-yellow .jBox-container:after {
  background-color: #fb0;
}

.jBox-NoticeFancy .jBox-countdown {
  left: 8px;
  border-radius: 0 4px 0 0;
}

.jBox-TooltipBorder .jBox-container,
.jBox-TooltipBorder .jBox-pointer:after {
  border: 2px solid #49d;
}

.jBox-TooltipBorder .jBox-pointer:after {
  width: 22px;
  height: 22px;
}

.jBox-TooltipBorder .jBox-pointer-top,
.jBox-TooltipBorder .jBox-pointer-bottom {
  width: 34px;
  height: 13px;
}

.jBox-TooltipBorder .jBox-pointer-top:after,
.jBox-TooltipBorder .jBox-pointer-bottom:after {
  left: 6px;
}

.jBox-TooltipBorder .jBox-pointer-left,
.jBox-TooltipBorder .jBox-pointer-right {
  width: 13px;
  height: 34px;
}

.jBox-TooltipBorder .jBox-pointer-left:after,
.jBox-TooltipBorder .jBox-pointer-right:after {
  top: 6px;
}

.jBox-TooltipBorder.jBox-closeButton-box:before {
  width: 28px;
  height: 28px;
  background: #49d;
}

.jBox-TooltipBorderThick .jBox-container {
  box-shadow: none;
  border-radius: 8px;
  border: 4px solid #ccc;
}

.jBox-TooltipBorderThick .jBox-pointer:after {
  box-shadow: none;
  border: 4px solid #ccc;
  width: 24px;
  height: 24px;
}

.jBox-TooltipBorderThick .jBox-pointer-top,
.jBox-TooltipBorderThick .jBox-pointer-bottom {
  width: 38px;
  height: 13px;
}

.jBox-TooltipBorderThick .jBox-pointer-left,
.jBox-TooltipBorderThick .jBox-pointer-right {
  width: 13px;
  height: 38px;
}

.jBox-TooltipBorderThick.jBox-closeButton-box:before {
  width: 32px;
  height: 32px;
  background: #ccc;
}

.jBox-TooltipDark .jBox-container {
  border-radius: 4px;
  background: #000;
  color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}

.jBox-TooltipDark .jBox-pointer:after {
  background: #000;
}

.jBox-TooltipDark .jBox-closeButton {
  background: #000;
}

.jBox-TooltipDark.jBox-closeButton-box:before {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}

.jBox-TooltipDark.jBox-closeButton-box .jBox-closeButton path {
  fill: #ddd;
}

.jBox-TooltipDark.jBox-closeButton-box .jBox-closeButton:hover path {
  fill: #fff;
}

.jBox-TooltipDark.jBox-closeButton-box .jBox-closeButton:active path {
  fill: #bbb;
}

.jBox-TooltipError {
  pointer-events: none;
}

.jBox-TooltipError .jBox-container {
  border-radius: 2px;
  background: #d00;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
}

.jBox-TooltipError .jBox-content {
  padding: 0 10px;
  line-height: 28px;
}

.jBox-TooltipError .jBox-pointer:after {
  background: #d00;
  width: 20px;
  height: 20px;
}

.jBox-TooltipError .jBox-pointer-top, .jBox-TooltipError .jBox-pointer-bottom {
  width: 22px;
  height: 8px;
}

.jBox-TooltipError .jBox-pointer-right, .jBox-TooltipError .jBox-pointer-left {
  width: 8px;
  height: 22px;
}

.jBox-TooltipError .jBox-pointer-top:after {
  left: 1px;
  top: 6px;
}

.jBox-TooltipError .jBox-pointer-right:after {
  top: 1px;
  right: 6px;
}

.jBox-TooltipError .jBox-pointer-bottom:after {
  left: 1px;
  bottom: 6px;
}

.jBox-TooltipError .jBox-pointer-left:after {
  top: 1px;
  left: 6px;
}

.jBox-TooltipSmall {
  pointer-events: none;
}

.jBox-TooltipSmall .jBox-container {
  border-radius: 2px;
}

.jBox-TooltipSmall .jBox-content {
  padding: 0 10px;
  line-height: 28px;
}

.jBox-TooltipSmall .jBox-pointer:after {
  width: 20px;
  height: 20px;
}

.jBox-TooltipSmall .jBox-pointer-top, .jBox-TooltipSmall .jBox-pointer-bottom {
  width: 22px;
  height: 8px;
}

.jBox-TooltipSmall .jBox-pointer-right, .jBox-TooltipSmall .jBox-pointer-left {
  width: 8px;
  height: 22px;
}

.jBox-TooltipSmall .jBox-pointer-top:after {
  left: 1px;
  top: 6px;
}

.jBox-TooltipSmall .jBox-pointer-right:after {
  top: 1px;
  right: 6px;
}

.jBox-TooltipSmall .jBox-pointer-bottom:after {
  left: 1px;
  bottom: 6px;
}

.jBox-TooltipSmall .jBox-pointer-left:after {
  top: 1px;
  left: 6px;
}

.jBox-TooltipSmallGray {
  pointer-events: none;
}

.jBox-TooltipSmallGray .jBox-container {
  font-size: 13px;
  line-height: 24px;
  border-radius: 12px;
  background-image: linear-gradient(to bottom, #fafafa, #f2f2f2);
}

.jBox-TooltipSmallGray .jBox-content {
  padding: 0 10px;
}

.jBox-TooltipSmallGray .jBox-pointer:after {
  width: 20px;
  height: 20px;
}

.jBox-TooltipSmallGray .jBox-pointer-top, .jBox-TooltipSmallGray .jBox-pointer-bottom {
  width: 22px;
  height: 8px;
}

.jBox-TooltipSmallGray .jBox-pointer-left, .jBox-TooltipSmallGray .jBox-pointer-right {
  width: 8px;
  height: 22px;
}

.jBox-TooltipSmallGray .jBox-pointer-top:after {
  background: #fafafa;
  left: 1px;
  top: 6px;
}

.jBox-TooltipSmallGray .jBox-pointer-right:after {
  top: 1px;
  right: 6px;
}

.jBox-TooltipSmallGray .jBox-pointer-bottom:after {
  background: #f2f2f2;
  left: 1px;
  bottom: 6px;
}

.jBox-TooltipSmallGray .jBox-pointer-left:after {
  top: 1px;
  left: 6px;
}

/*# sourceMappingURL=jBox.all.css.map */
