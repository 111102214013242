@import "../../mixins/index-mixins.scss";
@import "../../tokens/colors.scss";
@import "../../functions/rem-conversion.scss";
@import "../../common/button-component.scss";

.theme--clipped-sections {
  font-family: $font-sans;

  // Slideshows
  .slideshow {
    overflow: hidden;
    max-width: pxToRemVal(1156px);
    width: 100%;
    margin: 0 auto;
    padding: pxToRemVal(20px) 0;
    .buttons {
      @include flex(
        $justifyContent: flex-start,
        $alignItems: center,
        $gap: pxToRemVal(20px)
      );
      padding-bottom: pxToRemVal(20px);
      .next,
      .prev {
        @include border(
          $color: $primary-default-700,
          $radius: pxToRemVal(56px)
        );
        padding: pxToRemVal(8px);
        background-color: #ffffff;
        cursor: pointer;
        display: inline-block;
        @include flex(
          $justifyContent: center,
          $gap: pxToRemVal(8px),
          $alignItems: center
        );

        svg {
          width: 24px;
          height: 24px;
        }
        &:hover {
          background-color: $primary-default-700;
          @include border($color: #ffffff);

          > svg path {
            fill: #ffffff;
          }
        }
      }
    }
    .tiles {
      &__navigation {
        padding-top: pxToRemVal(28px);
        padding-bottom: 0;
      }
    }
    .flickity-viewport {
      overflow: visible;
    }
  }

  // -------------
  // OVERRIDES
  // -------------
  main {
    padding: 0;
  }

  // form overrides
  .form {
    &__input,
    &__select,
    &__select.placeholder,
    &__textarea,
    &__button,
    input[type="submit"] {
      font-family: $font-sans;
    }
  }
}
