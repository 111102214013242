@import "../base/variables";

body.companies-page {
  $background-color: $colors-light-blue;
  $border-color: #afc5ce;
  $header-color: #6c4ea5;

  //   @include header-invert-colors(
  //     $background-color: $colors-light-blue,
  //     $color: #000
  //   );

  main {
    padding-top: 0;
    background-color: $colors-offwhite;
  }

  color: #272727;
  font-weight: 400;

  .hero {
    background-color: $colors-light-blue;
    padding: 7rem 0 $rhythm;
    position: relative;
    @include breakpoint(10) {
      padding: 7rem 0 (2 * $rhythm);
    }

    &:before {
      background-image: url("#{$CDN_IMAGES}/images/_icons/pyramid-light-purple.svg");
      background-size: cover;
      background-position: 33% 0;
      background-repeat: no-repeat;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
    }
  }
  .intro {
    @include clearfix;

    .cta-wrap {
      @extend %lede;
      margin: 0 $rhythm $rhythm 0;
    }

    .cta {
      border-bottom: 1px solid $link-color;
      color: $header-color;
      display: inline-block;
      font-size: 1.5rem;
      margin: 27px $rhythm 20px;
      position: relative;
      vertical-align: top;
      z-index: 1;
      &:hover {
        border-color: $link-color-hover;
      }
    }

    svg {
      height: 80px;
      width: 80px;
      g {
        fill: $header-color;
      }
    }
  }

  .recent-activity-module-view {
    @include block(14);
  }

  .footnote {
    color: $colors-qqqq;
    font-size: 0.75rem;
  }

  .more-below {
    clear: both;
    padding-left: 10px;
  }

  .filters {
    padding: (2 * $rhythm) 0;
  }
  .filter-bar {
    @include clearfix;

    .search {
      margin-bottom: 1.5rem;
      position: relative;
      min-width: 320px;

      .company-search-form {
        display: flex;
      }

      .query-wrapper {
        position: relative;
        padding-left: 1.9rem;
        background: $colors-white;

        &::before {
          position: absolute;
          top: calc(50% - 0.6rem);
          left: 0.5rem;
          width: 1.6rem;
          height: 1.6rem;
          content: "";
          background-image: url($CDN_IMAGES + "/images/_icons/line-style/magnifying.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .query {
        background: $colors-white;
        border: 0;
        font-size: 1rem;
        line-height: 3rem;
        margin: 0;
        height: 3rem;
        padding: 0 3rem 0 1rem;
        width: 100%;
      }
    }
    .reset {
      .button {
        font-size: 0.85em;
        margin: 0;
      }
    }
    .offerings,
    .sort,
    .search {
      border-bottom: 8px solid #f9f8f4;
    }
    @include breakpoint(7) {
      .offerings,
      .sort,
      .search,
      .reset {
        max-width: 80%;
        margin: 0 auto;
      }
    }
    @include breakpoint(12) {
      .offerings,
      .sort,
      .search,
      .reset {
        border: 0;
        float: left;
        margin-right: 1rem;
        &:last-of-type {
          margin-right: 0;
        }
      }
      .offerings,
      .offerings ul,
      .sort,
      .sort ul,
      .search {
        margin-bottom: 0;
        width: 224px;
      }
    }
  }

  .no-results {
    color: $colors-nnnn;
    text-align: center;
    h2,
    p {
      max-width: 100%;
    }
  }

  .loading {
    &:before {
      background-color: $colors-offwhite;
    }
  }

  .companies {
    display: flex;
    flex-wrap: wrap;
    padding: $rhythm 0;
    width: 100%;

    @include breakpoint(12) {
      justify-content: space-evenly;
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: calc(320px + 1.5rem); // img width + padding
      grid-gap: 3rem;
      justify-content: space-evenly;

      @include breakpoint(12) {
        grid-template-columns: calc(352px + 1.5rem) calc(352px + 1.5rem); // img width + padding
      }

      @include breakpoint(18) {
        grid-template-columns: calc(336px + 1.5rem) calc(336px + 1.5rem) calc(
            336px + 1.5rem
          ); // img width + padding
      }
    }

    .company {
      position: relative;
      width: calc(320px + 1.5rem);
      margin: 0 auto;
      margin-bottom: 2 * $rhythm;
      padding: 0.75rem;
      padding-bottom: 0;
      border: 1px solid $colors-grey;
      background-color: $colors-white;
      transition: all $transition-speed-slow ease-out;

      &:hover,
      &:focus {
        box-shadow: -3px 3px 2px rgba(150, 150, 150, 0.75);
      }

      &:active {
        box-shadow: -3px 3px 2px rgba(150, 150, 150, 0.5);
      }

      &__title {
        margin: 0;
        padding: 1rem 0;
        font-size: 1.5rem;
        line-height: 2rem;
      }

      &__img {
        // img ratio 16:9
        display: block;
        width: 320px;
        height: 180px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      &__description {
        position: relative;
        height: 6rem;
        margin: 0;
        margin-bottom: $rhythm;
        overflow-y: hidden;
        font-weight: 300;

        &::after {
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          width: 100%;
          height: 3.5rem;
          background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1)
          );
          content: "";
        }
      }

      &__keywords {
        height: 5rem;
        margin: 0;
        margin-bottom: $rhythm;
        overflow-y: hidden;
        font-size: 0.85rem;
        line-height: 1.2rem;
        font-weight: 300;
      }

      &__keyword {
        margin-bottom: 0;
      }

      &__btn {
        display: block;
        width: 100%;
        margin: 0;
        padding: 1rem;
        text-align: center;
        font-family: "Arial", sans-serif;
        font-size: 1.15rem;
        font-weight: 500;
        border-radius: 0.125rem;
      }

      &__disclaimer {
        padding: 1rem 0;
        text-align: left;
        font-size: 0.75rem;
        line-height: 1rem;
        color: $colors-grey;
      }

      &__footer {
        display: flex;
        flex-wrap: wrap;
        padding: 0.25rem 0;
        font-size: 0.9rem;
        border-top: 1px solid $colors-grey;
      }

      &__share-price-raise-wrapper {
        flex-basis: 60%;
      }

      &__currency {
        display: inline-block;

        &--has-delta {
          position: relative;
          padding-left: 14px;
        }
      }

      &__delta {
        position: absolute;
        top: 0;
        left: 4px;
        width: 8px;
        height: 8px;
        content: "";
        font-size: 0;
        border: 4px solid $colors-white;

        &--up {
          top: calc(50% - 6px);
          border-bottom-color: $colors-dddd;
        }
        &--down {
          top: calc(50% - 2px);
          border-top-color: $colors-red-dark;
        }
      }

      &__raise {
        flex-basis: 50%;
      }

      &__end-date {
        flex-basis: 40%;
        align-self: flex-end;
        text-align: right;

        &.has-tooltip {
          margin: 0;
          font-style: normal;
        }
      }

      &__flag {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0.25rem 0.45rem;
        font-weight: 600;
        background-color: $colors-white;

        &--ending-soon {
          color: $colors-red-dark;
          box-shadow: 0 1px 3px rgba(150, 150, 150, 0.25);
        }

        &--coming-soon {
          color: $colors-purple;
          box-shadow: 0 1px 3px rgba(150, 150, 150, 0.25);
        }
      }

      @include breakpoint(12) {
        width: calc(352px + 1.5rem);

        &__title {
          height: 5.5rem;
        }

        &__img {
          // img ratio 16:9
          width: 352px;
          height: 198px;
        }

        &__disclaimer {
          height: 4rem;
        }
      }

      @include breakpoint(18) {
        width: calc(336px + 1.5rem);

        &__img {
          // img ratio 16:9
          width: 336px;
          height: 189px;
        }
      }

      &[data-is-active="1"] {
        .footer {
          .offering-type {
            color: $colors-purple;
          }
        }
      }
      &[data-is-active="0"] {
        .footer {
          .offering-type {
            color: $colors-grey;
          }
        }
      }

      &.offer-hidden,
      &.keyword-hidden {
        display: none;
      }

      @supports (display: grid) {
        margin-bottom: 0;
      }
    }
  }

  @include make-footer($background: #272727, $foreground: $colors-white);

  // mobile only
  @include breakpoint(10, $max: true) {
    .intro .cta-wrap {
      margin-right: 0;

      &:first-of-type .cta {
        margin-top: 16px;
      }
      .cta {
        border: 0;
        margin-right: 0;
        max-width: 60%;
        margin-top: 0;
      }
    }
  }
}
