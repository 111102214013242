#{to-string($selectors-input-fields)},
button {
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}

// Override browser-specific input field shenanigans
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill,
input:-internal-autofill:hover,
input:-internal-autofill:focus,
input:-internal-autofill-selected,
input:-internal-autofill-selected:hover,
input:-internal-autofill-selected:focus,
input:selection,
input:-moz-selection,
select:selection,
select:-moz-selection {
	background: none !important;
	background-color: $colors-transparent !important;
	background-position: 0 0 !important;
	outline: none;
}
