// a single item on the Events page
.event {
	border: 1px solid $colors-purple;
	margin-bottom: 2 * $rhythm;
	// height: 420px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 100%;
	margin: $rhythm 2% 0;
	position: relative;
	min-width: 320px;
	width: 48%;
	@media screen and (min-width: $bp-lg) {
		margin-left: 2%;
		margin-right: 1.5%;
		width: 31%;
	}

	&__header {
		padding: $rhythm $rhythm (0.5 * $rhythm);
	}
	&__content {
		padding: 0 $rhythm (2 * $rhythm);
	}
	&__images {
		padding: $rhythm;
	}
	&__date {
		font-weight: 400;
		white-space: nowrap;
	}
	&__name {
		font-weight: 400;
		margin-bottom: 1rem;
	}
	&__description {
		margin-bottom: $rhythm;
		// tag-based overrides
		p {
			margin-left: 0;
			margin-right: 0;
		}
		ul, ol {
			margin-left: $rhythm;
		}
		ul {
			list-style: disc;
		}
		ol {
			list-style: decimal;
		}
	}
	&__link {
		color: $colors-yellow;
		display: inline-block;
		font-weight: 600;
		margin-bottom: $rhythm;
		position: absolute;
		bottom: 0;
		// left: 0;
	}
	&__images {
		background-color: $colors-grey-lighter;
		text-align: center;
		min-height: 108px;
		@media screen and (min-width: $bp-lg) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}
	&__image {
		background-color: #fff;
		border-radius: 8px;
		border: 1px solid $colors-grey-lighter;
		box-shadow: 0 2px 4px rgba(0,0,0,0.05);
		color: $colors-grey;
		display: inline-block;
		font-weight: 400;
		margin: 0 1rem 0 0;
		padding: 4px 8px;
		width: 60px;
		height: 60px;
		line-height: 52px;
		overflow: hidden;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-origin: content-box;
		@media screen and (min-width: $bp-sm) {
			width: 100px;
		}
		img {
			display: block;
			width: 100%;
		}
		&:last-of-type {
			margin-right: 0;
		}
	}

	&--upcoming {
		border: 1px solid $colors-purple;
		margin: 0 auto $rhythm;
		max-width: 1400px;
		width: 100%;
		height: auto;
		.event__header {
			background: $colors-purple;
			color: #fff;
			font-size: 1.125rem;
			display: flex;
			flex-direction: column;
			align-content: center;
		}
		.event__date {
			margin-bottom: $rhythm;
		}
		.event__header,
		.event__content {
			padding: $rhythm;
		}
		.event__description {
			margin-bottom: 0;
		}
		.event__link {
			margin: 0;
			position: relative;
		}
		.event__images {
			.flex {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				margin-top: -1 * $rhythm;
			}
			.event__image {
				margin: $rhythm 0 0;
			}
		}
		// @media screen and (min-width: $bp-lg) {
		// 	display: flex;
		// 	align-content: center;
		// 	justify-content: space-between;
		// 	.event__header,
		// 	.event__content,
		// 	.event__images {
		// 		flex-basis: 0;
		// 	}
		// 	.event__header {
		// 		flex-grow: 1;
		// 	}
		// 	.event__content {
		// 		flex-grow: 1;
		// 	}
		// 	// .event__images {
		// 	// 	flex-grow: 1;
		// 	// }
		// }
		// @media screen and (min-width: $bp-lg) {
		// 	.event__header {
		// 		flex-grow: 0.75;
		// 	}
		// }
		// @media screen and (min-width: $bp-xxl) {
		// 	.event__header {
		// 		flex-grow: 0.5;
		// 	}
		// 	.event__images {
		// 		display: flex;
		// 		align-items: center;
		// 	}
		// }
		// &:first-child {
		// 	margin-left: auto;
		// }
		// &:last-child {
		// 	margin-right: auto;
		// }
		&.no-events {
			max-width: 560px;
		}
	}
}
