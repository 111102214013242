.image {
	display: flex;
	.two-imgs-row {
		flex-direction: row;
	}
	.two-imgs-column {
		flex-direction: column;
	}
}

.image-aligned-center {
	display: flex;
	align-items: center;
	padding-bottom: 1em;
	&.two-imgs-row {
		flex-direction: row;
		> *:first-child {
			margin-right: $rhythm;
		}
	}
	&.two-imgs-column {
		flex-direction: column;
	}
	&.align-items-center {
		align-items: center;
		padding-bottom: 1em;
	}
}

.image-text-wrap {
	&.right {
		img {
			float: right;
		}
	}
	img {
		width: 50%;
		float: left;
		padding: 8px;
		margin-right: 12px;
	}

	p {
		float: none;
	}
}

.image-tiny {
	width: 5em;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.image-small {
	width: 20em;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}