@import "../../common//input-component.scss";

// Form field:
// Phone Number w/ Country code
.form__fieldset--phone {

	.fields-container {
		display: flex;
		.form__select--phone-country-code {
			width: 86px;
			@include inputComponent();
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;

			@media screen and (min-width: $bp-md) {
				width: 110px;
			}
		}

		.form__input--phone {
			border-left: 0 !important;
			@include inputComponent();
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}
}