/* Tables
–––––––––––––––––––––––––––––––––––––––––––––––––– */
table {
	width: 100%;
	box-sizing: border-box;

	&.layout-fixed {
		table-layout: fixed;
	}
	&.no-border tr {
		border: 0;
	}
}

tr {
	border-bottom: 1px solid #E1E1E1;
}

th, td {

	@include breakpoint(10) {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	padding-top: 6px;
	padding-bottom: 6px;

	@each $breakpoint, $width in $gutters {
		@include breakpoint($breakpoint) {
			padding-left: $width * 0.5;
			padding-right: $width * 0.5;
		}
	}

	text-align: left;

	// enable tabular lining figures
	font-kerning: normal;
	font-variant-ligatures: common-ligatures, contextual;
	font-variant-numeric: lining-nums, tabular-nums;
	-moz-font-feature-settings: "kern", "liga", "clig", "calt", "lnum", "tnum";
	-ms-font-feature-settings: "kern", "liga", "clig", "calt", "lnum", "tnum";
	-webkit-font-feature-settings: "kern", "liga", "clig", "calt", "lnum", "tnum";
	font-feature-settings: "kern", "liga", "clig", "calt", "lnum", "tnum";

	#{to-string($selectors-input-fields)} {
		margin: 0;
	}

	.for-input {
		padding: 1rem;
	}

	&:first-child {
		padding-left: 0;

		&.for-input {
			padding-left: 1rem;
		}
	}
	&:last-child {
		padding-right: 0;

		&.for-input {
			padding-right: 1rem;
		}
	}

}

th {
	font-weight: 500;
	position: relative;

	a {
		color: $colors-black;
	}

	&[data-sort] {
		cursor: pointer;
	}

	&.sorting-asc::after,
	&.sorting-desc::after {
		content: ' ';
		display: block;
		height: 7px;
		width: 15px;
		position: absolute;
		right: 0;
		top: 12px;
		background-repeat: no-repeat;
		background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 7" width="34px" height="7px"><path d="M0 0 L14 0 L7 7" fill="#999"></path></svg>');
	}

	&.sorting-asc::after {
		@include vendor-prefix('transform', 'rotate(180deg)');
		@include vendor-prefix('transform-origin', '7px 5px');
	}

}
