.layout {
	$self: &;
	&--sidebar {
		@media screen and (min-width: $bp-lg) {
			display: flex;
			#{$self}__main-content {
				margin-right: 4 * $rhythm;
				flex-basis: 0;
				flex-grow: 999;
			}
			#{$self}__sidebar {
				flex-basis: 18 * $rhythm;
			}
		}
	}
}