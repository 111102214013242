.recent-activity-module-view {
  > .tabs {
    background: none;
    padding-top: 3px;
    a {
      border: 1px solid transparent;
      color: $colors-qqqq;
      cursor: pointer;
      display: inline-block;
      padding: 8px 12px;
      position: relative;
      top: 1px;
      z-index: 1;
      &:hover {
        color: $colors-rrrr;
        border-color: transparent;
      }
      &.is-active {
        background-color: #fff;
        border: 1px solid #e1e1e1;
        border-bottom: 1px solid #fff;
        color: #000;
      }
      @include breakpoint(7, $max: true) {
        padding: 8px;
      }
    }
  }

  .tab-content {
    background-color: #fff;
    display: none;
    min-height: 312px;
    overflow: auto;
    &.is-active {
      border: 1px solid #e1e1e1;
      display: block;
    }
  }
}