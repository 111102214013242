$buttons-pad-x: 1.25em;
$colors-buttons-disabled: #666666;

// todo:
// input[type='submit'] {
// 	transform: translate3d(0,0,0);
// }
// input[type='submit']:hover {
// 	box-shadow: 0 0.7rem 1.4rem rgba(51,58,70,.15), 0 0.3rem 0.6rem rgba(0,24,55,.065);
// 	transform: translateY(-0.1rem);
// 	transition: transform .45s ease, box-shadow .45s ease;
// }

@mixin button($background: $colors-aaaa, $foreground: $colors-white, $accent: $colors-mink, $border: false) {
	& {
		background-color: $background;
		$border-color: if($border, $border, $background);
		border: 1px solid $border-color;
		color: $foreground;
		text-shadow: none;
		cursor: pointer;

		&:hover,
		&:focus {
			text-shadow: none;
			background-color: darken($background, 3%);
			border-color: darken($border-color, 3%);
			opacity: 0.85;
		}

		&:active {
			opacity: 0.65;
		}

		&:disabled {
			background-color: $colors-buttons-disabled;
			cursor: not-allowed;
		}
	}
}

@mixin button-base {
	@include remove-underline;

	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	border-radius: 0.125rem;
	border: none;
	cursor: pointer;
	display: block;
	font-family: 'Source Sans Pro', Arial, serif;
	font-size: 1.25rem;
	font-weight: 400;
	height: 3rem;
	line-height: 3rem;
	padding-bottom: 0;
	padding-left: $buttons-pad-x;
	padding-right: $buttons-pad-x;
	padding-top: 0;
	text-align: center;

	&[disabled], &[disabled]:hover {
		background-color: $colors-buttons-disabled;
		cursor: not-allowed;
	}

	&:focus {
		outline: none;
	}
}