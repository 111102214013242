// Styles for generic show/hide tabbed toggles
// that do not instantiate BB views
[data-behavior="tabs"] {
	display: flex;
	flex-direction: column;

	.tab-nav {
		display: flex;
		justify-content: space-around;
		margin-bottom: $rhythm;
		position: relative;

		@media (min-width: $bp-md) {
			justify-content: flex-start;
		}

		&:before {
			background: $colors-grey-light;
			content: '';
			display: block;
			position: absolute;
			bottom: 2px;
			left: 0;
			height: 1px;
			width: 100%;
		}

		&__item {
			border-bottom: 5px solid rgba(0,0,0,0);
			color: $colors-grey;
			cursor: pointer;
			font-weight: 600;
			line-height: 2.25rem;
			padding-left: 4px;
			padding-right: 1rem;
			position: relative;
			text-align: center;

			.icon {
				margin: auto;
				width: 34px;
				svg {
					display: block;
				}
				path {
					fill: $colors-grey;
				}
			}

			&:hover {
				color: $colors-grey-med-dark;
				.icon path {
					fill: $colors-grey-med-dark;
				}
			}

			&.active {
				color: $colors-purple;
				border-color: $colors-purple;
				.icon path {
					fill: $colors-purple;
				}
			}

			@media (min-width: $bp-md) {
				padding-right: $rhythm;
				margin-right: $rhythm * 0.5;
				text-align: left;
				&:last-child {
					margin-right: 0;
				}
			}
		}

		&--has-icons {
			@media (min-width: $bp-md) {
				.tab-nav__item {
					margin-right: $rhythm;
					&:last-child {
						margin-right: 0;
					}
				}
				.icon {
					display: inline-block;
					margin-right: 8px;
					width: 20px;
				}
			}
		}
	}

	.tab {
		display: none;

		&.active {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}
	}
}