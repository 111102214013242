.password-eye {
	position: relative;
	.eye-icon {
		cursor: pointer;
		width: 1.5rem;
		position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);

		svg {
			display: block; // todo: should `.icon svg, .icon img` be global?
			path {
				&#strike {
					display: none;
				}
			}
		}
	}
}
