body.stealth-page {
	// @include links-for-background($background-color-marketing);
	display: flex;
	flex-direction: column;

	background-color: $background-color-marketing;
	main {
		flex-grow: 1;
    padding-bottom: 0;
    min-height: 90%;
	}
	.wrap {
		@include centered-container;
		position: relative;
	}
	
	.hero {
		background: #272727 url("#{$CDN_IMAGES}/images/secondary/paths/path-1.svg") no-repeat bottom right;
		background-position-y: 330px;
		color: #fff;
		height: 100%;
		padding-bottom: 4 * $rhythm;
		padding-top: 4 * $rhythm;
		position: relative;
		@include breakpoint(7) {
			background-position-y: 227px;
		}
		@include breakpoint(10) {
			background-position-y: 170px;
		}
		@include breakpoint(12) {
			background-position-y: 360px;
			padding-bottom: 10.5 * $rhythm;
			padding-top: 10.5 * $rhythm;
		}
		@include breakpoint(16) {
			background-position: bottom right;
		}
		h1 {
			color: #F9F8F4;
			line-height: 1;
			margin-bottom: 1.5rem;
			max-width: 80%;
			@include breakpoint(6) {
				font-size: 2rem;
			}
			@include breakpoint(10) {
				font-size: 2.25rem;
			}
			@include breakpoint(14) {
				font-size: 3rem;
			}
			@include breakpoint(18) {
				font-size: 4.5rem;
			}
			
    }
    .btn {
      @include button-base;
      @include button($background: #6C4EA5, $foreground: $colors-white, $accent: $colors-mink);
      display: inline-block;
      margin-bottom: $rhythm;
      font-weight: 400;
      width: auto;
    }
		.early-access {
			left: 0;
			position: absolute;
			top: 0;
			height: auto;
			width: 100px;
			@include breakpoint(10) {
				display: block;
				height: auto;
				width: 100px;
			}
			@include breakpoint(12) {
				height: 184px;
				width: 183px;
			}
		}
  }
}
