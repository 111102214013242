// Basic status indicator
.status {
	$self: &;

	&__icon {
		display: inline-block;
		margin-right: 6px;
		height: 20px;
		width: 20px;
		svg {
			display: block;
		}
	}

	&__message {
		display: inline-block;
	}

	&--success {
		color: $colors-success;
		#{$self}__icon {
			transform: translateY(6px);
			svg path {
				fill: $colors-success;
			}
		}
	}

	&--warning {
		color: $colors-warning;
		#{$self}__icon {
			transform: translateY(4px);
			svg path {
				fill: $colors-yellow;
			}
		}
	}

	&--error {
		color: $colors-error;
		#{$self}__icon {
			transform: translateY(4px);
			svg path {
				fill: $colors-error;
			}
		}
	}

	&--disabled {
		color: $colors-grey;
		#{$self}__icon {
			transform: translateY(4px);
			svg path {
				fill: $colors-grey;
			}
		}
	}

}