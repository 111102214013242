.file-download {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: -30px;
    display: inline-block;
    width: 25px;
    height: 100%;
    content: '';
    background-image: svg-url('<svg width="25px" height="32px" viewBox="0 0 25 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Template" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Company-Profile-_Template_NetcapBrand_Neutral" transform="translate(-969.000000, -13447.000000)" stroke="#6C4EA5" stroke-width="2"><g id="Body-Content" transform="translate(0.000000, 905.902784)"><g id="Slide-Deck" transform="translate(330.000000, 12125.144192)"><g id="Bitmap" transform="translate(0.000000, 55.000000)"><g id="basic-elaboration-document-download" transform="translate(640.632447, 361.953884)"><g id="Group"><polygon id="Path" points="6.69565217 0 22 0 22 29.6521739 0 29.6521739 0 6.69565217"></polygon><polyline id="Path" points="0 6.69565217 6.69565217 6.69565217 6.69565217 0"></polyline></g><polyline id="Path" stroke-linejoin="bevel" points="14.826087 17.2173913 11 21.0434783 7.17391304 17.2173913"></polyline><path d="M11,21.0434783 L11,11.4782609" id="Path"></path></g></g></g></g></g></g></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 center;
  }

  &:hover::before,
  &:focus::before {
    opacity: 0.7;
  }

  &:active::before {
    opacity: 0.4;
  }

  &--icon-left {
    display: block; // todo
    margin-bottom: $rhythm; // todo
    padding-left: 26px;
    &::before {
      left: 0;
      right: auto;
    }
  }

  &--no-underline {
    @include remove-underline;
  }
}

.files {
	@media screen and (min-width: $bp-md) {
		&__column {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;

			&--2 > * {
        margin-left: 1%;
        margin-right: 1%;
				width: 48%;
			}
			&--3 > * {
				width: 31%;
			}
		}
	}
}
