@mixin border($color, $width: 1px, $type: solid, $side: all, $radius: null) {
  @if $side == "all" {
    border: $width $type $color;
    @if $radius != null {
      border-radius: $radius;
    }
  } @else {
    // Apply the border to a specific side
    @each $s in $side {
      @if $s == "top" {
        border-top: $width $type $color;
      } @else if $s == "right" {
        border-right: $width $type $color;
      } @else if $s == "bottom" {
        border-bottom: $width $type $color;
      } @else if $s == "left" {
        border-left: $width $type $color;
      } @else {
        @warn "Invalid side: #{$s}. Use 'top', 'right', 'bottom', 'left', or 'all'.";
      }
    }
    // Apply border-radius if specified
    @if $radius != null {
      border-radius: $radius;
    }
  }
}
