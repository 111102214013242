// todo: max-widths
h1, h2, h3, h4, h5, h6 {
	clear: both;
}

h1,
h2,
h3 {
	font-family: 'Source Serif Pro';
}

h4,
h5,
h6 {
	font-family: 'Source Sans Pro';
	font-size: 1.125rem;
	line-height: $rhythm;
}

#{$selectors-block-elements} {
	& + h2 {
		margin-top: 2 * $rhythm;
	}

	& + h3 {
		margin-top: 1.5 * $rhythm;
	}

	& + h6 {
		margin-top: $rhythm;
	}
}

// this isn’t ideal, but this allows us to fake h3 vertical rhythm when the h3 is contained within another element
.h2 {
	margin-top: 2 * $rhythm;
}

.h3 {
	margin-top: 1.5 * $rhythm;
}

h2,
h3,
h4,
h5,
h6 {
	@include max-width($measure);
}

h1 {
	@include max-width(12);

	font-size: 1.875rem;
	font-weight: 600;
	line-height: 2.25rem;
	margin-bottom: 2 * $rhythm;

	@include breakpoint(6) {
		font-size: 2rem;
		line-height: 2.25rem;
	}

	@include breakpoint(10) {
		font-size: 2.25rem;
		line-height: 2.25rem;
	}

	@include breakpoint(12) {
		font-size: 3rem;
		line-height: 3rem;
	}

	.supplement {
		font-weight: 300;
		margin-bottom: 2 * $rhythm;
	}
}

h2 {
	font-size: 1.875rem;
	font-weight: 400;
	line-height: 2.25rem;
	margin-bottom: $rhythm;

	@include breakpoint(6) {
		font-size: 2rem;
		line-height: 2.25rem;
	}

	@include breakpoint(12) {
		font-size: 2.25rem;
		line-height: 2.25rem;
	}
}

h3 {
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.5rem;
	margin-bottom: 0.5 * $rhythm;

	@include breakpoint(12) {
		&:not(.static) {
			font-size: 1.5rem;
			line-height: 1.75rem;
		}
	}
}

h4 {
	font-weight: 700;
}

h5 {
	font-size: 1.0625rem;
	font-weight: 400;
	text-transform: uppercase;
}

h6 {
	font-style: italic;
	font-weight: 200;
}