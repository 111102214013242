// @import 'lib/lity';
// @import 'lib/flickity';
// @import 'lib/OverlayScrollbars';
@import '../blocks/hero';
@import '../company-blocks/v5/figure';
@import '../company-blocks/v5/image-grids';

body.blog-page {
  .filters {
    padding: $rhythm 0 ($rhythm * 0.5);
    text-align: center;
    li {
      display: inline-block;
      margin: 0 4px 8px;
      h4 {
        border: 2px solid $colors-offwhite;
        border-radius: 4px;
        color: $colors-grey;
        cursor: pointer;
        font-weight: 600;
        padding: 6px 8px;
        &:hover,
        &.is-selected {
          border-color: $colors-yellow;
          color: $colors-yellow;
        }
      }
    }
  }

  .main-content {
    padding: (3 * $rhythm) 0;
  }

  article {
    @include centered-container;
    margin-bottom: 4 * $rhythm;
    position: relative;

    .tag {
      color: $colors-grey-dark;
      font-size: 0.95rem;
      font-family: 'Source Sans Pro';
      letter-spacing: 0.05rem;
      line-height: 1;
      text-transform: uppercase;
      padding-top: $rhythm;
      position: absolute;
      top: 0;
      left: 0;
      width: breakpoint-width(3);

      &:before {
        background: $colors-purple;
        content: '';
        width: 14px;
        height: 2px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .article-content {
      @include centered-container($width: (0:5, 7:6, 10:7, 12:8, 14:10, 16:12, 22:16));
      padding-top: 4rem;
      @include breakpoint(18) {
        padding-top: 0;
      }
      // TODO: generalize list styles, excluding application form
      ol,
      ul {
        margin: 0 0 $rhythm 24px;
        max-width: none;
        padding-left: 0;
      }

      li {
        margin-bottom: $rhythm * 0.5;
      }

      ul {
        list-style-image: svg-url('<svg class="chevron chevron-right" width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <defs> <path d="M8.22222222,9.72222222 L8.22222222,4.38888889 C8.22222222,3.89796911 8.62019133,3.5 9.11111111,3.5 C9.60203089,3.5 10,3.89796911 10,4.38888889 L10,10.6111111 C10,11.1020309 9.60203089,11.5 9.11111111,11.5 L2.88888889,11.5 C2.39796911,11.5 2,11.1020309 2,10.6111111 C2,10.1201913 2.39796911,9.72222222 2.88888889,9.72222222 L8.22222222,9.72222222 Z" id="path-1"></path> </defs> <g id="Template" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Company-Profile-_Template_NetcapBrand_Neutral" transform="translate(-334.000000, -9427.000000)"> <rect fill="none" x="0" y="0" width="1440" height="20525"></rect> <g id="Body-Content" transform="translate(0.000000, 905.902784)"> <g id="H3-Block" transform="translate(329.000000, 8170.896539)"> <g id="Icons/Bullet" transform="translate(0.810205, 348.634537)"> <g id="Colors/Primary"> <rect id="Rectangle" fill-opacity="0" fill="#D8D8D8" x="0" y="0" width="16" height="16"></rect> <mask id="mask-2" fill="white"> <use xlink:href="#path-1"></use> </mask> <use id="Combined-Shape" fill="#6C4EA5" transform="translate(6.000000, 7.500000) rotate(-45.000000) translate(-6.000000, -7.500000) " xlink:href="#path-1"></use> </g> </g> </g> </g> </g> </g> </svg>');

        > li ul {
          border-left: 2px solid $colors-purple;
          list-style: none;
          margin-top: 0.5 * $rhythm;
          margin-bottom: 0;
          padding-left: 0.5 * $rhythm;
        }

        &.file-list {
          list-style: none;
          margin: 0 0 (2 * $rhythm);
          max-width: none;
        }
      }

      ol {
        list-style: decimal;
        > li ul {
          margin-bottom: 0;
        }
      }
    }

    h2 {
      color: $colors-purple;
      // line-height: 2.5rem;
      padding-right: 40px;
      position: relative;
      // TODO: externalize
      .copy-to-clipboard {
        @include remove-underline;
        transition: opacity $transition-speed-base ease;
        position: absolute;
        right: 0;
        top: 7px;
        opacity: 0.35;
        width: 24px;
        &:after {
          transition: opacity $transition-speed-base ease;
          content: 'Copy Link';
          color: $colors-grey;
          display: block;
          font-size: 0.75rem;
          font-family: 'Source Sans Pro';
          letter-spacing: 0.05rem;
          position: absolute;
          left: -17px;
          bottom: -13px;
          opacity: 0;
          width: 80px;
        }
        &:hover {
          opacity: 1;
          &:after {
            opacity: 1;
          }
        }
        textarea {
          opacity: 0;
          position: absolute;
          left: -9999px;
        }

      }
      .category {
        color: $colors-grey-light;
        font-family: 'Source Sans Pro';
        font-size: 0.4em;
        font-weight: 600;
      }
      .author {
        color: $colors-grey-med-dark;
        // font-family: 'Source Sans Pro';
        // font-size: 0.8em;
        // line-height: 1.8em;
      }
    }

    h2, h3, h4, p {
      max-width: none;
    }

    .pullquote,
    .disclaimer {
      font-style: italic;
      text-align: center;
    }
    .pullquote {
      font-size: 1.2rem;
    }
    .disclaimer {
      font-size: 0.85rem;
      line-height: 1.15rem;
    }

    .link-out {
      color: $colors-grey-dark;
      font-weight: 600;
    }
  }

  @include make-footer($background: #272727, $foreground: $colors-white);
}
