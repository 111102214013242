@import "header-mixins";
@import "../../mixins/index-mixins.scss";
@import "../../tokens/colors.scss";
@import "../../functions/rem-conversion.scss";
@import "../../common/button-component.scss";

// Generic hero with offwhite background and svg line drawing
// see: News/Blog, Dashboard
main.hero-generic {
  padding: 0;
  position: relative;
  // declarations below fix negative margin weirdness in FF
  display: inline-block;
  vertical-align: top;
  width: 100%;

  .hero {
    @include clearfix;
    background-color: $colors-offwhite;
    position: relative;
    padding-top: 7rem;

    h1,
    h3,
    h4 {
      margin-bottom: $rhythm;
      max-width: 100%;
    }

    h1 {
      color: $colors-purple;
      margin-top: 2 * $rhythm;
    }

    h4 {
      font-weight: 400;
    }

    .bg {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -ms-user-select: none; /* IE 10+ */
      user-select: none;
      z-index: 0;

      @include breakpoint(12) {
        top: 0;
        left: 0;
      }
    }

    .container {
      > .wrap {
        @include centered-container;
        position: relative;
        margin-bottom: 2 * $rhythm;
      }
    }
  }
}

// Used on Home and Sign Up pages
.hero-section {
  padding: pxToRemVal(92px) pxToRemVal(24px) pxToRemVal(94px);

  &__content {
    max-width: pxToRemVal(1440px);
    width: 100%;
    margin: 0 auto;
    @include flex($direction: column, $justifyContent: center);
    padding-top: pxToRemVal(112px);
    max-width: pxToRemVal(500px);
    width: 100%;
    margin: 0 auto;
    @include flex(
      $direction: column,
      $justifyContent: center,
      $alignItems: center
    );
  }

  &__title {
    @include webTextVariant("web-heading1");
    color: $soft-black;
    width: 100%;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    padding-bottom: pxToRemVal(40px);
    margin-bottom: 0;
    @include webTextVariant("web-body");
    color: $soft-black;
  }

  &__buttons {
    padding-bottom: pxToRemVal(20px);
    @include flex($justifyContent: center, $gap: pxToRemVal(24px));
  }

  &__btn-explore {
    @include buttonComponent("large", "primary");
  }

  &__btn-raise {
    @include buttonComponent("large", "secondary");
  }

  &__slogan {
    @include webTextVariant("web-heading6-italic");
    color: $soft-black;
    text-align: center;
    padding-bottom: pxToRemVal(72px);
  }

  &__success-stories {
    width: 100%;
    margin: 0 auto;
    @include flex(
      $justifyContent: center,
      $gap: pxToRemVal(24px),
      $alignItems: center
    );

    @media screen and (min-width: $tablet-max-width) {
      @include flex($direction: row, $alignItems: flex-start);
    }
  }

  &__success-stories-card {
    border-radius: pxToRemVal(24px);
    @include border(
      $color: rgba(0, 0, 0, 0.01),
      $type: solid,
      $width: pxToRemVal(6px)
    );
    background-color: #ffffff;
    box-shadow: 0 pxToRemVal(6px) pxToRemVal(20px) 0 rgba(0, 0, 0, 0.1);
    @include flex(
      $direction: column,
      $alignItems: center,
      $alignSelf: stretch,
      $gap: pxToRemVal(20px)
    );
    padding-top: pxToRemVal(24px);
    max-width: pxToRemVal(370px);
    width: 100%;

    .success-stories-card {
      &__value {
        @include border($color: $neutral-200, $radius: pxToRemVal(24px));
        background: #ffffff;
        padding: pxToRemVal(1px) pxToRemVal(12px);
        @include flex(
          $alignItems: center,
          $justifyContent: center,
          $gap: pxToRemVal(4px)
        );
        @include webTextVariant(web-small-semibold);
        color: $primary-1100;
      }

      &__icon {
        background-size: 100% 100%;
        height: 3rem;
        width: 12rem;
        background-repeat: no-repeat;
        background-position: center;

        > svg {
          width: pxToRemVal(225px);
          height: pxToRemVal(52px);
        }
      }

      &__footer {
        width: 100%;
        @include border($color: rgba(204, 193, 224, 0.16), $side: top);
        color: #ffffff;
        padding: pxToRemVal(12px);
        @include flex($justifyContent: center);
        flex: 1 0 0;
      }

      &__quote {
        overflow: hidden;
        color: #000;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        text-align: center;
        @include flex($alignItems: flex-start);
        flex: 1 0 0;
        border-radius: pxToRemVal(12px);
        padding: pxToRemVal(12px);
        @include webTextVariant(web-body-tiny);
        background-color: $primary-100;
        margin: 0;
      }
    }

    &:nth-child(1) {
      .success-stories-card__quote {
        background-color: $primary-100;
      }
    }

    &:nth-child(2) {
      .success-stories-card__quote {
        background-color: $success-100;
      }
    }

    &:nth-child(3) {
      .success-stories-card__quote {
        background-color: $seccondary-100;
      }
    }
  }

  &__disclaimer {
    padding-top: pxToRemVal(32px);
    max-width: pxToRemVal(684px);
    width: 100%;
    margin: 0 auto;
    @include webTextVariant(web-caption);
    color: $neutral-300;
    text-align: center;
  }
}

// Events page
.hero--mic {
  background-image: url("#{$CDN_IMAGES}/images/events/hero-mic.jpg");
  background-position: center;
  background-size: cover;
  color: #fff;
  position: relative;
  height: 386px;
}

// Featured In - Section

.featured {
  width: 100%;
  padding-top: pxToRemVal(92px);

  &__content {
    @include flex($justifyContent: center);
  }

  &__title {
    text-align: center;
    color: $neutral-500;
    @include webTextVariant(web-body);
  }

  &__logo-wrapper {
    margin-bottom: 2rem;
    padding-top: pxToRemVal(24px);
    width: 100%;
  }

  &__title {
    text-align: center;
    color: $neutral-500;
    @include webTextVariant(web-body);
  }

  &__logo-wrapper {
    margin-bottom: 2rem;
    padding-top: pxToRemVal(24px);
    width: 100%;
  }

  &__logo-slider {
    @include flex(
      $justifyContent: flex-start,
      $alignItems: center,
      $gap: pxToRemVal(24px)
    );
    overflow: visible;
    position: relative;
    animation: slide 40s linear infinite;
    white-space: nowrap;
    width: calc(200% + pxToRemVal(48px));

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 1;
    }
  }

  &__logo-slide {
    column-gap: pxToRemVal(60px);
    row-gap: pxToRemVal(60px);
    flex: none;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    min-width: pxToRemVal(1650px);
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important; /* Hat tip Nick/cssremedy (https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/#comment-1700170) */
    transition-duration: 0.001ms !important;
  }
}

// MAX -  Media for Tablet

@media screen and (max-width: $tablet-max-width) {
  .hero-section {
    padding: pxToRemVal(76px) pxToRemVal(24px) pxToRemVal(94px);

    &__content {
      padding-top: pxToRemVal(50px);
    }

    &__title {
      @include tabletTextVariant("tablet-heading1");
    }

    &__subtitle {
      @include tabletTextVariant("tablet-body-small");
    }

    &__slogan {
      @include tabletTextVariant("tablet-body-tiny-italic");
    }

    &__success-stories {
      @include position();
      height: pxToRemVal(300px);
    }

    &__success-stories-card {
      @include position($position: absolute);
      transition: transform 0.5s ease-in-out;
      cursor: pointer;
      min-height: 100%;

      &:nth-child(1) {
        max-width: pxToRemVal(535px);
        width: 100%;
        z-index: 3;
        top: 0;
      }

      &:nth-child(2) {
        max-width: pxToRemVal(500px);
        width: 100%;
        z-index: 2;
        top: 6%;
      }

      &:nth-child(3) {
        max-width: pxToRemVal(480px);
        width: 100%;
        z-index: 1;
        top: 11%;
      }
    }

    &__disclaimer {
      padding-top: pxToRemVal(64px);
    }
  }
}

// MAX - Media for Mobile
@media screen and (max-width: $mobile-max-width) {
  .hero-section {
    padding: pxToRemVal(30px) pxToRemVal(16px);

    &__title {
      @include mobileTextVariant("mobile-heading1");
    }

    &__subtitle {
      @include mobileTextVariant("tablet-body");
    }

    &__slogan {
      @include mobileTextVariant("web-heading6-italic");
    }

    &__buttons {
      @include flex($direction: column);
    }

    &__disclaimer {
      padding-top: pxToRemVal(64px);
    }
  }
}
