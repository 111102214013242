p {
	// @include block;
	@include max-width($measure);
	margin-bottom: $rhythm;
	// clear: both;
}

p i {
	font-style: italic;
}

%disclaimer,
.disclaimer, 
.disclaimers {
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1.33333;
	opacity: 0.75;
	text-align: justify;
}

%lede,
.lede {
	font-size: 1.25rem;
	line-height: $rhythm;
}

.marquee,
%marquee {
	font-size: 1.125rem;
	line-height: $rhythm;
}

.major,
%major {
	font-size: 1.5rem;
	line-height: $rhythm;
}

dl + p,
ol + p,
ul + p {
	margin-top: $rhythm * 0.5;
}
