body.entrepreneurs-page {

	// todo: abstract this, since it's just about adjusting a few colors based on the background.
	@include header-invert-colors($background-color: $colors-hhhh);

	background-color: $background-color-marketing;

	main {
		padding-top: 0;
		// required for section-nav with 'fixed bottom' styles
		position: relative;
	}

	.intro {
		background-color: $colors-hhhh;
		color: $colors-white;
		font-weight: 400;
		padding-top: 2 * $rhythm;
		padding-bottom: 2 * $rhythm;

		@include breakpoint(9) {
			background-image: url('#{$CDN_IMAGES}/images/entrepreneurs/pyramid.svg');
			background-position: 50% 100%;
			background-size: auto 100%;
			background-repeat: no-repeat;
		}

	}

	.hero-content {
		h1 {
			margin-bottom: 1rem;
		}
		h3 {
			margin-bottom: 3rem;
			max-width: 100%;
		}
	}

	.section-nav {
		padding-top: 3 * $rhythm;
	}

	.body {
		@include container(8, $push:(0:0,10:1,12:3,14:3,16:4,18:5,20:6,22:7,24:8));
		@include links-colors($background-color-marketing);
		padding-top: 3 * $rhythm;
		overflow: visible; //override

		p {
			@extend %marquee;
		}
	}

	.issuer-lead-form-view-container {
		background-color: #fff;
		color: #000;
		margin-left: 8px;
    margin-right: 8px;
		max-width: 930px;
    padding: 16px;
	}

	.issuer-lead-form-view {

		.form__field {
			&.terms label {
				font-size: 1rem;
			}
		}

		.reset-password {
			line-height: 2 * $rhythm;
		}

		.application-form.cta {
			text-shadow: none;
		}
	}

	.existing-listings .cta {
		color: $colors-purple-dark;
		font-size: 1.25rem;
	}

	@include footer-push($columns: (0: 0, 18: 1, 20: 2, 22: 3, 24: 4));

}
