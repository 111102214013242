@use "sass:math";
input[type='submit'],
input[type='button'],
a.button {
	@include button-base;
	@include breakpoint(5) {
		@include block-margins;
		float: left;
		position: relative;
		z-index: 1;
	}
}

// todo: rename to .btn
input[type='submit'],
input[type='button'],
.button.primary {
	@include button($background: $colors-aaaa, $foreground: $colors-white, $accent: $colors-mink);
}

.button.primary-v2 {
	@include button($background: $colors-purple, $foreground: $colors-white, $accent: $colors-mink);
}

.button.secondary {
	@include button($background: $colors-yellow, $foreground: $colors-black, $accent: $colors-yellow);
}

.button.transparent {
	@include button($background: transparent, $foreground: $colors-black, $accent: transparent, $border: $colors-grey-light);
}

form.busy input[type='submit'],
input[type='submit'].busy {
	color: rgba(0,0,0,0) !important;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 12" width="72px" height="12px"><style>@keyframes example{from{opacity: 0;}to{opacity: 1;}}.busy circle{fill:#fff;animation: example 1s ease-in infinite alternate;}.busy circle:nth-child(2){animation-delay:0.2s;}.busy circle:nth-child(3){animation-delay: 0.4s;}</style><g class="busy"><circle stroke="none" cx="6" cy="6" r="4"></circle><circle stroke="none" cx="30" cy="6" r="4"></circle><circle stroke="none" cx="54" cy="6" r="4"></circle></g></svg>');
	background-size: initial;

	&:hover {
		cursor: wait;
	}
}

.actions-via-button {
	position: relative;

	&__button {
		position: relative;
		display: inline-block;
		padding: 4px 0;
		padding-left: 12px;
		padding-right: 28px;
		font-size: 0.85rem;
		background-color: $colors-white;
		border: 1px solid $colors-purple;
		border-radius: 4px;
		color: $colors-purple;
		cursor: pointer;

		.chevron-right {
			position: absolute;
			top: calc(50% - 8px);
			right: 10px;
			display: block;
			transition: transform $transition-speed-base ease;
			transform: rotate(90deg);
			width: 8px;
			height: 16px;
			#chevron-right {
				stroke: $colors-purple;
			}
		}
	}

	&__submenu {
		background: $colors-offwhite;
		border: 1px solid $colors-grey-light;
		border-radius: 4px;
		box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.0914289);
		display: none;
		margin: 0;
		padding: math.div($rhythm, 1.5);
		position: absolute;
		text-align: right;
		right: 0.75rem;
		width: 224px;
		z-index: 2;
		li {
			margin-bottom: math.div($rhythm, 3);
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		a {
			@include remove-underline;
			color: #000;
			font-weight: 400;
			&:hover {
				color: $colors-purple;
				text-decoration: underline;
			}
		}
	}

	&:hover {
		.actions-via-button__button {
			color: $colors-white;
			background-color: $colors-purple;
			.chevron-right {
				transform: rotate(270deg);
				#chevron-right {
					stroke: #fff;
				}
			}
		}

		.actions-via-button__submenu {
			display: block;
		}
	}
}

//Infinite List Buttons

.previous-names-view, .funding-history-view, .creditors-view, .voting-view, .names-view, .proceeds-view, .investor-communications-view, 
.equity-securities-view, .option-securities-view, .additional-risks-view, .employment-records-view { 
	table.selectable {
		tr {
			//hide table cell border around buttons
			td:last-child {
				border: 0;
			}
		}
	}
	.delete {
		cursor: pointer;
		width: 20px;
		height: 20px;
		display: block;
		margin: 0 6px;

		&:hover {
			svg path {
				fill: red;
			}
		}
	}
	//dont show delete btns for empty rows
	tr.dummy .delete {
		display: none;
	}
}

//override to show cell border for last table cell of last 2 rows in 
//UoP table (Unallocated Funds & Netcapital Fee)
.proceeds-view {
	table.selectable {
		tr.not-selectable {
			td:last-child {
				border: 1px solid #dfe2ea;
			}
		}
	}
}
