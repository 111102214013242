@import "header-mixins";
@import "../../mixins/index-mixins.scss";
@import "../../tokens/colors.scss";
@import "../../functions/rem-conversion.scss";
@import "../../common//button-component.scss";

.header-hero {
  background: $hero-section-gradient;
  overflow: hidden;
}

.header {
  background-color: transparent;
  padding: pxToRemVal(16px) pxToRemVal(20px);
  @include position($position: fixed, $left: 0, $right: 0, $top: 0);
  z-index: 9;

  @media screen and (max-width: $mobile-max-width) {
    padding: 0;
  }

  &__container {
    max-width: pxToRemVal(1156px);
    width: 100%;
    max-height: pxToRemVal(60px);
    margin: 0 auto;
    padding: pxToRemVal(20px) pxToRemVal(12px) pxToRemVal(20px) pxToRemVal(24px);
    border-radius: pxToRemVal(100px);
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 pxToRemVal(12px) pxToRemVal(32px) 0 rgba(145, 123, 188, 0.26);
    backdrop-filter: blur(pxToRemVal(20px));
    @include flex($justifyContent: center, $alignItems: center);

    @media screen and (max-width: $mobile-max-width) {
      border-radius: 0;
    }
  }

  &__nav {
    width: 100%;
    @include flex($justifyContent: space-between, $alignItems: center);
  }

  &__links {
    display: none;
    @media screen and (min-width: $tablet-max-width) {
      @include flex($alignItems: center, $gap: pxToRemVal(32px));
      width: 100%;
    }
  }

  &__link {
    color: $soft-black;
    @include webTextVariant("web-body-small");
    @include flex($justifyContent: center, $alignItems: center);
    padding: 4px 12px;

    &:hover {
      border-radius: 8px;
      background: #f2f2f4;
    }
  }

  &__brand {
    @include flex();
    max-width: pxToRemVal(305.5px);
    width: 100%;
    &--img {
      width: pxToRemVal(92px);
      height: pxToRemVal(19px);
    }
  }

  &__auth {
    @include flex(
      $gap: pxToRemVal(20px),
      $alignItems: center,
      $justifyContent: flex-end
    );
    width: 100%;
    max-width: pxToRemVal(305.5px);

    &--links {
      display: none;

      @media screen and (min-width: $tablet-max-width) {
        @include flex($gap: pxToRemVal(20px), $alignItems: center);
      }
    }
  }

  &__signin {
    @include buttonComponent("regular", "tertiary");
    @include webTextVariant("web-body-small");
    color: $soft-black;
    border-radius: 100px;
  }

  &__signup {
    @include buttonComponent("regular", "primary");
    border-radius: 100px;

    @media screen and (max-width: 900px) {
      padding: 0.75rem 0.5rem;
    }
  }
}

//  Hamburger menu for ANONYMOUS USERS
.hamburger-menu {
  display: block;

  .menu-icon {
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.responsive-menu {
  position: absolute;
  top: pxToRemVal(70px);
  background: #ffffff;
  box-shadow: 0 pxToRemVal(12px) pxToRemVal(32px) 0 rgba(145, 123, 188, 0.26);
  right: 0;
  border-radius: pxToRemVal(20px);
  box-shadow: 0 pxToRemVal(6px) pxToRemVal(12px) 0 rgba(28, 28, 30, 0.12);
  max-width: pxToRemVal(192px);
  width: 100%;
  @include flex($direction: column, $alignItems: flex-start);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  .hamburger__link {
    width: 100%;
    padding: pxToRemVal(12px) pxToRemVal(20px);
    color: $soft-black;
    @include webTextVariant("web-body-small");
    @include flex($justifyContent: flex-start);
  }

  > li {
    width: 100%;
    cursor: pointer;
    @include flex(
      $alignItems: flex-start,
      $gap: pxToRemVal(8px),
      $alignSelf: stretch,
      $justifyContent: flex-start
    );

    &:first-of-type {
      border-radius: pxToRemVal(20px) pxToRemVal(20px) 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 pxToRemVal(20px) pxToRemVal(20px);
    }

    &:hover {
      background-color: $neutral-100;
    }
  }

  .border-bottom {
    @include border($color: $neutral-100, $side: bottom);
  }

  .border-top {
    @include border($color: $neutral-100, $side: top);
  }
}

.hidden {
  display: none;
}

#loading-state {
  @include flex($justifyContent: space-between, $gap: 1rem);

  .placeholder-btn {
    border-radius: pxToRemVal(100px);
    background-color: $neutral-100;
    width: pxToRemVal(100px);
    height: pxToRemVal(44px);
    animation: skeleton-loading 1s linear infinite alternate;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

@keyframes skeleton-loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
