// todo: most of this should be base styles

.log-in,
.sign-up,
%auth-forms {

	.title {
		.supplement {
			display: block;
			font-size: 2rem;
			.switch-flow {
				font-weight: 400;
			}
		}
		.form__list {
			font-family: 'Source Sans Pro';
			font-weight: 400;
			margin-left: 0.5rem;
			margin-top: 1rem;
		}
	}

	// .actions {
	// 	@include block;
	// 	line-height: 3rem;
	// 	font-size: 1.25rem;
	// }

	form {
		@include block-wrap(8);
		overflow: visible;
	}

	// .field.first-name,
	// .field.last-name {
	// 	@include block($width: (0: 8, 8: 4));
	// }

	// label.legend {
	// 	margin-left: 8px;
	// 	font-weight: 500;
	// 	font-size: 0.85rem;
	// }

	// .decision input[type='submit'] {
	// 	@include block(2);
	// }

	.decision {
		a {
			line-height: 3rem; // to match button height
		}
	}

}

%auth-pages {

	@extend %auth-forms;
	@include make-footer($background: #2a0a58, $foreground: $colors-white);

	// todo: this main and h1 style should eventually be on everything...
	main {
		@include centered-container;
	}

	// reset the styles of the Sign Up button in the header
	// use !important so we don’t have to repeat media queries
	// header .authentication a {
	// 	padding: 0 !important;
	// 	background: none !important;
	// }

}

#create-account, #reset-password {
	.field {
		@include max-width(8);
	}
	.password {
		// border: none;
		margin: 0;
	}
}

// .log-in,
// .sign-up {
// 	@extend %auth-forms;
// }