// A field with a checkbox enabling/disabling the input
.toggleable-field {
  .toggle {
		font-size: 0.85rem;
		input[type="checkbox"] {
			~ label {
				color: $colors-grey;
			}
			&:checked {
				~ label {
					color: #000;
				}
			}
		}
	}
	.input input:disabled {
		background-color: $colors-grey-light;
	}
}