// Vendor Prefixes
@use "sass:math";

@mixin vendor-prefix($name, $value, $vendors: ('-webkit-', '-moz-', '-ms-', '-o-', '')) {
	@each $vendor in $vendors {
		#{$vendor}#{$name}: #{$value};
	}
}

// completely remove an element from the flow
@mixin remove() {
	position: absolute;
	z-index:-1000;
	left:-1000px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }
}
