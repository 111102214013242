@import '../lib/OverlayScrollbars';

.recent-announcements-view {
  .module {
    .scroll-wrap {
      max-height: 309px;
      overflow-y: auto;
      padding: ($rhythm * 0.5) 0 $rhythm;

      .os-scrollbar {
        background: #fff;
      }
    }
    table {
      table-layout: fixed;
      th,
      td {
        &.date {
          width: 20%;
        }
        &.company {
          width: 30%;
        }
        &.announcement {
          padding-right: 12px;
          width: 50%;
        }
      }
    }
    .company {
      width: 134px;
    }
    .date {
      width: 80px;
    }
    .announcement .truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}