@mixin header-invert-colors($background-color, $color: $colors-white) {
	header {
		@if($background-color) {
			background-color: $background-color;
		}
		color: $color;
		font-weight: 400;

		a,
		.wordmark {
			color: $color;
		}

		@if $color == $colors-white {
			.wordmark__img--black {
				display: none;
			}
			.wordmark__img--white {
				display: inline;
			}
		}

		span.menu::before {
			background: linear-gradient($color, $color 10%, transparent 10%, transparent 45%, $color 45%, $color 55%, transparent 55%, transparent 90%, $color 90%);
		}
	}
}
