// compensate for links without an href attribute
[data-action] {
	cursor: pointer;
}

p, h1, h2, h3, h4, h5, h6, li, legend, .legend, label, .decision {
	a {
		@include links;
		// background-image: linear-gradient($link-color, $link-color);
		// background-position: 0 1.1em;
		// background-repeat: repeat-x;
		// background-size: 1px 1px;
		// // todo: ems
		// // text-shadow: -2px 0 0 $background-color, -1px 0 0 $background-color, 1px 0 0 $background-color, 2px 0 0 $background-color;

		// // *,
		// // *::after,
		// &::after,
		// &::before {
		// 	text-shadow: none;
		// }
	}
}

// @include links-colors;

h1 a {
	background-position: 0 1.02em;
}

// @mixin links-for-background($background: $link-underline-background-color, $link-color: $link-color) {
// }

// @include links-for-background;

@mixin links-arrow($margin: 0.25em, $shift: 0.25em) {
	$transition-duration: 0.15s;

	&::after {
		position: absolute;
		content: '→';
		margin-left: $margin;
		transition: transform $transition-duration ease-in;
	}

	&:hover::after {
		transform: translateX($shift);
		transition: transform $transition-duration ease-out;
	}
}

a.cta {
	@include links-arrow;
}

a.cta-chevron {
	color: $colors-purple;
	clear: both;
	display: block;
	font-weight: 400;
	position: relative;

	&:after {
		display: inline-block;
		content: '';
		background-image: svg-url('<svg class="chevron chevron-right" width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <defs> <path d="M8.22222222,9.72222222 L8.22222222,4.38888889 C8.22222222,3.89796911 8.62019133,3.5 9.11111111,3.5 C9.60203089,3.5 10,3.89796911 10,4.38888889 L10,10.6111111 C10,11.1020309 9.60203089,11.5 9.11111111,11.5 L2.88888889,11.5 C2.39796911,11.5 2,11.1020309 2,10.6111111 C2,10.1201913 2.39796911,9.72222222 2.88888889,9.72222222 L8.22222222,9.72222222 Z" id="path-1"></path> </defs> <g id="Template" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Company-Profile-_Template_NetcapBrand_Neutral" transform="translate(-334.000000, -9427.000000)"> <rect fill="#FFFFFF" x="0" y="0" width="1440" height="20525"></rect> <g id="Body-Content" transform="translate(0.000000, 905.902784)"> <g id="H3-Block" transform="translate(329.000000, 8170.896539)"> <g id="Icons/Bullet" transform="translate(0.810205, 348.634537)"> <g id="Colors/Primary"> <rect id="Rectangle" fill-opacity="0" fill="#D8D8D8" x="0" y="0" width="16" height="16"></rect> <mask id="mask-2" fill="white"> <use xlink:href="#path-1"></use> </mask> <use id="Combined-Shape" fill="#6C4EA5" transform="translate(6.000000, 7.500000) rotate(-45.000000) translate(-6.000000, -7.500000) " xlink:href="#path-1"></use> </g> </g> </g> </g> </g> </g> </svg>');
		background-repeat: no-repeat;
		background-position: bottom right;
		position: relative;
		top: 1px;
		width: 1rem;
		height: 20px;
	}

	&:hover {
		text-decoration: underline;
	}
}

.create-new {
	@include links-create-new;
}