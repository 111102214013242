.recommended-offerings-introduction {
  font-weight: 400;
  margin-top: 3 * $rhythm;
  margin-bottom: $rhythm;
}

.recommended-offerings-view {
  // background: $colors-offwhite;
  margin-bottom: 2 * $rhythm;
  // padding: 8px;
  .module-title {
    margin-bottom: 1rem;
    color: $colors-purple;
    font-weight: 600;
  }
}

.recommended-offerings {

  &__item {
    margin-bottom: 2rem;

    &.gaining .tile-company {
      &__category {
        border-color: $colors-dddd;
        color: $colors-dddd;
      }
      &__factoid {
        background: $colors-dddd;
      }
    }
    &.most-momentum .tile-company {
      &__category {
        border-color: $colors-currency;
        color: $colors-currency;
      }
      &__factoid {
        background: $colors-currency;
      }
    }
    &.closing-soon .tile-company {
      &__category {
        border-color: $colors-red;
        color: $colors-red;
      }
      &__factoid {
        background: $colors-red;
      }
    }
  }
}

// @media screen and (min-width: $bp-sm) {
//   .recommended-offerings {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;

//     &__item {
//       flex-basis: 48%;
//     }

//     @supports (display: grid) {
//       display: grid;
//       grid-template-columns: 1fr 1fr;
//       grid-gap: 1.5rem;

//       &__item {
//         margin-bottom: 0;
//       }
//     }
//   }
// }

@include breakpoint(10) {
  .recommended-offerings {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      flex-basis: 48%;
      margin-bottom: 0;
    }

    @supports (display: grid) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 1rem;
    }
  }
}