// Color Theme Pallete for the Revamp Version of NC

// BACKGROUND GRADIENTS

$hero-section-gradient: linear-gradient(109deg, #f3f1f8 25.3%, #fff2dd 98.85%);

// TEXT COLORS
$soft-black: #1c1c1e;

// PRIMARY COLORS
$primary-100: #f3f1f8;
$primary-200: #e0d9ec;
$primary-300: #ccc1e0;
$primary-400: #af9ece;
$primary-500: #917bbc;
$primary-600: #7f64b0;
$primary-default-700: #6c4ea5;
$primary-800: #553e83;
$primary-900: #47336d;
$primary-1000: #2e2147;
$primary-1100: #7941e7;

// SECCONDARY COLORS
$seccondary-100: #fff2dd;
$seccondary-200: #ffd794;
$seccondary-default-300: #ffc666;
$seccondary-400: #cc9f52;
$seccondary-500: #a58042;
$seccondary-600: #8c6d38;
$seccondary-700: #735a2e;
$seccondary-800: #5c4825;
$seccondary-900: #4d3b1f;
$seccondary-1000: #3b2e18;

// NEUTRALS COLORS
$neutral-100: #f2f2f4;
$neutral-200: #dcdce1;
$neutral-300: #c4c5ce;
$neutral-400: #a5a5b4;
$neutral-500: #848498;
$neutral-600: #707087;
$neutral-700: #5b5c76;
$neutral-800: #484865;
$neutral-900: #3b3c54;
$neutral-1000: #2e2e40;

// SUCCESS COLORS
$success-100: #ecf4ea;
$success-200: #cce3c6;
$success-300: #aad0a0;
$success-400: #7ab56b;
$success-500: #44972f;
$success-600: #218407;
$success-700: #166e00;
$success-800: #125700;
$success-900: #0f4800;
$success-1000: #0b3800;

// DANGER COLORS
$danger-100: #fcefef;
$danger-200: #f6d4d5;
$danger-300: #f0b8b8;
$danger-400: #e78d8e;
$danger-500: #dd5c5d;
$danger-600: #d43536;
$danger-700: #b42425;
$danger-800: #911d1e;
$danger-900: #791819;
$danger-1000: #791819;

// WARNING COLORS
$warning-100: #f7f1e8;
$warning-200: #ebdac4;
$warning-300: #dec29c;
$warning-400: #cb9e62;
$warning-500: #b77826;
$warning-600: #a96000;
$warning-700: #8b4f00;
$warning-800: #703f00;
$warning-900: #703f00;
$warning-1000: #472900;
