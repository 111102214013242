// todo:
input[type='checkbox'] {
	@include remove();

	& + label {

		// padding: 1rem 0 0 40px;
		background-position: 0 19px;

		margin-right: 1rem;
		min-height: $rhythm;
		display: inline-block;
		padding-left: 40px;

		background-repeat: no-repeat;
		background-position: 0 50%;

		vertical-align: middle;
		cursor: pointer;

		background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22px" height="22px"><rect x="0.5" y="0.5" width="20" height="20" fill="none" stroke="#888888" stroke-width="1"></rect><path d="M 5 11 L 10 15 L 17 5" stroke="#eeeeee" fill="none" stroke-width="3" /></svg>');

		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	&:checked + label {
		background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22px" height="22px"><rect x="0.5" y="0.5" width="20" height="20" fill="none" stroke="#888888" stroke-width="1"></rect><path d="M 5 11 L 10 15 L 17 5" stroke="#008080" fill="none" stroke-width="3" /></svg>');
	}

	&:disabled + label {
		background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22px" height="22px"><rect x="0.5" y="0.5" width="20" height="20" fill="#fdfdfd" stroke="#888888" stroke-width="1"></rect><path d="M 5 11 L 10 15 L 17 5" stroke="#eeeeee" fill="none" stroke-width="3" /></svg>');
		color: $colors-nnnn;
	}
}
