// sass-lint:disable no-color-literals
a {
	&.facebook:hover path {
		fill: $colors-facebook;
	}

	&.linkedin:hover path {
		fill: $colors-linkedin;
	}

	&.twitter:hover path {
		fill: $colors-twitter;
	}
	&.instagram:hover path {
		// fill: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
		fill: $colors-instagram;
	}
}

// todo: remove ".youtube" classname as an indicator of videos
footer a {
	&.youtube:hover path {
		fill: $colors-youtube;
	}
}