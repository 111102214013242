.alert-notifications {
	display: flex;
	padding: 0 0.5rem;
	line-height: 32px;

	&__icon {
		position: relative;
		align-self: center;
		width: 32px;
		height: 32px;

		.bell {
			width: 100%;
			height: 100%;

			.path {
				fill: $colors-yellow;
			}
		}
	}

	&__count {
		position: absolute;
		top: 0;
		right: 0;
		width: 18px;
		height: 18px;
		text-align: center;
		vertical-align: middle;
		font-size: 12px;
		line-height: 16px;
		font-weight: 600;
		color: $colors-white;
		background-color: $colors-purple-dark;
		border: 1px solid $colors-purple-dark;
		border-radius: 50%;
	}

	&:focus,
	&:hover {
		opacity: 0.85;
	}

	&:active {
		opacity: 0.75;
	}
}
