// Small widget, e.g. Recent Trades on Companies listing page
.module-container {
  @include clearfix;
  // padding: $rhythm 0;

  .tabs {
    background: #fff;
    padding-top: 3px;
    a {
      border-bottom: 4px solid $colors-ssss;
      cursor: pointer;
      display: inline-block;
      padding: 5px 8px;
      &:hover {
        border-color: $link-color;
      }
      &.is-active {
        border-color: #6c4ea5;
        color: #6c4ea5;
      }
    }
  }

  .module {
    background: #fff;
    font-size: 0.85em;
    line-height: 1rem;
    padding: 0 8px;
    position: relative;

    th {
      font-weight: 600;
    }

    td {
      &.company {
        max-width: 135px;
        .code,
        .dba {
          display: block;
        }
        .code {
          text-transform: uppercase;
        }
        .dba {
          color: $colors-nnnn;
          font-size: 0.9em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .no-data {
      color: $colors-rrrr;
      font-style: italic;
      margin: $rhythm 0 $rhythm 8px;
    }

    // overlayscrollbars
    &.os-host-overflow-x {
      padding-bottom: 12px;
    }
  }

  @include breakpoint(7, $max: true) {
    [data-mobile-content] {
      &:before {
        content: attr(data-mobile-content);
        display: inline;
      }
      span {
        display: none;
      }
    }
  }
}