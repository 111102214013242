@import "../functions/rem-conversion.scss";
@import "../mixins//index-mixins.scss";
@import "../tokens/colors.scss";

@mixin inputContainer {
  @include flex(
    $direction: "column",
    $alignItems: "flex-start",
    $gap: pxToRemVal(4px)
  );
  max-width: pxToRemVal(263px);
  width: 100%;
}

@mixin inputComponent {
  color: $neutral-400;
  background-color: #ffffff;
  padding: pxToRemVal(10px) pxToRemVal(12px);
  @include border($color: $neutral-300, $radius: pxToRemVal(8px));
  @include flex($alignItems: center, $alignSelf: stretch);

  &:hover {
    @include border($color: $neutral-400);
  }

  &:focus {
    @include border($color: $primary-default-700);
  }

  &:valid,
  &:user-valid {
    color: $neutral-800;
  }

  &:active {
    @include border($color: $primary-default-700);
    color: $neutral-800;
  }

  // &:invalid,
  // &:user-invalid {
  //   @include border($color: $danger-600);
  // }

  &:disabled {
    color: $neutral-300;
    @include border($color: $neutral-200);
    background-color: $neutral-100;
  }
}

@mixin labelText {
  color: $neutral-600;
  @include webTextVariant("web-label");
}

@mixin helperText {
  color: $neutral-600;
  @include webTextVariant("web-caption");
}

@mixin errorText {
  color: $danger-600;
  @include webTextVariant("web-caption");
}

@mixin warningText {
  color: $warning-600;
  @include webTextVariant("web-caption");
}
