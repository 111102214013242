// full-page error message
.very-bad {
	position: absolute;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #FFFFFF;

	.centered {
		width: 80%;
		margin: 0 auto;
		max-width: 40rem;
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -40%);
	}
}