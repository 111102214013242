body.secondary-page {
	// @include links-for-background($background-color-marketing);
	background-color: $background-color-marketing;
	main {
		padding-bottom: 0;
	}
	.wrap {
		@include centered-container;
		position: relative;
	}
	.owns-securities {
		display: none;
	}
	.hero {
		background: #272727 url("#{$CDN_IMAGES}/images/secondary/paths/path-1.svg") no-repeat bottom right;
		background-position-y: 330px;
		color: #fff;
		padding-bottom: 4 * $rhythm;
		padding-top: 4 * $rhythm;
		position: relative;
		@include breakpoint(7) {
			background-position-y: 227px;
		}
		@include breakpoint(10) {
			background-position-y: 170px;
		}
		@include breakpoint(12) {
			background-position-y: 360px;
			padding-bottom: 10.5 * $rhythm;
			padding-top: 10.5 * $rhythm;
		}
		@include breakpoint(16) {
			background-position: bottom right;
		}
		h1 {
			color: #F9F8F4;
			line-height: 1;
			margin-bottom: 1.5rem;
			max-width: 80%;
			@include breakpoint(6) {
				font-size: 2rem;
			}
			@include breakpoint(10) {
				font-size: 2.25rem;
			}
			@include breakpoint(14) {
				font-size: 3rem;
			}
			@include breakpoint(18) {
				font-size: 4.5rem;
			}

		}
		.early-access {
			left: 0;
			position: absolute;
			top: 0;
			@include breakpoint(10) {
				display: block;
				height: auto;
				width: 100px;
			}
			@include breakpoint(12) {
				height: 184px;
				width: 183px;
			}
		}
	}
	.two-col {
		// background: #fff;
		.col {
			margin-bottom: 5.5 * $rhythm;
			margin-top: 3.75 * $rhythm;
			&:first-child {
				margin-bottom: 0;
			}
			@include breakpoint(10) {
				float: left;
				width: 50%;
			}
		}
	}
	.three-col {
		@include clearfix;
		margin-bottom: 3 * $rhythm;
		h2 {
			max-width: 100%;
			text-align: center;
		}
		.col {
			text-align: center;
			p {
				max-width: 100%;
			}
		}
		@include breakpoint(10) {
			margin-bottom: 5.75 * $rhythm;
			.col {
				float: left;
				width: 33.33%;
			}
		}
		@include breakpoint(12) {
			.three-col-wrap {
				@include centered-container;
				position: relative;
			}
		}
		@include breakpoint(14) {
			h2 {
				text-align: left;
			}
		}
	}

	.companies {
		@include clearfix;
		.tile-wrap {
			@include centered-container;
			margin-top: -1.75 * $rhythm;
		}
		.tile {
			position: relative;
			background-color: #402c67;
			background-image: url('#{$CDN_IMAGES}/images/patterns/repeats-01.svg');
			padding: 1.5rem;
			height: column-width(3, 10);
			margin-bottom: 1.5 * $rhythm;
			float: right;
			width: 100%;
			@include breakpoint(6) {
				padding: 1.5rem 1rem;
			}
			@include breakpoint(8) {
				padding: 3rem 1.5rem;
			}
			&:nth-child(n+3) {
				display: none;
			}
			.keyword {
				color: #FFFFFF;
				font-family: 'Source Serif Pro';
				font-weight: 600;
				font-size: 1.5rem;
				line-height: 1;
				@include breakpoint(6) {
					font-size: 1.125rem;
				}
				@include breakpoint(8) {
					font-size: 2rem;
				}
			}
			.description {
				position: absolute;
				bottom: 2rem;
				line-height: 1.1;
			}
			.name {
				color: #FFC666;
				font-weight: 400;
				margin-bottom: 0.25 * $rhythm;
			}
			.fact {
				color: #FFFFFF;
				font-weight: 400;
				font-size: 1.125rem;
				margin-bottom: 0.75 * $rhythm;
			}
			.cta {
				@include links-arrow;
				color: #FFC666;
				font-weight: 400;
				float: none;
				position: static;
				right: auto;
			}
		}
		.cta {
			@include links-arrow;
			color: #6C4EA5;
			clear: both;
			float: right;
			font-weight: 400;
			position: relative;
			right: 20px;
		}
		@include breakpoint(6) {
			.tile {
				@include block(3);
				float: right;
				height: column-width(3, 10);
				margin-bottom: .5 * $rhythm;
			}
		}
		@include breakpoint(8) {
			.tile {
				@include block(4);
				float: right;
				height: column-width(4, 10);
			}
		}
		@include breakpoint(10) {
			.tile-wrap {
				margin-top: -1.75 * $rhythm;
			}
		}
		@include breakpoint(12) {
			.tile:nth-child(n+3) {
				display: block;
			}
		}
		@include breakpoint(14) {
			.tile-wrap {
				position: relative;
				&:before {
					background: url("#{$CDN_IMAGES}/images/secondary/paths/path-2.svg") no-repeat 0 0;
					position: absolute;
					top: -18px;
					right: 775px;
					width: 202px;
					height: 299px;
				}
			}
		}
	}
	.how-it-works {
		@include clearfix;
		margin-top: 3.75 * $rhythm;
		margin-bottom: 3.75 * $rhythm;
		text-align: center;
		h2,
		h3 {
			max-width: 100%;
		}
	}
	.easy-to-sell {
		position: relative;
		&:before {
			background: $colors-light-blue;
			content: '';
			display: block;
			height: 120%;
			right: 0;
			position: absolute;
			top: -13%;
			max-width: 2000px;
			width: 100%;
			@include breakpoint(12) {
				width: 95%;
			}
		}
		.three-col {
			position: relative;
		}
		.col {
			padding: 0 $rhythm $rhythm;
			position: relative;
			a.lightbox {
				display: block;
				border-radius: 50%;
				box-shadow: 0 4px 8px rgba(0,0,0,0.15);
				margin: 0 auto 1.5rem;
				overflow: hidden;
				transform: scale(1);
				transition: all 0.15s ease;
				$scale: 1.10;
				&:hover {
					transform: scale($scale);
					img {
						transform: scale(1);
					}
				}
				img {
					display: block;
					width: 100%;
					height: auto;
					transition: all 0.15s ease;
					transform: scale($scale);
				}
			}
		}
	}
	.easy-to-buy {
		margin-bottom: 3 * $rhythm;
		position: relative;
		.wrap {
			background: #FFC666;
			margin-top: -(3 * $rhythm);
			padding-top: 3 * $rhythm;
			width: 100%;
		}
		img {
			display: block;
			margin: 0 auto;
			height: auto;
			position: relative;
			width: 100%;
			z-index: 2;
		}
		.text {
			padding: (3 * $rhythm) (1 * $rhythm) (3 * $rhythm);
			text-align: center;
			h2,
			p {
				max-width: 100%;
			}
		}
		@include breakpoint(6) {
			.wrap {
				margin-top: -(4.5 * $rhythm);
			}
		}
		@include breakpoint(12) {
			&:before {
				background: #FFC666;
				content: '';
				display: block;
				height: calc(100% - 80px);
				left: 0;
				position: absolute;
				top: 0;
				width: calc(100% - 250px);
			}
			.wrap {
				@include centered-container;
				background: none;
				margin-top: 0;
				padding-top: 0;
			}
			img {
				float: right;
				margin-top: 2 * $rhythm;
				width: 50%;
			}
			.text {
				float: left;
				text-align: left;
				width: 50%;
				padding: (2 * $rhythm) (1 * $rhythm) (2 * $rhythm) 0;
				h2,
				p {
					// replace w/ column width
					max-width: 512px;
				}
			}
		}
		@include breakpoint(14) {
			&:before {
				max-height: 280px;
			}
		}
		@include breakpoint(16) {
			&:before {
				height: 324px;
				max-height: 324px;
			}
			.text {
				padding-bottom: 3.5 * $rhythm;
				padding-top: 3.5 * $rhythm;
			}
		}
	}
	.btn {
		@include button-base;
		@include button($background: #6C4EA5, $foreground: $colors-white, $accent: $colors-mink);
		display: inline-block;
		margin-bottom: $rhythm;
		font-weight: 400;
		width: auto;
	}
	.get-started {
		margin-bottom: 3.75 * $rhythm;
		text-align: center;
		h2,
		h3,
		p {
			max-width: 100%;
		}
		h3 {
			margin-top: 3.75 * $rhythm;
		}
		.small {
			font-size: 0.875rem;
			color: #908F8D;
		}
	}

	@include make-footer($background: #272727, $foreground: $colors-white);

}
