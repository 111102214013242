.btn {
	$self: &;
	background-image: none;
	background-color: $colors-purple;
	border-radius: 0.125rem;
	border: 1px solid $colors-purple;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: 'Source Sans Pro', Arial, sans-serif;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.5rem;
	margin: 0;
	padding: 0.5rem 1rem;
	position: relative;
	text-align: center;
	text-shadow: none;
	// transition: all $transition-speed-base ease-out;

	&__container {
		display: inline-block;
	}

	&__text {
		// the main text content
	}

	&__subtext {
		display: block;
		font-size: 0.8rem;
		font-weight: 400;
		margin-top: -12px;
	}

	&__icon {
		position: absolute;
		left: 8px;
		top: 50%;
		transform: translateY(-50%);
		width: 1.5rem;
		height: 1.5rem;
		svg path {
			fill: #fff;
		}

		// &.rotate {
		// 	&--45 {
		// 		transform: translateY(-50%) rotate(45deg);
		// 	}
		// 	&--90 {
		// 		transform: translateY(-50%) rotate(90deg);
		// 	}
		// 	&--180 {
		// 		transform: translateY(-50%) rotate(180deg);
		// 	}
		// 	&--270 {
		// 		transform: translateY(-50%) rotate(270deg);
		// 	}
		// }
	}

	&:hover,
	&:focus {
		background-image: none;
		opacity: 0.9;
	}

	&:focus,
	&:selection {
		outline: none;
	}

	&--has-icon {
		padding-left: 2.5rem;
	}

	&--secondary {
		color: #000;
		background-color: $colors-yellow;
		border-color: $colors-yellow;

		&#{$self}--has-icon {
			svg path {
				fill: #000;
			}
		}
	}

	&--inverse {
		background-color: #fff;
		color: $colors-purple;

		&#{$self}--has-icon {
			svg path {
				fill: $colors-purple;
			}
		}

		&:hover,
		&:focus {
			opacity: 1;
			background-color: $colors-purple;
			color: #fff;
		}
	}

	&--small {
		font-size: 1rem;
		line-height: 1.9rem;
		padding: 0 8px;

		&#{$self}--has-icon {
			padding-left: 1.5rem;
		}
	}

	&--xs {
		font-size: 0.85rem;
		font-weight: 600;
		line-height: 1.15rem;
		padding: 4px 8px;
	}

}
