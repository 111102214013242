// todo: remove/migrate to base_variables.scss
$colors-forms-success: $colors-success;
$colors-forms-warning: $colors-warning;
$colors-input-borders: $colors-pppp;
$colors-forms-labels: darken($colors-qqqq, 15%);
$colors-accent: $colors-purple;

// vertical rhythm
$input-margin-bottom: 0.5 * $rhythm; // TODO: remove

$input-padding-top: 12px;
$input-padding-left: 12px;
$input-padding-right: 12px;
$input-padding-bottom: 12px;
$input-padding: $input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;